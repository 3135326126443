<div class="parentDiv">
  <div class="quizParentDiv">
    <div *ngIf="!course?.fromContentUpload201" class="courseNameTile" [ngStyle]="course.expertise=='101'?{'background-color':'#318cf1'} : {'background-color': '#22b1a8'}">
      <div class="courseExpertise">{{course.expertise}}</div>
      <div>{{course.Name}}</div>
    </div>
    <form [formGroup]="quizForm" autocomplete="off">
      <div class="topDiv"
        [style.height]="(quizForm.get('hasQuiz').value == 'Yes' || course.FromHomePage)  ? '220px' : '70px'"
        [style.padding-left]="course.FromHomePage ? '25px' : '0px'">
        <div class="hasQuizDiv" *ngIf="!course.FromHomePage">
          <div class="alignLeft divWidth">
            <div class="hasQuizText">Course has Quiz?</div>
            <div class="hasQuizRadio" *ngFor="let radio of yesNoRadio">
              <input class="yesRadioButton" (change)="onHasQuizChange(radio)" type="radio" value="{{ radio }}"
                formControlName="hasQuiz" />{{ radio }}
            </div>
          </div>

          <!-- <div class="hasQuizText">Course has Quiz?</div>
        <div class="hasQuizRadio" fxLayout="row" fxLayoutAlign="start center">
          <input class="yesRadioButton" type="radio" name="hasQuizRadio" value="yes">Yes
          <input class="noRadioButton" type="radio" name="hasQuizRadio"  value="no" checked>No
        </div> -->

        </div>


        <div class="customizationBox" *ngIf="quizForm.get('hasQuiz').value == 'Yes' || course.FromHomePage">
          <div class="customizationRow1" fxLayout="row">
            <div class="timeBoxedDiv">
              <div>Is Quiz Time boxed?</div>
              <div class="yesNoRadio" *ngFor="let radio of yesNoRadio">
                <input class="yesRadioButton" (change)="onTimeBoxedChange(radio)" type="radio" value="{{ radio }}"
                  formControlName="isQuizTimeBoxed" />{{ radio }}
              </div>
            </div>
            <div class="randomizedDiv">
              <div>Is Quiz Randomized?</div>
              <div class="yesNoRadio" *ngFor="let radio of yesNoRadio">
                <input class="yesRadioButton" (change)="onRandomizedChange(radio)" type="radio" value="{{ radio }}"
                  formControlName="isQuizRandomized" />{{ radio }}
              </div>
            </div>
            <div class="randomizedDiv">
              <div>Is Quiz Attempts Defined?</div>
              <div class="yesNoRadio" *ngFor="let radio of yesNoRadio">
                <input class="yesRadioButton" (change)="onAttemptsChange(radio)" type="radio" value="{{ radio }}"
                  formControlName="isAttemptsDefined" />{{ radio }}
              </div>
            </div>


          </div>
          <div class="customizationRow2" fxLayout="row">
            <div class="durationDiv">
              <div>Quiz Duration</div>
              <div class="durationTextBox" fxLayout="row" fxLayoutAlign="start center">
                <input class="durationHour" type="text" name="durationHour" (blur)="durationChange('hour')"
                  formControlName="durationHour">
                <input class="durationMins" type="text" name="durationMins" (blur)="durationChange('mins')"
                  formControlName="durationMins">
                <input class="durationSeconds" type="text" name="durationSeconds" formControlName="durationSeconds">
              </div>
              <div>
                <span class="hourSpan">hr</span>
                <span class="minSpan">min</span>
                <span class="secSpan">sec</span>
              </div>
              <div class="errorMessage" *ngIf="durationError">
                {{durationErrorMessage}}
              </div>
              <!-- <div class="errorMessage" *ngIf="!durationEmpty
              && (quizForm.get('durationMins').errors?.errorMessage == 'Please enter numbers only')
              && ((quizForm.controls['durationHour'].invalid && quizForm.controls['durationHour'].touched)
              || (quizForm.controls['durationMins'].invalid && quizForm.controls['durationMins'].touched))">
                Please enter numbers only
              </div> -->

            </div>
            <div class="noOfQuestionDiv">
              <div>No. of questions to be picked</div>
              <div class="noOfQuestionsText" fxLayout="row" fxLayoutAlign="start center">
                <input class="noOfQuestionsTextBox" (blur)="noOfQuestionChange()" type="text" name="noOfQuestions"
                  formControlName="noOfQuestions">
              </div>
              <div class="errorMessage" *ngIf="quizForm.controls['noOfQuestions'].invalid
          && quizForm.controls['noOfQuestions'].touched">
                {{quizForm.get('noOfQuestions').errors.errorMessage}}
              </div>
            </div>

            <div class="attemptsDiv">
              <div>Attempts</div>
              <div class="attemptsText" fxLayout="row" fxLayoutAlign="start center">
                <input class="attemptsTextBox" (blur)="attemptsChange()" type="text" name="attemptsTextBox"
                  formControlName="attempts">
              </div>
              <div class="errorMessage" *ngIf="quizForm.controls['attempts'].invalid
          && quizForm.controls['attempts'].touched">
                {{quizForm.get('attempts').errors.errorMessage}}
              </div>
            </div>
            <div class="cutOffMarksDiv">
              <div>Cut Off marks</div>
              <div class="cutOffMarksText">
                <input class="cutOffMarksTextBox" type="text" formControlName="cutOffMarks" />
              </div>
              <div class="errorMessage" *ngIf="quizForm.controls['cutOffMarks'].invalid
          && (quizForm.controls['cutOffMarks'].dirty || quizForm.controls['cutOffMarks'].touched)">
                {{quizForm.get('cutOffMarks').errors.errorMessage}}
              </div>
            </div>
          </div>

          <div *ngIf="quizForm.get('isQuizTimeBoxed').value == 'Yes'" class="customizationRow3" fxLayout="column">
            <!-- <div class="errorMessage" *ngIf="durationEmpty">
              {{durationErrorMessage}}
            </div> -->
            <div class="noteDiv" *ngIf="quizForm.get('isQuizTimeBoxed').value == 'Yes'"
              [ngClass]="(quizForm.get('durationMins').errors?.errorMessage === 'Minutes cannot be more than 59') ? 'errorMessage' : 'nonError'">
              Note: Minutes cannot be more than 59
            </div>
          </div>
        </div>
      </div>
      <div class="questionsTitleDiv" *ngIf="quizForm.get('hasQuiz').value == 'Yes' || course.FromHomePage">
        <div class="createQuiz">Create Quiz</div>
        <div>
          <div class="questionsSection" formArrayName="questionsAndAnswer"
            *ngFor="let questions of quizForm.controls.questionsAndAnswer.controls; let i = index;">
            <div class="sectionParentDiv" [formGroupName]='i'>
              <div class="form-group">
                <div class="questionLabel" cdkDragHandle>
                  {{i + 1}}. Question
                  <div class="iconsDiv" *ngIf="quizFormDataDummy.questionsAndAnswer.length > 1 && !isSubmittedOrApproved">
                    <div class="spriteIcons upIcon" (click)="moveUp(i)"></div>
                    <div class="spriteIcons downIcon" (click)="moveDown(i)"></div>
                    <div class="spriteIcons deleteIcon" (click)="removeQuestion(i)"></div>
                  </div>
                </div>
                <div class="questionDiv">
                  <textarea class="form-control questionTextBox" formControlName="question" cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="5"
                  placeholder="Enter a question" required >
                    </textarea>
                </div>
                <div class="errorMessage"
                  *ngIf="(questions.controls.question.dirty || submitted) && questions.controls.question.errors?.required">
                  *Question cannot be blank
                </div>
                <div class="errorMessage"
                  *ngIf="questions.controls.question.dirty && (questions.controls.question.errors?.isInvalid && !questions.controls.question.errors?.required)">
                  *Question cannot contain white space
                </div>
                <div cdkDropList (cdkDropListDropped)="dropOption($event, i)">
                  <div class="optionsSection" formArrayName='options'
                    *ngFor="let optionss of quizForm.controls.questionsAndAnswer.controls[i].controls.options.controls; let j = index;"
                    cdkDrag>
                    <div class="optionParentDiv" [formGroupName]='j' (mouseover)="mouseEnter(i,j)"
                      (mouseout)="mouseLeave(i,j)">
                      <!-- <input type="checkbox" class="markRight" formControlName='isCorrectAnswer'/> -->
                      <div class="optionLabel" (mouseover)="mouseIndexEnter(i,j)" (mouseout)="mouseIndexLeave(i,j)"
                        cdkDragHandle>
                        <div class="optionIndex"
                          [hidden]="quizFormDataDummy.questionsAndAnswer[i].options[j].onIndexHover">A{{j + 1}}.</div>
                        <div class="spriteIcons swapIcon"
                          [hidden]="!quizFormDataDummy.questionsAndAnswer[i].options[j].onIndexHover"></div>
                      </div>
                      <div class="optionsDiv">
                        <textarea class="form-control optionsTextBox" formControlName='option'
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5"
                        placeholder="Enter Option" required >
                          </textarea>
                        <div class="errorMessage"
                          *ngIf="(optionss?.controls.option?.dirty || submitted) && optionss?.controls.option?.errors?.required">
                          *Option cannot be blank
                        </div>
                        <div class="errorMessage"
                          *ngIf="optionss?.controls.option?.dirty && (optionss?.controls.option?.errors?.isInvalid && !optionss?.controls.option?.errors?.required)">
                          *Option cannot contain white space
                        </div>
                      </div>
                      <div
                        [hidden]="(!quizFormDataDummy.questionsAndAnswer[i].options[j].onHover && !quizFormDataDummy.questionsAndAnswer[i].options[j].isCorrectAnswer) || (isSubmittedOrApproved && !quizFormDataDummy.questionsAndAnswer[i].options[j].isCorrectAnswer)">
                        <div class="markRight" (click)="markRightAnswer(i,j)"
                          [ngClass]="(quizFormDataDummy.questionsAndAnswer[i].options[j].isCorrectAnswer) ? course.expertise=='201'?'rightAnswer201':'rightAnswer' : 'wrongAnswer'">
                          {{
                          (quizFormDataDummy.questionsAndAnswer[i].options[j].isCorrectAnswer) ? 'Right Answer' :
                          'Mark as Right'
                          }}
                        </div>
                      </div>
                      <div class="spriteIcons deleteIcon optionDelete"
                        *ngIf="quizFormDataDummy.questionsAndAnswer[i].options.length > 2 && !isSubmittedOrApproved"
                        [hidden]="!quizFormDataDummy.questionsAndAnswer[i].options[j].onHover"
                        (click)="removeOption(i,j)"></div>
                    </div>
                  </div>
                </div>
                <div class="errorMessage" *ngIf="quizForm.value.questionsAndAnswer[i].isMaxLimitReached">
                  *You can add
                  a maximum of 5 options per question.
                </div>
                <div class="errorMessage"
                  *ngIf="!quizFormDataDummy.questionsAndAnswer[i].hasCorrectAnswer && submitted">
                  *Please select atleast one answer as correct answer.
                </div>
                <div>
                  <div class="questionLabel" *ngIf="!isSubmittedOrApproved">
                    <div class="spriteIcons addIcon" (click)="addOption(i)"></div>
                    <div class="addOption">ADD OPTION</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="addQuestionDiv" *ngIf="!isSubmittedOrApproved">
            <button type="button" class="addQuestionButton" (click)="addQuestion()">Add Question</button>
          </div>

        </div>
      </div>
    </form>
  </div>
</div>
<div class="proceedButtonDiv" *ngIf="!course?.fromContentUpload201">
  <div class="exitForm" (click)="onExit()">EXIT FORM</div>
  <div class="buttonsDiv">
    <button type="submit" class="proceedButton"  mat-raised-button (click)="onBackPressed()"
      value="Back" *ngIf="course?.createQuiz==false">BACK</button>
    <button type="submit" [disabled]="course.IsSubmittedOrApprovedCourse" class="proceedButton" 
    [ngClass]="{disabledButton: course.IsSubmittedOrApprovedCourse}" mat-raised-button (click)="onSave('save')"
      value="SAVE">SAVE</button>
      <button type="submit" [disabled]="course.IsSubmittedOrApprovedCourse" class="proceedButton"
      [ngClass]="{disabledButton: course.IsSubmittedOrApprovedCourse}"  mat-raised-button
        (click)="onSubmit()" value="SUBMIT">
        SUBMIT
      </button>
  </div>
</div>
