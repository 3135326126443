import { Component, ElementRef, OnInit } from '@angular/core';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { Globals } from '../globals';
import { DiversityAndInclusionService } from '../shared/services/diversity-and-inclusion.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'yorbit-our-ai-strategy',
  templateUrl: './our-ai-strategy.component.html',
  styleUrls: ['./our-ai-strategy.component.scss']
})
export class OurAiStrategyComponent implements OnInit {

  selectedArticleFilter: string = 'all';
  seeMoreFlag: boolean = false;

  videoList = [];
  articleList = [];
  filteredPackagesAndCoursesList = [];
  coursesList = [];

  isVideoLoading = false;
  isArticleLoading = false;
  isCoursesLoading = false;


  selectedVideo;
  selectedVideoCategory: String = "";



  //innovative exchange video container
  innovativeExchangeSeason1 = [
    {
      Id: 1,
      ThumNailImageURl: 'https://img.youtube.com/vi/9H9BzHrmXus/0.jpg',
      Title: 'LTIMindtree\'s AI Innovation Showcase: Nachiket Deshpande Walks Us Through Key Innovations | ET Now',
      Description: "Experience a unique insight into LTIMindtree’s AI Innovation Showcase, where artificial intelligence is integrated into all aspects, and every element is crafted with AI in mind. Learn about the company's substantial advancements in AI as COO Nachiket Deshpande emphasizes the pivotal innovations.",
      videolink: 'https://www.youtube.com/embed/9H9BzHrmXus?autoplay=1',
      Speaker: 'Nayna Raut',
      Resources: '',
      ResourceType: ''
    },
    {
      Id: 2,
      ThumNailImageURl: './../../../../courses/nonscorm/OurAIStrategy/nachiket.png',
      Title: 'Leading the AI Wave – Nachiket Deshpande, Rohit Kedia and Krishnan Iyer',
      Description: "Explains, how to see “AI in Everything, Everything in AI and AI for Everyone” with industries perspective.",
      videolink: './../../../../courses/nonscorm/OurAIStrategy/Leading the AI Wave – Nachiket Deshpande.pdf',
      Speaker: 'Bablu Lawrence',
      Resources: '',
      ResourceType: 'pdf'
    }
  ];
  canvasaiapps = [
    {
      Id: 1,
      ThumNailImageURl: './../../../../courses/nonscorm/OurAIStrategy/PythonCopilot.jpg',
      Title: 'Python Copilot',
      Description: 'Powered by Canvas.ai hosted LLM Model fine-tuned for Python. Generate code safe and secure!',
      videolink: '',
      Speaker: '',
      Resources: 'https://canvasai.ltimindtree.com/kits/playarea?space=667ff0d04da168dd276f7ffd&hint=&title=PythonCodePlayGround&desc=Powered%20by%20Canvas.ai%20hosted%20LLM%20Model%20fine-tuned%20for%20Python.%20Generate%20code%20safe%20and%20secure!&userId=99628fc8-5517-497a-93b9-4827fc36ffa6&spaceName=PythonCodePlayGround&appId=667ff0fd4da168dd276f8468&lang=en',
      ResourceType: 'canvasapp'
    },
    {
      Id: 2,
      ThumNailImageURl: './../../../../courses/nonscorm/OurAIStrategy/SmartTrainerApp.jpg',
      Title: 'Smart Trainer App',
      Description: 'Smart Trainer, your AI-powered guide, creates personalized course menus and descriptions, transforming learning into a seamless experience.',
      videolink: '',
      Speaker: '',
      Resources: 'https://canvasai.ltimindtree.com/edtechui/?space=6683b98fa848c835cb56e8e5&hint=&title=SmarttrainerApp&desc=%E2%80%9CSmart%20Trainer,%20your%20AI-powered%20guide,%20creates%20personalized%20course%20menus%20and%20descriptions,%20transforming%20learning%20into%20a%20seamless%20experience.%E2%80%9D&userId=99628fc8-5517-497a-93b9-4827fc36ffa6&spaceName=smart-trainer&appId=673deacf067c521a28be2532&lang=en',
      ResourceType: 'canvasapp'
    },
    {
      Id: 3,
      ThumNailImageURl: './../../../../courses/nonscorm/OurAIStrategy/YourGPT.jpg',
      Title: 'Your GPT',
      Description: 'Fully internal, secure ChatGPT style application for you.',
      videolink: '',
      Speaker: '',
      Resources: 'https://canvasai.ltimindtree.com/kits/chatpage?space=667ebed44da168dd276abb15&hint=&title=YourGPTApp&desc=Fully%20internal%2C%20secure%20ChatGPT%20style%20application%20for%20you&userId=99628fc8-5517-497a-93b9-4827fc36ffa6&spaceName=AskMeAnything-CanvasGPT&appId=668d3ec05351bc22ded42f9a&lang=en',
      ResourceType: 'canvasapp'
    },
    {
      Id: 4,
      ThumNailImageURl: './../../../../courses/nonscorm/OurAIStrategy/Artist.jpg',
      Title: 'Artist Description',
      Description: 'Rekindle your imagination by creating vivid paintings for Content generation, Marketing.',
      videolink: '',
      Speaker: '',
      Resources: 'https://canvasai.ltimindtree.com/artkit/?space=6682ba9da848c835cb55ddaf&hint=&title=ArtisticApp&desc=Rekindle%20your%20imagination%20by%20creating%20vivid%20paintings%20for%20Content%20generation%2C%20Marketing&userId=99628fc8-5517-497a-93b9-4827fc36ffa6&spaceName=QuickArt&appId=6683a5ffa848c835cb56793f&lang=en',
      ResourceType: 'canvasapp'
    }
    ,
    {
      Id: 5,
      ThumNailImageURl: './../../../../courses/nonscorm/OurAIStrategy/CodeCopilotSecured.jpg',
      Title: 'Code Copilot Secured',
      Description: 'Code Pilot is your AI-powered coding assistant, enhancing efficiency and learning in your coding journey.',
      videolink: '',
      Speaker: '',
      Resources: 'https://canvasai.ltimindtree.com/kits/playarea?space=667e270ba518c28e1c510811&hint=&title=CodePlaygroundAssistant&desc=%20Code%20Pilot%20is%20your%20AI-powered%20coding%20assistant%2C%20enhancing%20efficiency%20and%20learning%20in%20your%20coding%20journey.&userId=99628fc8-5517-497a-93b9-4827fc36ffa6&spaceName=CodeGenerationSpace&appId=667e2779a518c28e1c510d62&lang=en',
      ResourceType: 'canvasapp'
    }
  ];

  GenAILearningPath = [
    {
      Id: 1,
      Title: 'Gen AI Learning Path',
      URL: 'https://degreed.com/plan/3207662'
    },
    {
      Id: 2,
      Title: 'Persona Based Learning',
      URL: 'https://degreed.com/plan/3216125'
    },
    {
      Id: 3,
      Title: 'Gen AI Industry Accreditations',
      URL: 'https://degreed.com/plan/3217970'
    },
    {
      Id: 4,
      Title: 'Gen AI - Expert Sessions',
      URL: 'https://degreed.com/plan/3216132'
    }
  ];

  SpecialReading = [
    {
      Id: 1,
      Title: 'Blogs',
      URL: 'https://ltimindtree.sharepoint.com/sites/GARUDA/SitePages/Blogs.aspx'
    },
    {
      Id: 2,
      Title: 'AI news and Insights',
      URL: 'https://ltimindtree.sharepoint.com/sites/GARUDA/SitePages/AI-News.aspx'
    },
    {
      Id: 3,
      Title: 'Sound Bytes',
      URL: 'https://ltimindtree.sharepoint.com/sites/GARUDA/SitePages/Sound-Bytes.aspx'
    }
  ];

  Linkstorefer = [
    {
      Id: 1,
      Title: 'Garuda',
      URL: 'https://ltimindtree.sharepoint.com/sites/GARUDA'
    },
    {
      Id: 2,
      Title: 'Research Bytes',
      URL: 'https://ltimindtree.sharepoint.com/:f:/r/sites/GARUDA/SiteAssets/EAI%20Research%20bytes?csf=1&web=1&e=qskfzm'
    },
    {
      Id: 3,
      Title: 'Center of Excellence',
      URL: 'https://ltimindtree.sharepoint.com/sites/GARUDA/SitePages/CoE.aspx'
    }
  ];

  leaderspeaks = [
    {
      Id: 1,
      ThumNailImageURl: './../../../../courses/nonscorm/OurAIStrategy/LeaderSpeaks.jpg',
      Title: 'Leader Speaks',
      Description: 'This link contains our various leaders’ messages about Generative AI, It Usage, Discussions and sharing thoughts. It also focuses on the future implications of Generative AI.',
      videolink: '',
      Speaker: '',
      Resources: 'https://ltimindtree.sharepoint.com/sites/GARUDA/SitePages/LeaderSpeak.aspx',
      ResourceType: 'leaderspeaks'
    }
  ];

  seasonList = ['Season 1'];
  selectedSeason: any = 'Select Season';
  scrollButton: HTMLElement;

  constructor(
    private _diversityAndInclusionService: DiversityAndInclusionService,
    private _appInsight: AppInsightService,
    public globals: Globals,
    private hostElement: ElementRef
  ) { }

  ngOnInit(): void {

    this.selectedVideo = this.innovativeExchangeSeason1[0];
    this.updateIframeLink(this.selectedVideo.videolink);

    this._diversityAndInclusionService.data$.subscribe(data => {
      this.scrollFunction(data);
    });

  }

  clickOfReltatedVideoSelect(video) {
    const insightPayload = {
      LOCATION: "Diversity & Inclustion Related Videos",
      MID: this.globals.MId
    };

    this._appInsight.trackEvent("D&I_Video", insightPayload)
    this.selectedVideo = video;
    this.updateIframeLink(this.selectedVideo.videolink);
  }

  clickSelectedFilter(filter) {
    this.selectedArticleFilter = filter;
  }

  clickOfArticleTile(item: any): void {
    //App Insight 
    const insightPayload = {
      LOCATION: "Diversity & Inclustion Videos",
      MID: this.globals.MId
    };

    if (item.ResourceType) {
      window.open(item.videolink, '_blank');
    }
    else {
      this._appInsight.trackEvent("D&I_Video", insightPayload)
      this.selectedVideo = item;
      this.updateIframeLink(this.selectedVideo.videolink);
      this.gotoVideoContainer();
    }
  }

  clickofcanvasapp(item: any): void {
    if (item.Resources) {
      window.open(item.Resources, '_blank');
    }
  }
  clickofleaderspeaks(item: any): void {
    if (item.Resources) {
      window.open(item.Resources, '_blank');
    }
  }
  gotoVideoContainer() {
    let el = document.getElementById('videoSection');
    ////console.log(el.scrollHeight)
    el.scrollIntoView({ behavior: 'smooth' });
  }

  updateIframeLink(link) {
    const iframe = this.hostElement.nativeElement.querySelector('iframe');
    iframe.src = link;
  }

  seasonSelected() {
    console.log(this.selectedSeason);
  }



  scrollFunction(scrollPosition): void {
    this.scrollButton = document.getElementById('scrollBtn')
    if (scrollPosition > 20) {
      this.scrollButton.style.display = 'block';
    } else {
      this.scrollButton.style.display = 'none';
    }
  }

  topFunction(): void {
    this._diversityAndInclusionService.scrollToTop(0);
  }

}
