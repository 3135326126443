<link href="/icon?family=Material+Icons" rel="stylesheet">
<div class="contentVerificationContainer">
  <div class="headerText" *ngIf="this.role=='AO'">
      <div class="expertiseHeading" (click)="courseExpertiseSelected('101')"
      [ngStyle]="courseExperise=='101'?{'background-color':'#318cf1'} : {'background-color': 'lightgrey'}"
      *ngIf="(cuuserroles &&(-1!=cuuserroles.indexOf('101')))">101</div>
      <div class="expertiseHeading inactive" *ngIf="(cuuserroles&&(-1==cuuserroles.indexOf('101')))"
      title="Coming Soon">101</div>


      <div class="expertiseHeading" (click)="courseExpertiseSelected('201')"
      *ngIf="!(cuuserroles&&(-1 == cuuserroles.indexOf('201')))"
      [ngStyle]="courseExperise=='201'?{'background-color':'#22b1a8'} : {'background-color': 'lightgrey'}">201</div>

      <div class="expertiseHeading inactive" *ngIf="(cuuserroles&&(-1==cuuserroles.indexOf('201')))"
      title="Coming Soon">201</div>

      <div class="expertiseHeading" (click)="courseExpertiseSelected('301')"
      *ngIf="!(cuuserroles&&(-1 == cuuserroles.indexOf('301')))"
      [ngStyle]="courseExperise=='301'?{'background-color':'#F99723'} : {'background-color': 'lightgrey'}">301</div>

      <div class="expertiseHeading inactive" *ngIf="(cuuserroles&&(-1==cuuserroles.indexOf('301')))"
      title="Coming Soon">301</div>
    
      <div class="expertiseHeading" (click)="courseExpertiseSelected('package')"
      *ngIf="!(cuuserroles&&(-1 == cuuserroles.indexOf('package')))"
      [ngStyle]="courseExperise=='package'?{'background-color':'#394959','color': 'white'} : {'background-color': 'lightgrey'}">Package</div>

      <div class="expertiseHeading inactive" *ngIf="(cuuserroles&&(-1==cuuserroles.indexOf('package')))"
      title="Coming Soon">Package</div>


    <div class="newCourse width300">
      <button class="form-action-btn floatLeft" type="button" (click)="createQuiz()"
        *ngIf="(cuuserroles&&(-1!=cuuserroles.indexOf('101') || -1!=cuuserroles.indexOf('201')))">Create New Quiz</button>
      <button class="form-action-btn floatLeft disableButton" type="button"
        *ngIf="(cuuserroles&&(-1==cuuserroles.indexOf('101') && -1==cuuserroles.indexOf('201')))" title="Coming Soon">Create New Quiz</button>

      <button class="form-action-btn" type="button" (click)="createContent()">Create New Content</button>
    </div>
  </div>
  <div class="savedCourse" *ngIf="contentList && contentList.data && contentList.data.length > 0">{{titleMessage}}
    <div class="resetAllDiv" *ngIf="checkFilter">
      <i class="spriteIcons resetAllIcon" (click)="resetAllFilters();"></i>
      Reset All
    </div>
  </div>

  <div class="noContents" *ngIf="getContentLoaded && (contentList == undefined || contentList.data == null || contentList.data == undefined || (contentList.data && contentList.data.length == 0))">
    No content is available to display
  </div>

  <div *ngIf="contentList && contentList.data && contentList.data.length > 0 && courseExperise != 'package'">
    <table class="contentUploadTable" mat-table [dataSource]="contentList">
      <ng-container matColumnDef="courseName">
        <th mat-header-cell *matHeaderCellDef>Course Name
          <i class="material-icons dropDownIcon" *ngIf="checkedCourseName.length == 0"
            [matMenuTriggerFor]="courseNameMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedCourseName.length > 0"
            [matMenuTriggerFor]="courseNameMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element" title="{{element.Name}}"> {{element.Name.length > 40 ?
          element.Name.slice(0,40) + '...' : element.Name}} </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type
          <i class="material-icons dropDownIcon" *ngIf="checkedType.length == 0"
            [matMenuTriggerFor]="typeMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedType.length > 0"
            [matMenuTriggerFor]="typeMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element"> {{(element.Type != null && element.Type != "") ? element.Type : "NA"}} </td>
      </ng-container>

      <ng-container matColumnDef="repoType">
        <th mat-header-cell *matHeaderCellDef>Repo Type
          <i class="material-icons dropDownIcon" *ngIf="checkedRepoType.length == 0"
            [matMenuTriggerFor]="repoTypeMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedRepoType.length > 0"
            [matMenuTriggerFor]="repoTypeMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.RepoType}} </td>
      </ng-container>

      <ng-container matColumnDef="academy">
        <th mat-header-cell *matHeaderCellDef>Academy
          <i class="material-icons dropDownIcon" *ngIf="checkedAcademy.length == 0"
            [matMenuTriggerFor]="academyMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedAcademy.length > 0"
            [matMenuTriggerFor]="academyMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element" title="{{element.Academy}}"> {{(element.Academy != null && element.Academy != "") ?
          (element.Academy.length > 25 ? element.Academy.slice(0,25)+'...' : element.Academy) : 'NA'}} </td>
      </ng-container>

      <ng-container matColumnDef="genre">
        <th mat-header-cell *matHeaderCellDef>Genre
          <i class="material-icons dropDownIcon" *ngIf="checkedGenre.length == 0"
            [matMenuTriggerFor]="genreMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedGenre.length > 0"
            [matMenuTriggerFor]="genreMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element" title="{{element.Genre}}"> {{(element.Genre != null && element.Genre != "") ?
          (element.Genre.length > 25 ? element.Genre.slice(0,25)+'...' : element.Genre) : 'NA'}} </td>
      </ng-container>

      <ng-container matColumnDef="skill">
        <th mat-header-cell *matHeaderCellDef>Skill
          <i class="material-icons dropDownIcon" *ngIf="checkedSkill.length == 0"
            [matMenuTriggerFor]="skillMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedSkill.length > 0"
            [matMenuTriggerFor]="skillMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element" title="{{element.Skill}}"> {{(element.Skill != null && element.Skill != "") ?
          (element.Skill.length > 25 ? element.Skill.slice(0,25)+'...' : element.Skill) : 'NA'}} </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Category
          <i class="material-icons dropDownIcon" *ngIf="checkedCategory.length == 0"
            [matMenuTriggerFor]="categoryMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedCategory.length > 0"
            [matMenuTriggerFor]="categoryMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element" title="{{element.Category}}"> {{(element.Category != null && element.Category != "") ?
          (element.Category.length > 25 ? element.Category.slice(0,25)+'...' : element.Category) : 'NA' }} </td>
      </ng-container>

      <ng-container matColumnDef="courseStatus">
        <th mat-header-cell *matHeaderCellDef>Status
          <i class="material-icons dropDownIcon" *ngIf="checkedStatus.length == 0"
            [matMenuTriggerFor]="courseStatusMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedStatus.length > 0"
            [matMenuTriggerFor]="courseStatusMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element">{{ role.toLowerCase() == 'ao' ? element.CourseStatus : element.CourseStatus.toLowerCase() == 'launched' ? 'Approved' : element.CourseStatus}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
      <tr mat-row (click)="gotoEditMode(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator *ngIf="courseExperise != 'package'" [pageSizeOptions]="[10]" showFirstLastButtons>
</mat-paginator>

  <!-- Package table -->
  <div *ngIf="contentList && contentList.data && contentList.data.length> 0 && courseExperise == 'package'">
    <table class="contentUploadTable" mat-table [dataSource]="contentList">
      <ng-container matColumnDef="courseName">
        <th mat-header-cell *matHeaderCellDef>Package Name
          <i class="material-icons dropDownIcon" *ngIf="checkedCourseName.length == 0"
            [matMenuTriggerFor]="courseNameMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedCourseName.length > 0"
            [matMenuTriggerFor]="courseNameMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element" title="{{element.Name}}"> {{element.Name.length > 40 ?
          element.Name.slice(0,40) + '...' : element.Name}} </td>
      </ng-container>

      <!-- <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type
          <i class="material-icons dropDownIcon" *ngIf="checkedType.length == 0"
            [matMenuTriggerFor]="typeMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedType.length > 0"
            [matMenuTriggerFor]="typeMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.Type}} </td>
      </ng-container> -->

      <ng-container matColumnDef="repoType">
        <th mat-header-cell *matHeaderCellDef>Repo Type
          <i class="material-icons dropDownIcon" *ngIf="checkedRepoType.length == 0"
            [matMenuTriggerFor]="repoTypeMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedRepoType.length > 0"
            [matMenuTriggerFor]="repoTypeMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element"> {{(element.RepoType != "" && element.RepoType != null) ? element.RepoType : "NA"}} </td>
      </ng-container>

      <ng-container matColumnDef="academy">
        <th mat-header-cell *matHeaderCellDef>Academy
          <i class="material-icons dropDownIcon" *ngIf="checkedAcademy.length == 0"
            [matMenuTriggerFor]="academyMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedAcademy.length > 0"
            [matMenuTriggerFor]="academyMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element" title="{{element.Academy}}"> {{(element.Academy != null && element.Academy != "") ?
          (element.Academy.length > 25 ? element.Academy.slice(0,25)+'...' : element.Academy) : 'NA'}} </td>
      </ng-container>

      <ng-container matColumnDef="genre">
        <th mat-header-cell *matHeaderCellDef>Genre
          <i class="material-icons dropDownIcon" *ngIf="checkedGenre.length == 0"
            [matMenuTriggerFor]="genreMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedGenre.length > 0"
            [matMenuTriggerFor]="genreMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element" title="{{element.Genre}}"> {{(element.Genre != null && element.Genre != "") ?
          (element.Genre.length > 25 ? element.Genre.slice(0,25)+'...' : element.Genre) : 'NA'}} </td>
      </ng-container>

      <ng-container matColumnDef="skill">
        <th mat-header-cell *matHeaderCellDef>Skill
          <i class="material-icons dropDownIcon" *ngIf="checkedSkill.length == 0"
            [matMenuTriggerFor]="skillMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedSkill.length > 0"
            [matMenuTriggerFor]="skillMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element" title="{{element.Skill}}"> {{(element.Skill != null && element.Skill != "") ?
          (element.Skill.length > 25 ? element.Skill.slice(0,25)+'...' : element.Skill) : 'NA'}} </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Category
          <i class="material-icons dropDownIcon" *ngIf="checkedCategory.length == 0"
            [matMenuTriggerFor]="categoryMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedCategory.length > 0"
            [matMenuTriggerFor]="categoryMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element" title="{{element.Category}}"> {{(element.Category != null && element.Category != "") ?
          (element.Category.length > 25 ? element.Category.slice(0,25)+'...' : element.Category) : 'NA' }} </td>
      </ng-container>

      <ng-container matColumnDef="PackageStatus">
        <th mat-header-cell *matHeaderCellDef>Status
          <i class="material-icons dropDownIcon" *ngIf="checkedStatus.length == 0"
            [matMenuTriggerFor]="courseStatusMenu">arrow_drop_down</i>
          <i class="material-icons dropDownIcon" *ngIf="checkedStatus.length > 0"
            [matMenuTriggerFor]="courseStatusMenu">filter_alt</i>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.PackageStatus.toLowerCase() == "approved" ? "Launched" : element.PackageStatus}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
      <tr mat-row (click)="gotoEditMode(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>
    <mat-paginator *ngIf="courseExperise == 'package'" [pageSizeOptions]="[10]" showFirstLastButtons>
</mat-paginator>

  <mat-menu #courseNameMenu="matMenu">
    <div class="parentMenuDiv" (click)="$event.stopPropagation();">
      <div class="searchDiv">
        <div class="textBoxStyle">
          <input [(ngModel)]="courseNameSearchText" type="text" class="textBox" (click)="$event.stopPropagation();"
            placeholder="Search">
          <i class="spriteIcons closeIcon" *ngIf="courseNameSearchText?.length > 0"
            (click)="clearSearch('CourseName')"></i>
          <mat-icon *ngIf="courseNameSearchText?.length == 0 || courseNameSearchText == null">search</mat-icon>
        </div>
      </div>
      <div class="filterList">
        <div *ngIf="courseNameFilter.length > 1" class="selectAllDiv">
          <input type="checkbox" class="selectAllCheckBox" [checked]="selectAllCourseName"
            (change)="updateSelectAll('CourseName')" (click)="$event.stopPropagation();">Select All
        </div>
        <div class="courseList font12" *ngFor='let courseName of courseNameFilter | searchFilter: courseNameSearchText'>
          <input type="checkbox" [checked]="courseName.isSelected" class="checkboxStyle"
            (click)="$event.stopPropagation();" title="{{courseName.value}}"
            (change)="updateCourseNameFilter(courseName.value)">
          {{courseName.value.length > 30 ? courseName.value.slice(0,30)+'...' : courseName.value}}
        </div>
      </div>
    </div>
  </mat-menu>

  <mat-menu #typeMenu="matMenu">
    <div class="parentMenuDiv" (click)="$event.stopPropagation();">
      <div class="filterList noSearch">
        <div *ngIf="courseTypeFilter.length > 1" class="selectAllDiv">
          <input type="checkbox" class="selectAllCheckBox" [checked]="selectAllType" (change)="updateSelectAll('Type')"
            (click)="$event.stopPropagation();">Select All
        </div>
        <div class="courseList font12" *ngFor='let type of courseTypeFilter'>
          <input type="checkbox" [checked]="type.isSelected" class="checkboxStyle" (click)="$event.stopPropagation();"
            (change)="updateCourseTypeFilter(type.value)">
          {{type.value}}
        </div>
      </div>
    </div>
  </mat-menu>

  <mat-menu #repoTypeMenu="matMenu">
    <div class="parentMenuDiv" (click)="$event.stopPropagation();">
      <div class="filterList noSearch">
        <div *ngIf="repoTypeFilter.length > 1" class="selectAllDiv">
          <input type="checkbox" class="selectAllCheckBox" [checked]="selectAllRepoType"
            (change)="updateSelectAll('RepoType')" (click)="$event.stopPropagation();">Select All
        </div>
        <div class="courseList font12" *ngFor='let repoType of repoTypeFilter'>
          <input type="checkbox" [checked]="repoType.isSelected" class="checkboxStyle"
            (click)="$event.stopPropagation();" (change)="updateRepoTypeFilter(repoType.value)">
          {{repoType.value}}
        </div>
      </div>
    </div>
  </mat-menu>

  <mat-menu #academyMenu="matMenu">
    <div class="parentMenuDiv" (click)="$event.stopPropagation();">
      <div class="searchDiv">
        <div class="textBoxStyle">
          <input [(ngModel)]="academySearchText" type="text" class="textBox" (click)="$event.stopPropagation();"
            placeholder="Search">
          <i class="spriteIcons closeIcon" *ngIf="academySearchText?.length > 0" (click)="clearSearch('Academy')"></i>
          <mat-icon *ngIf="academySearchText?.length == 0 || academySearchText == null">search</mat-icon>
        </div>
      </div>
      <div class="filterList">
        <div *ngIf="academyFilter.length > 1" class="selectAllDiv">
          <input type="checkbox" class="selectAllCheckBox" [checked]="selectAllAcademy"
            (change)="updateSelectAll('Academy')" (click)="$event.stopPropagation();">Select All
        </div>
        <div class="courseList font12" *ngFor='let academy of academyFilter | searchFilter: academySearchText'>
          <input type="checkbox" [checked]="academy.isSelected" class="checkboxStyle" title="{{academy.value}}"
            (click)="$event.stopPropagation();" (change)="updateAcademyFilter(academy.value)">
          {{academy.value != null ? (academy.value.length > 30 ? academy.value.slice(0,30)+'...' : academy.value) :
          'NA'}}
        </div>
      </div>
    </div>
  </mat-menu>

  <mat-menu #genreMenu="matMenu">
    <div class="parentMenuDiv" (click)="$event.stopPropagation();">
      <div class="searchDiv">
        <div class="textBoxStyle">
          <input [(ngModel)]="genreSearchText" type="text" class="textBox" (click)="$event.stopPropagation();"
            placeholder="Search">
          <i class="spriteIcons closeIcon" *ngIf="genreSearchText?.length > 0" (click)="clearSearch('Genre')"></i>
          <mat-icon *ngIf="genreSearchText?.length == 0 || genreSearchText == null">search</mat-icon>
        </div>
      </div>
      <div class="filterList">
        <div *ngIf="genreFilter.length > 1" class="selectAllDiv">
          <input type="checkbox" class="selectAllCheckBox" [checked]="selectAllGenre"
            (change)="updateSelectAll('Genre')" (click)="$event.stopPropagation();">Select All
        </div>
        <div class="courseList font12" *ngFor='let genre of genreFilter | searchFilter: genreSearchText'>
          <input type="checkbox" [checked]="genre.isSelected" class="checkboxStyle" (click)="$event.stopPropagation();"
            (change)="updateGenreFilter(genre.value)" title="{{genre.value}}">
          {{genre.value != null ? (genre.value.length > 30 ? genre.value.slice(0,30)+'...' : genre.value) :
          'NA'}}
        </div>
      </div>
    </div>
  </mat-menu>

  <mat-menu #skillMenu="matMenu">
    <div class="parentMenuDiv" (click)="$event.stopPropagation();">
      <div class="searchDiv">
        <div class="textBoxStyle">
          <input [(ngModel)]="skillSearchText" type="text" class="textBox" (click)="$event.stopPropagation();"
            placeholder="Search">
          <i class="spriteIcons closeIcon" *ngIf="skillSearchText?.length > 0" (click)="clearSearch('Skill')"></i>
          <mat-icon *ngIf="skillSearchText?.length == 0 || skillSearchText == null">search</mat-icon>
        </div>
      </div>
      <div class="filterList">
        <div *ngIf="skillFilter.length > 1" class="selectAllDiv">
          <input type="checkbox" class="selectAllCheckBox" [checked]="selectAllSkill"
            (change)="updateSelectAll('Skill')" (click)="$event.stopPropagation();">Select All
        </div>
        <div class="courseList font12" *ngFor='let skill of skillFilter | searchFilter: skillSearchText'>
          <input type="checkbox" [checked]="skill.isSelected" class="checkboxStyle" (click)="$event.stopPropagation();"
            (change)="updateSkillFilter(skill.value)" title="{{skill.value}}">
          {{skill.value != null ? (skill.value.length > 30 ? skill.value.slice(0,30)+'...' : skill.value) :
          'NA'}}
        </div>
      </div>
    </div>
  </mat-menu>

  <mat-menu #categoryMenu="matMenu">
    <div class="parentMenuDiv" (click)="$event.stopPropagation();">
      <div class="filterList noSearch">
        <div *ngIf="categoryFilter.length > 1" class="selectAllDiv">
          <input type="checkbox" class="selectAllCheckBox" [checked]="selectAllCategory"
            (change)="updateSelectAll('Category')" (click)="$event.stopPropagation();">Select All
        </div>
        <div class="courseList font12" *ngFor='let category of categoryFilter'>
          <input type="checkbox" [checked]="category.isSelected" class="checkboxStyle" title="{{category.value}}"
            (click)="$event.stopPropagation();" (change)="updateCategoryFilter(category.value)">
          {{category.value != null ? (category.value.length > 30 ? category.value.slice(0,30)+'...' : category.value) :
          'NA'}}
        </div>
      </div>
    </div>
  </mat-menu>

  <mat-menu #courseStatusMenu="matMenu">
    <div class="parentMenuDiv" (click)="$event.stopPropagation();">
      <div class="filterList noSearch">
        <div *ngIf="courseStatusFilter.length > 1 && courseExperise != 'package'" class="selectAllDiv">
          <input type="checkbox" class="selectAllCheckBox" [checked]="selectAllStatus"
            (change)="updateSelectAll('CourseStatus')" (click)="$event.stopPropagation();">Select All
        </div>

        <div *ngIf="courseStatusFilter.length > 1 && courseExperise == 'package'" class="selectAllDiv">
          <input type="checkbox" class="selectAllCheckBox" [checked]="selectAllStatus"
            (change)="updateSelectAll('PackageStatus')" (click)="$event.stopPropagation();">Select All
        </div>
        <div class="courseList font12" *ngFor='let status of courseStatusFilter'>
          <input type="checkbox" [checked]="status.isSelected" class="checkboxStyle" (click)="$event.stopPropagation();"
            (change)="updateCourseStatusFilter(status.value)">
          {{status.value}}
        </div>
      </div>
    </div>
  </mat-menu>

  <div *ngIf="false" fxLayout="column" class="contentGridContainer">
    <div fxLayout="row" fxLayoutAlign="space-between none" class="gridHeader">
      <div fxFlex="45" fxFlexOffset="10px">Course Name</div>
      <div fxFlex="10" fxFlexOffset="10px">Academy</div>
      <div fxFlex="10" fxFlexOffset="10px">Genre</div>
      <div fxFlex="10" fxFlexOffset="10px">Skill</div>
      <div fxFlex="10" fxFlexOffset="10px">Status</div>
    </div>

    <div *ngFor="let eachContent of contentList" fxLayout="row" fxLayoutAlign="space-between none"
      class="gridContent mousePointer" (click)="gotoEditMode(eachContent)">
      <div class="textTruncate" fxFlex="45" fxFlexOffset="10px" title="{{ eachContent.Name }}">
        {{ eachContent.Name }}
      </div>
      <div class="textTruncate" fxFlex="10" fxFlexOffset="10px" title="{{ eachContent.Academy }}">
        {{ eachContent.Academy}}</div>
      <div class="textTruncate" fxFlex="10" fxFlexOffset="10px" title="{{ eachContent.Genre }}">{{ eachContent.Genre }}
      </div>
      <div class="textTruncate" fxFlex="10" fxFlexOffset="10px" title="{{ eachContent.Skill }}">{{ eachContent.Skill }}
      </div>
      <div *ngIf="courseExperise != 'package'" class="textTruncate" fxFlex="10" fxFlexOffset="10px" title="{{ eachContent.CourseStatus }}">{{
        eachContent.CourseStatus }}</div>
        <div *ngIf="courseExperise == 'package'" class="textTruncate" fxFlex="10" fxFlexOffset="10px" title="{{ eachContent.PackageStatus }}">{{
          eachContent.PackageStatus }}</div>
    </div>
  </div>
</div>

<div class="exceptionMessage" fxLayout="column" fxLayoutAlign="center center"
  *ngIf="contentList &&0==contentList.data.length && getContentLoaded && this.role!=='AO'">
  {{ exceptionMessage }}
</div>
<div *ngIf="!getContentLoaded" fxLayout="column" fxLayoutAlign="center center" class="preloader-container">
  <app-preloader></app-preloader>
</div>
<script src="/icon?family=Material+Icons"></script>