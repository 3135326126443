import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'yorbit-our-aistrategy-video-tile',
  templateUrl: './our-aistrategy-video-tile.component.html',
  styleUrls: ['./our-aistrategy-video-tile.component.scss']
})
export class OurAIStrategyVideoTileComponent implements OnInit {

 
  @Input('articleDetail') articleDetail: any;
  @Output() videoClicked: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public dialog: MatDialog) {}

  ngOnInit(): void {}

  gotoArticle() {
    if(this.articleDetail.Type==='article' || this.articleDetail.Type==='publication'){
      window.open(this.articleDetail.URl, '_blank');
    }
  }

  playVideo(){
    this.videoClicked.emit(this.articleDetail)
  }

  openResource(){
    if(this.articleDetail.ResourceType=='link' || this.articleDetail.ResourceType=='canvasapp' || this.articleDetail.ResourceType=='leaderspeaks'){
      window.open(this.articleDetail.Resources,'_blank')
    }else if(this.articleDetail.ResourceType=='text'){
      //open Dialog
      //this.openDialog(this.articleDetail)
    }
    
  }

  // matDialogConfig(data: any) {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.panelClass = 'popupDialogContainer';
  //   dialogConfig.data = data;
  //   let dialogRef = this.dialog.open(
  //     InnovativeExchangeDialogeComponent,
  //     dialogConfig
  //   );
  //   return dialogRef;
  // }

  // openDialog(item?: any) {
  //   let dialogRef = this.matDialogConfig(item);
  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result == 'true') {
        
  //     } else {
  //       item.isCompleted = false;
  //     }
  //   });
  // }
}
