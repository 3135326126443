import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromUserDetailsReducer from '../reducers/user-details.reducer';
import { IuserDetails } from '../user-details.interface';
import { ArrayPropertyFilterPipe } from 'libs/pipes/src/lib/array-property-filter.pipe';
import { IuserDetailsState } from '../reducers';
import { routerReducer } from '@ngrx/router-store';
const filterPipe = new ArrayPropertyFilterPipe();
export const getUserDetailsSelector = createFeatureSelector('userDetails');


export const getLPResumeDetails = createSelector(
  getUserDetailsSelector,
  (userDetails: IuserDetailsState) => {
    return userDetails.user.data.LearningPlanToResume;
  }
);
export const getPackagesListWithCourses = createSelector(
  getUserDetailsSelector,
  (userDetails: IuserDetailsState) => {
    return userDetails.user.data.PackagesListWithCourses;
  }
);
export const getCoursesInsidePackageById = id =>
  createSelector(getPackagesListWithCourses, packageList => {
    return packageList[id];
  });
export const getLearningPathsSelector = createSelector(
  getUserDetailsSelector,
  (userDetails: IuserDetailsState) => {
    if (userDetails.user.data.LearningPaths && userDetails.user.data.LearningPaths.length > 0) {

      return userDetails.user.data.LearningPaths.filter(lp => !lp.IsDeleted);
    }
    else {
      return [];
    }
  }
);
export const getLearningPathListEntities = createSelector(
  getLearningPathsSelector,
  learningPaths => {
    let entities = {};
    let learningPathEntities;
    if (learningPaths && 0 !== learningPaths.length) {
      learningPathEntities = learningPaths.reduce(
        // tslint:disable-next-line:no-shadowed-variable
        (
          entities: fromUserDetailsReducer.IlearningPathEntities,
          learningPath
        ) => {
          if (learningPath.IsMandatory && !learningPath.IsSkillPlan) {
            return {
              ...entities,
              [learningPath.PathName.toUpperCase()]: learningPath
            };
          } else {
            return {
              ...entities,
              [learningPath.PathId]: learningPath
            };
          }
        },
        {}
      );
    } else {
      learningPathEntities = {};
    }
    return learningPathEntities;
  }
);
export const getContentProgressInsideUnit = (lpId, courseId) =>
  createSelector(getLearningPathListEntities, learningPaths => {
    let courses = filterPipe.transform(learningPaths[lpId].PackageList, {
      property: 'ItemId',
      flag: courseId.toString()
    });
    let course = filterPipe.transform(courses, {
      property: 'IsDeleted',
      flag: false
    })[0];
    return course.ProgressList;
  });
export const getContentProgressInsideDeletedBackUp = () =>
  createSelector(getUserDetailsSelector, (userDetails: IuserDetailsState) => {
    if (userDetails.user.data.DeletedVideoProgressListBackup == null) {
      userDetails.user.data.DeletedVideoProgressListBackup = [];
    }
    return userDetails.user.data.DeletedVideoProgressListBackup;
  });
export const getLearningPathByIdEntities = lpId =>
  createSelector(getLearningPathListEntities, learningPathEntities => {
    return learningPathEntities[lpId];
  });
export const getUnDeletedLPsSelector = createSelector(
  getLearningPathsSelector,
  learningPathList => {
    return filterPipe.transform(learningPathList, {
      property: 'IsDeleted',
      flag: false
    });
  }
);
export const getUserCreatedLPsSelector = createSelector(
  getUnDeletedLPsSelector,
  learningPathList => {
    return filterPipe.transform(learningPathList, {
      property: 'IsMandatory',
      flag: false
    });
  }
);
export const getMandatoryLPsSelector = createSelector(
  getUnDeletedLPsSelector,
  learningPathList => {
    return filterPipe.transform(learningPathList, {
      property: 'IsMandatory',
      flag: true
    });
  }
);
export const getSkillPathsSelector = createSelector(
  getUnDeletedLPsSelector,
  learningPathList => {
    return filterPipe.transform(learningPathList, {
      property: 'IsSkillPlan',
      flag: true
    });
  }
);
export const getSystemGeneratedMandatoryLPsSelector = createSelector(
  getMandatoryLPsSelector,
  learningPathList => {
    return filterPipe.transform(learningPathList, {
      property: 'IsSkillPlan',
      flag: false
    });
  }
);
//Degreed Learning Pathways
export const getDegreedUserLPsSelector = createSelector(
  getUnDeletedLPsSelector,
  learningPathList => {
    return filterPipe.transform(learningPathList, {
      property: 'IsMandatory',
      flag: false
    });
  }
);

export const getCourseProgressListSelector = createSelector(
  getUserDetailsSelector,
  (userDetails: IuserDetailsState) => {
    return userDetails.user.data.CourseProgressList;
  }
);
export const getCourseProgressEntitiesSelector = createSelector(
  getCourseProgressListSelector,
  CourseProgressList => {
    let entities = {};
    let courseProgressEntities;
    if (CourseProgressList && 0 !== CourseProgressList.length) {
      courseProgressEntities = CourseProgressList.reduce(
        // tslint:disable-next-line:no-shadowed-variable
        (entities, course) => {
          return {
            ...entities,
            [course.CourseId]: course
          };
        },
        {}
      );
    } else {
      courseProgressEntities = {};
    }
    return courseProgressEntities;
  }
);
export const getCourseProgressEntitiesByIdSelector = id =>
  createSelector(
    getCourseProgressEntitiesSelector,
    courseProgressListEntities => {
      return courseProgressListEntities[id];
    }
  );
// export const getAchievementsSelector = createSelector(
//   getUserDetailsSelector,
//   (userDetails: IuserDetailsState) => {
//     return userDetails.user.data.Achievements;
//   }
// );
export const getQuizDetailsSelector = createSelector(
  getUserDetailsSelector,
  (userDetails: IuserDetailsState) => {
    return userDetails.user.data.QuizDetails;
  }
);
export const getInterestsSelector = createSelector(
  getUserDetailsSelector,
  (userDetails: IuserDetailsState) => {
    return userDetails.user.data.Interests;
  }
);
export const getRolesSelector = createSelector(
  getUserDetailsSelector,
  (userDetails: IuserDetailsState) => {
    return userDetails.user.data.Roles;
  }
);
export const getAchievementsSelector = createSelector(
  getUserDetailsSelector,
  (userDetails: IuserDetailsState) => {
    return userDetails.user.data.Achievements;
  }
);
export const getBadgesSelector = createSelector(
  getAchievementsSelector,
  (Achievements: any) => {
    return Achievements.Badges;
  }
);
export const getQuizDetailsEntitiesByCourseIdSelector = createSelector(
  getQuizDetailsSelector,
  quizDetails => {
    let entities = {};
    let quizEntities;
    if (quizDetails && 0 !== quizDetails.length) {
      quizEntities = quizDetails.reduce(
        // tslint:disable-next-line:no-shadowed-variable
        (entities, quiz) => {
          return {
            ...entities,
            [quiz.EntityId]: quiz
          };
        },
        {}
      );
    } else {
      quizEntities = {};
    }
    return quizEntities;
  }
);
export const getQuizDetailsEntitiesByQuizIdSelector = createSelector(
  getQuizDetailsSelector,
  quizDetails => {
    let entities = {};
    let quizEntities;
    if (quizDetails && 0 !== quizDetails.length) {
      quizEntities = quizDetails.reduce(
        // tslint:disable-next-line:no-shadowed-variable
        (entities, quiz) => {
          return {
            ...entities,
            [quiz.QuizId]: quiz
          };
        },
        {}
      );
    } else {
      quizEntities = {};
    }
    return quizEntities;
  }
);
export const getQuizByCourseIdSelector = id =>
  createSelector(getQuizDetailsEntitiesByCourseIdSelector, quiz => {
    return quiz[id];
  });
export const getQuizByQuizIdSelector = id =>
  createSelector(getQuizDetailsEntitiesByQuizIdSelector, quiz => {
    return quiz[id];
  });
export const getBadgesEntitiesByBadgeIdSelector = createSelector(
  getBadgesSelector,
  badgesList => {
    let entities = {};
    let badgeEntities;
    if (badgesList && 0 !== badgesList.length) {
      badgeEntities = badgesList.reduce(
        // tslint:disable-next-line:no-shadowed-variable
        (entities, badge) => {
          return {
            ...entities,
            [badge.BadgeId]: badge
          };
        },
        {}
      );
    } else {
      badgeEntities = {};
    }
    return badgeEntities;
  }
);
// export const updateBadgesEntitiesByBadgeIdSelector = (
//   selectedBadgeId,
//   status
// ) =>
//   createSelector(getBadgesEntitiesByBadgeIdSelector, badgeEntities => {
//     if (badgeEntities[selectedBadgeId]) {
//       badgeEntities[selectedBadgeId].Status = status;
//     }
//   });

export const getBadgesEntitiesByCourseIdSelector = createSelector(
  getBadgesSelector,
  badgesList => {
    let entities = {};
    let badgeEntities;
    if (badgesList && 0 !== badgesList.length) {
      badgeEntities = badgesList.reduce(
        // tslint:disable-next-line:no-shadowed-variable
        (entities, badge) => {
          return {
            ...entities,
            [badge.CourseId]: badge
          };
        },
        {}
      );
    } else {
      badgeEntities = {};
    }
    return badgeEntities;
  }
);
export const getBadgesByBadgeIdSelector = id =>
  createSelector(getBadgesEntitiesByBadgeIdSelector, badges => {
    return badges[id];
  });
export const getBadgesByCourseIdSelector = id =>
  createSelector(getBadgesEntitiesByCourseIdSelector, badges => {
    return badges[id];
  });

  export const selectUserDetails = createSelector(
    getUserDetailsSelector,
    (userDetails: IuserDetailsState) => userDetails.user.data
  );
