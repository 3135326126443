<div class="yorbitHomePage">
  <div fxLayout="column" fxLayoutAlign="start stretch" class="home-page">
    <!--fxFlex="700px"-->
    <!-- Banner and Top Course Recommendations -->
    <div class="banner-holder" fxLayout="column"
      [ngClass]="{'learningRecommendationsAvailable':canShowLearningRecommendationsBanner}">



      <div class="banner" [ngStyle.lt-md]="{'height':'auto'}">
        <ngb-carousel>

          <ng-template ngbSlide >            
            <div (click)="openInnovationPodcast()" class="banner podcastBanner" 
              [ngClass.xs]="{'banner-xs':true,'indexbanner-xs':true}" [ngClass.sm]="{'banner-sm':true}"
              [ngClass.md]="{'banner-md':true}" [ngClass.gt-md]="{'banner-gt-md':true}"
              [ngClass.gt-lg]="{'banner-gt-lg':true}"></div>
          </ng-template>

          <ng-template ngbSlide >            
            <div class="banner chatbotBanner" 
              [ngClass.xs]="{'banner-xs':true,'chatbotBanner-xs':true}" [ngClass.sm]="{'banner-sm':true}"
              [ngClass.md]="{'banner-md':true}" [ngClass.gt-md]="{'banner-gt-md':true}"
              [ngClass.gt-lg]="{'banner-gt-lg':true}"></div>
          </ng-template>

          <ng-template ngbSlide >            
            <div class="banner genAIbanner" 
              [ngClass.xs]="{'banner-xs':true,'indexbanner-xs':true}" [ngClass.sm]="{'banner-sm':true}"
              [ngClass.md]="{'banner-md':true}" [ngClass.gt-md]="{'banner-gt-md':true}"
              [ngClass.gt-lg]="{'banner-gt-lg':true}"></div>
          </ng-template>

          <ng-template ngbSlide >            
            <div class="banner indexbanner" (click)="openIndex()"
              [ngClass.xs]="{'banner-xs':true,'indexbanner-xs':true}" [ngClass.sm]="{'banner-sm':true}"
              [ngClass.md]="{'banner-md':true}" [ngClass.gt-md]="{'banner-gt-md':true}"
              [ngClass.gt-lg]="{'banner-gt-lg':true}"></div>
          </ng-template>

          <ng-template ngbSlide>
            <div (click)="openLearningPromonade()" class="banner learningpromonadeportal" [ngClass.xs]="{'banner-xs':true,'learningpromonadeportal-xs':true}"
              [ngClass.sm]="{'banner-sm':true}" [ngClass.md]="{'banner-md':true}"
              [ngClass.gt-md]="{'banner-gt-md':true}" [ngClass.gt-lg]="{'banner-gt-lg':true}"></div>
          </ng-template>

          <ng-template ngbSlide>            
            <div class="banner digitalProfilebanner" (click)="openDigitalProfile()"
              [ngClass.xs]="{'banner-xs':true,'digitalProfilebanner-xs':true}" [ngClass.sm]="{'banner-sm':true}"
              [ngClass.md]="{'banner-md':true}" [ngClass.gt-md]="{'banner-gt-md':true}"
              [ngClass.gt-lg]="{'banner-gt-lg':true}"></div>
          </ng-template>


          <ng-template ngbSlide *ngIf="false">            
            <div class="banner h2fbanner" (click)="openH2F()"
              [ngClass.xs]="{'banner-xs':true,'h2fbanner-xs':true}" [ngClass.sm]="{'banner-sm':true}"
              [ngClass.md]="{'banner-md':true}" [ngClass.gt-md]="{'banner-gt-md':true}"
              [ngClass.gt-lg]="{'banner-gt-lg':true}"></div>
          </ng-template>

         
          
          <ng-template ngbSlide>
            
            <div class="banner shotclassesbanner" (click)="openShotclasses()"
              [ngClass.xs]="{'banner-xs':true,'shotclassesbanner-xs':true}" [ngClass.sm]="{'banner-sm':true}"
              [ngClass.md]="{'banner-md':true}" [ngClass.gt-md]="{'banner-gt-md':true}"
              [ngClass.gt-lg]="{'banner-gt-lg':true}"></div>
          </ng-template>

          <ng-template ngbSlide>            
            <div class="banner indexbanner" (click)="openIndex()"
              [ngClass.xs]="{'banner-xs':true,'indexbanner-xs':true}" [ngClass.sm]="{'banner-sm':true}"
              [ngClass.md]="{'banner-md':true}" [ngClass.gt-md]="{'banner-gt-md':true}"
              [ngClass.gt-lg]="{'banner-gt-lg':true}"></div>
          </ng-template>
          <!--ESG Banner-->
          <ng-template ngbSlide>
            <div class="banner esg" (click)="gotoESG()"
              [ngClass.xs]="{'banner-xs':true,'d_and_i-xs':true}" [ngClass.sm]="{'banner-sm':true}"
              [ngClass.md]="{'banner-md':true}" [ngClass.gt-md]="{'banner-gt-md':true}"
              [ngClass.gt-lg]="{'banner-gt-lg':true}">
              <div class="overlay">

                <div class="buttons-flex">
                  <div class="tag-title">
                    <h3>Environment & Sustainability</h3>
                  </div>

                  <div class="button-wrapper">
                    <span>View content</span>
                  </div>
                </div>

                <div class="icons-flex">
                  <div class="icon">
                   <img src="../../assets/images/ESG/ClimateChange.png" >
                  </div>
 
                  <div class="icon">
                   <img src="../../assets/images/ESG/Ecosystem.png" >
                  </div>
 
                  <div class="icon">
                   <img src="../../assets/images/ESG/EndangeredSpecies.png" >
                  </div>
 
                   <div class="icon">
                   <img src="../../assets/images/ESG/SustainableLiving.png" >
                  </div>
 
                  <div class="icon">
                   <img src="../../assets/images/ESG/WildlifeConservation.png" >
                  </div>
 
                  <div class="icon">
                   <img src="../../assets/images/ESG/AndMore.png" >
                  </div>
 
                 </div>

              </div>
            </div>
          </ng-template>
          
          <!--Aceing Leadership Banner-->
          <!-- <ng-template ngbSlide>
              <div class="banner aceing_leadership_banner" 
                [ngClass.xs]="{'banner-xs':false}" [ngClass.sm]="{'banner-sm':false}"
                [ngClass.md]="{'banner-md':false}" [ngClass.gt-md]="{'banner-gt-md':true}" [ngClass.gt-lg]="{'banner-gt-lg':true}"></div>
            </ng-template> -->
          
            <!-- Conclave Banner -->
            
            <!--risk brigade banner-->
            <!-- <ng-template ngbSlide>
              <div class="banner risk_brigade" 
                [ngClass.sm]="{'banner-sm':true}"
                [ngClass.md]="{'banner-md':true}" [ngClass.gt-md]="{'banner-gt-md':true}" [ngClass.gt-lg]="{'banner-gt-lg':true}">
                <img   class="logo"   src="../assets/images/riskbrigade/logo.png" />
              </div>
            </ng-template> -->

          <ng-template ngbSlide- *ngIf="false">
            <div class="banner learning_promenade" [ngClass.xs]="{'banner-xs':true,'learning_promenade-xs':true}"
              [ngClass.sm]="{'banner-sm':true}" [ngClass.md]="{'banner-md':true}"
              [ngClass.gt-md]="{'banner-gt-md':true}" [ngClass.gt-lg]="{'banner-gt-lg':true}"
              (click)="gotoLearningPromenade()"></div>
          </ng-template>
         
          <ng-template ngbSlide *ngIf="false">
            <div class="banner defaultcursor" (click)="IsC4nabv?goToHMMSparkPage():''"
              [ngClass.xs]="{'banner-xs':true,'hmm-spark':IsC4nabv}"
              [ngClass.sm]="{'banner-sm':true,'hmm-spark':IsC4nabv}"
              [ngClass.md]="{'banner-md':true,'hmm-spark':IsC4nabv}"
              [ngClass.gt-md]="{'banner-gt-md':true,'hmm-spark':IsC4nabv}"
              [ngClass.gt-lg]="{'banner-gt-lg':true,'hmm-spark':IsC4nabv}"></div>
          </ng-template>
          
          <ng-template ngbSlide>
            <div class="banner conclave" [ngClass.xs]="{'banner-xs':true,'conclave-xs':true}"
              [ngClass.sm]="{'banner-sm':true}" [ngClass.md]="{'banner-md':true}"
              [ngClass.gt-md]="{'banner-gt-md':true}" [ngClass.gt-lg]="{'banner-gt-lg':true}"></div>
          </ng-template>

          <ng-template ngbSlide *ngIf="false">
            <div class="banner d_and_i" (click)="gotoDiversityAndInclusion()"
              [ngClass.xs]="{'banner-xs':true,'d_and_i-xs':true}" [ngClass.sm]="{'banner-sm':true}"
              [ngClass.md]="{'banner-md':true}" [ngClass.gt-md]="{'banner-gt-md':true}"
              [ngClass.gt-lg]="{'banner-gt-lg':true}"></div>
          </ng-template>

        </ngb-carousel>

        <div class="floating-new-features-icon" *ngIf="globals.showReleasePopup == true && false">
          <div class="newFeaturesIcon">
            <div class="yorbitSprite whats-new-icon mousePointer glow-anim" title="Check what's new on Shoshin School!"
              (click)="openGeneralInfoPopup($event)"></div>
          </div>
        </div>
        <div class="floating-action-bar" fxLayout="column">
          <div class="floatingActionBarHomePage positionRelative">
            <div class="learningParnterDetails" fxLayoutAlign="center center">
              <mat-menu #LearningPartners="matMenu" class="learningPartners-homepage" [overlapTrigger]="true"
                [xPosition]="'before'" (closed)="afterLPMenuClosed($event)">
                <div class="dummyDiv" (mouseover)="onMouseEnterOnMenu()" (mouseenter)="onMouseEnterOnMenu()"
                  (mouseleave)="closeLearningParntersMenu('mouseleave')"></div>
                <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="5px" class="container"
                  (mouseenter)="onMouseEnterOnMenu()" (mouseleave)="closeLearningParntersMenu('mouseleave')">
                  <div class="header" fxLayoutAlign="center center" fxLayout="column">
                    <div class="heading" fxLayoutAlign="center center">MY LEARNING
                      BUSINESS PARTNER{{learningPartnersList.length>1?'S':''}}</div>
                  </div>
                  <mat-divider fxFlex="1px"></mat-divider>
                  <div class="list">
                    <div class="preloader" fxFlexFill *ngIf="!learningPartnersListLoaded" fxLayoutAlign="center center">
                      <app-preloader></app-preloader>
                    </div>
                    <div class="alert" fxFlexFill *ngIf="learningPartnersListLoaded && learningPartnersList.length==0"
                      fxLayoutAlign="center center">
                      No data available
                    </div>
                    <div *ngFor="let lp of learningPartnersList" class="learning-partner" fxLayoutAlign="start center"
                      fxLayoutGap="8px" (click)="sendMail(lp.MID);stopPropagation($event)">
                      <!-- <div><img class="image" #expertImage /></div> -->
                      <mat-icon class="user-icon" *ngIf="userImages[lp.MID]==undefined" fxFlex="35px">account_circle
                      </mat-icon>
                      <img [src]="userImages[lp.MID]" *ngIf="userImages[lp.MID]!=undefined" class="img" fxFlex="35px" />
                      <div class="name" title="{{ expertName }}" fxFlex>{{ lp.Name }}</div>
                      <mat-icon class="mailToIcon" fxFlexAlign="start">mail_outline</mat-icon>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <div class="link" (click)="navigateToFAQ('Learning Business Partner Details')">List of all Learning Business Partners
                  </div>
                </div>
                <div class="arrow-border-right" fxFlex="20px"></div>
                <div class="arrow-right" fxFlex="20px"></div>
              </mat-menu>
              <div class="learningPartnerIcon yorbitSprite" [ngClass]="{'lpPopoverOpen':lpPopoverOpen}"
                [matMenuTriggerFor]="LearningPartners"
                (touchmove)="openLearningPartnersMenu($event);stopPropagation($event)"
                (mouseenter)="openLearningPartnersMenu('hover')" (mouseleave)="closeLearningParntersMenu('mouseleave')"
                (click)="stopPropagation($event)"></div>
            </div>
            <!-- <a href="https://isupport.ltimindtree.com/knowp" target="_blank" (click)="stopPropagation($event)">
              <div class="yorbitSprite floatingHelpIcon floatingHelpPos" title=" iSupport"></div>
            </a> -->
            <div class="yorbitSprite floatingFAQIcon floatingFAQPos" title="FAQ" (click)="goToFAQ()"></div>
            <div class="yorbitSprite floatingFeedbackIcon floatingFeedbackPos" title="Feedback"
              (click)="goToFeedback()"></div>
          </div>
        </div>

        <div class="iSupport" 
        (click)="openISuppport()"
        title="iSupport"
        [ngClass.gt-xs]="['gt-xs']"
        [ngClass.xs]="['xs']">
          <img class="floating-timeBoundLearning-icon1"
           src="./assets/images/isupport.svg" >
      </div> 


      <div class="shotclasses" 
        (click)="openShotclasses()"
        title="Lighthouse"
        [ngClass.gt-xs]="['gt-xs']"
        [ngClass.xs]="['xs']">
          <img class="floating-timeBoundLearning-icon1"
           src="./assets/images/lighthouse.png" >
      </div> 

        <!-- time bound learning icon-->

        <div class="time_bound_learning" [matMenuTriggerFor]="timeBoundLearningMenu"
          *ngIf="timeBoundCourseList?.length>0" (click)="changetimeBoundCourseReadFlag()">
          <img class="floating-timeBoundLearning-icon"
            src="./assets/images/time bound learning/time_bound_learning_icon.png" [ngClass.gt-xs]="['gt-xs']"
            [ngClass.xs]="['xs']">
          <img *ngIf="displayNotificationForExpiry" class="floating-notification"
            src="./assets/images/time bound learning/notification.png" [ngClass.gt-xs]="['gt-xs']"
            [ngClass.xs]="['xs']">
        </div>

        <mat-menu #timeBoundLearningMenu="matMenu" xPosition="before" class="timeBoundPanel">
          <div class="timeboundCoursesList" fxLayout="column" [ngClass.gt-xs]="['gt-xs']" [ngClass.xs]="['xs']">
            <div *ngFor="let t of sortedTimeBoundData ;let i=index"
              [ngStyle]="i == 0 ? {'margin-top':'15px'} : {'margin-top':'0px'}" class="courseList" fxLayout="column"
              fxLayoutGap="5px">
              <span class="titleName">{{t.itemName}} </span>
              <div class="dateTitle" *ngIf="!checkExpiryDate(t.dueDate)"><span
                  class="timebound"
                  [ngStyle]="{ 'color': checkTimeBoundCourseExpiryDate1(t.dueDate) ? '#E72037':'#394959'}">Due date
                  :</span> <span class="dueDate">{{t.dueDate | date : 'd MMMM y'}}</span> </div>
              <div class="dateTitle expired-on" *ngIf="checkExpiryDate(t.dueDate)"> <span>Expired On :</span> <span class="dueDate aligncenter">{{t.dueDate | date : 'd MMMM
                  y'}}</span> </div>

              <hr>
            </div>
          </div>
        </mat-menu>


      </div>

        <!-- Shoshin School Chat Bot  -->

        <iframe id="chatbot_iframe" *ngIf="chatbotShow" src="../../assets/V2/templates/index.html" class="chatbot" allowtransparency="true" 
        ></iframe>
      <!--  -->
        <div class="chatbot-icon"  >

          <div id="chatbot-tooltip" class="position-relative" style="opacity: 0;">
            <div id="six" class="widget-lagnguge-slot button">
                </div>
          </div>
          <div *ngIf="false" id="bot" (click)="openForm()"  (mouseenter)="openToolTip($event)" 
          (mouseleave)="closeBotPopUp($event)"  class="animate bot-event-img "> &nbsp;</div>
          <div id="hideMe" (click)="openForm()" (mouseenter)="openToolTip($event)"
           (mouseleave)="closeBotPopUp($event)"  class="animate bot-event-img ">
           <img style="width: 80px;" src="./assets/V2/static/Images/launchIcon.gif" alt="pic"/>
          </div>

        </div>

      


      <div *ngIf="false">
        <div *ngIf="!canShowLearningRecommendationsBanner && !canShowCourseraBanner && recommendations.length> 0"
          class="top-courses-holder" fxFlex.gt-sm="380px" fxFlex.lt-md="480px"
          [ngClass.lt-md]="['top-courses-holder-lt-md']" fxLayoutAlign.gt-sm="center start"
          fxLayoutAlign.lt-md="start stretch" fxLayout.lt-md="column" fxLayout.gt-sm="row wrap">
          <div class="recommended-courses overflow-theme-transparent" [ngClass.gt-sm]="['recommended-courses-gt-sm']"
            fxFlexOrder.gt-sm="1" fxFlexOrder.lt-md="2" fxLayoutGap.gt-sm="30px" fxLayoutGap.lt-md="15px">
            <div class="course-tile" fxFlex="225px" fxLayout="column" fxLayoutAlign="center center"
              *ngFor="let course of recommendations; let i = index">
              <div fxFlex="300px" fxLayoutAlign="center center" class="cardMargin">
                <yorbit-content-tile-browse class="mousePointer"
                  *ngIf="!('FamilyPackage'===course.ItemType||'Package'===course.ItemType)" [cardDetails]="course"
                  [featuredIn]="'Recommended Course for a Learner'" fxFlex="225px" fxLayoutAlign="start start">
                </yorbit-content-tile-browse>

                <yorbit-package-tile class="mousePointer"
                  *ngIf="'FamilyPackage'===course.ItemType||'Package'===course.ItemType" [cardDetails]="course"
                  [featuredIn]="'Recommended Course for a Learner'" fxFlex="215px" fxLayoutAlign="start start">
                </yorbit-package-tile>
              </div>
            </div>
          </div>
          <div fxFlex.lt-md="135px" fxFlex.gt-sm="245px" fxLayout.lt-md="row" fxLayout.gt-sm="column"
            fxFlexOrder.lt-md="1" fxFlexOrder.gt-sm="2" fxLayoutAlign.xs="start center"
            fxLayoutAlign.lt-md="center center" fxLayoutAlign.gt-sm="end start" class="recommendations-info"
            [ngClass.lt-md]="['recommendations-info-lt-md']">
            <div fxFlex.xs fxLayout="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign.lt-md="center center"
              fxLayoutAlign.gt-md="center start">
              <div class="info1" [ngClass.xs]="['info1-xs']">COURSES</div>
              <div class="info2">RECOMMENDED</div>
              <div class="info3">FOR YOU</div>
            </div>
            <div fxShow.xs="true" fxFlex="60px" class="toggle-arrow-btn" fxHide.gt-xs="true">
              <div class="yorbitSprite doubleDownArrow"></div>
            </div>
          </div>
          <div fxFlex="100%" fxLayoutAlign="center center" fxShow.gt-sm="true" fxHide.lt-md="true" fxFlexOrder="3">
            <div class="show-more-recommendations-link" fxFlex="1080px" fxLayoutAlign="start center"
              (click)="navigateToMyProfile()">
              <div fxFlexOffset="700px">Show More</div>
            </div>
          </div>
        </div>
        <div *ngIf="canShowLearningRecommendationsBanner" class="learning-recommendations"
          (click)="goToLearningRecommendations()">
          <div class="learning-recommendations-img" [ngClass.sm]="['sm']" [ngClass.md]="['md']"
            [ngClass.gt-md]="['gt-md']" [ngClass.gt-xs]="['gt-xs']" [ngClass.xs]="['xs']"></div>
        </div>
      </div>

      <div class="new-course-banner">
        <div class="LGBTQ-new-course-img" (click)="goToLBTQCourseOnYorbit()" [ngClass.xs]="['xs']" [ngClass.sm]="['sm']"
          [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']" title="Click here to view course details" *ngIf="false">
          <img src="./assets/images/LGBT_Course_mobile.jpg" fxShow.xs="true" fxHide.gt-xs="true" />
          <img src="./assets/images/LGBT_Course_desktop.jpg" fxShow.gt-xs="true" fxHide.xs="true" />
        </div>
      </div>
      <div *ngIf="canShowCourseraBanner" class="learning-recommendations" (click)="goToCoursera()">
        <div class="coursera-img" title="Click here to register" [ngClass.sm]="['sm']" [ngClass.md]="['md']"
          [ngClass.gt-md]="['gt-md']" [ngClass.gt-xs]="['gt-xs']" [ngClass.xs]="['xs']"></div>
      </div>
      <!-- End of Banner and Top Course Recommendations -->

    <!--behavioral program banner - 1-->
    <!-- <yorbit-banner-with-video></yorbit-banner-with-video> -->
    <!--end of behavioral program banner - 1-->

      <!--behavioral program banner - 2-->
      <!--<yorbit-banner-with-logo></yorbit-banner-with-logo>-->
      <!--end of behavioral program banner - 2-->

      <!--behavioral program banner - 3-->
      <!--<yorbit-banner-without-logo></yorbit-banner-without-logo>-->
      <!--end of behavioral program banner - 3-->

      <!-- Top Learners -->
      <!-- <yorbit-top-learners></yorbit-top-learners>

      <yorbit-top-coaches></yorbit-top-coaches> -->
      <!-- <div fxHide fxShow.gt-xs class="topCoachesStripDesktop" *ngIf="true">
        <img fxFlex="100%" src="./../../assets/images/Yellow-strip_desktop.jpg" />
      </div>
      <div fxHide fxShow.xs class="topCoachesStripMobile" *ngIf="true">
        <img fxFlex="100%" src="./../../assets/images/Yellow-strip_mobile.jpg" />
      </div> -->

      <!--Stats on Home Page-->
      <!--Web Version-->
      <div class="stats" fxLayout="column" [ngClass.gt-xs]="['gt-xs']" fxLayoutGap="10px" *ngIf="isStatsLoading">
        <app-preloader></app-preloader>
      </div>
      <div class="stats" fxLayout="column" [ngClass.gt-xs]="['gt-xs']" fxLayoutGap="10px" fxHide.xs="true"
        *ngIf="!isStatsLoading">
        <div fxFlex="100%" fxLayout="column" fxLayoutAlign="center center">
          <div fxLayoutAlign="center center" class="statsheader">
            Learning Statistics for FY'25
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="-1px" class="firstRow">
          <div class="backgroundFirstRow" fxFlex="20%" fxLayout="row">
            <img class="alignment2" src="./../../assets/images/StatsOnHomePage/Courses.png" height="70px"
              width="70px" />
            <div fxLayout="column" fxLayoutAlign="center center">
              <span class="data">
                {{stats?.Courses}}
              </span>
              <span class="header">
                COURSES
              </span>
            </div>
          </div>
          <div class="backgroundFirstRow" fxFlex="20%" fxLayout="row">
            <img class="alignment2" src="./../../assets/images/StatsOnHomePage/skills.png" height="70px" width="70px" />
            <div fxLayout="column" fxLayoutAlign="center center">
              <span class="data">
                {{stats?.Skills}}
              </span>
              <span class="header">
                SKILLS
              </span>
            </div>
          </div>
          <div class="backgroundFirstRow" fxFlex="20%" fxLayout="row">
            <img src="./../../assets/images/StatsOnHomePage/courseCompletions.png" height="70px" width="70px" />
            <div fxLayout="column" fxLayoutAlign="center center">
              <span class="data">
                {{stats.Coursecompletions}}
              </span>
              <span class="header">
                COURSE COMPLETIONS
              </span>
            </div>
          </div>
          <div class="backgroundFirstRow" fxFlex="20%" fxLayout="row">
            <img src="./../../assets/images/StatsOnHomePage/Feedback.png" height="70px" width="70px" />
            <div fxLayout="column" fxLayoutAlign="center center">
              <span class="data">
                {{stats.Feedback}}
              </span>
              <span class="header header1">
                AVERAGE LEARNER FEEDBACK
              </span>
            </div>
          </div>
          <div class="backgroundFirstRow" fxFlex="20%" fxLayout="row">
            <img src="./../../assets/images/StatsOnHomePage/uniqueLearners.png" height="70px" width="70px" />
            <div fxLayout="column" fxLayoutAlign="center center">
              <span class="data">
                {{stats.UniqueLearners}}
              </span>
              <span class="header">
                UNIQUE LEARNERS
              </span>
            </div>
          </div>
        </div>
        <div fxLayout="row" class="secondRow">
          <div class="backgroundSecondRow" fxFlex="80%" fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="row">
              <h3 class="top5Skills aligncenter">TOP 5 SKILLS</h3>
            </div>
            <div class="alignment" fxLayout="row" fxLayoutGap="10px">
              <div class="circle">
                <div class="text">{{stats.TopSkills[0]}}</div>
              </div>
              <div class="circle">
                <div class="text">{{stats.TopSkills[1]}}</div>
              </div>
              <div class="circle">
                <div class="text">{{stats.TopSkills[2]}}</div>
              </div>
              <div class="circle">
                <div class="text">{{stats.TopSkills[3]}}</div>
              </div>
              <div class="circle">
                <div class="text">{{stats.TopSkills[4]}}</div>
              </div>
            </div>
          </div>
          <div fxLayoutGap="5px" fxFlex="20%" fxLayout="column">
            <div class="backgroundFirstRow widthChange" fxLayout="column">
              <span class="header">TOTAL LEARNING HOURS</span>
              <span class="data totViewTime">{{stats.TotViewTime}} hrs</span>
            </div>
            <div class="backgroundFirstRow widthChange" fxLayout="column">
              <span class="header">AVERAGE LEARNING HOURS</span>
              <span class="data">{{stats.AvgViewTime}} hrs</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile Version -->
      <div class="stats" fxLayout="column" [ngClass.xs]="['xs']" fxLayoutGap="10px" fxHide.gt-xs="true"
        *ngIf="!isStatsLoading">
        <div fxFlex="100%" fxLayout="column" fxLayoutAlign="center center">
          <div fxLayoutAlign="center center" class="statsheader">
            Learning Statistics for FY'25
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="7px" class="firstRow">
          <div class="backgroundFirstRowMobile" fxFlex="50%" fxLayout="row">
            <img src="./../../assets/images/StatsOnHomePage/Courses.png" class="centerAli" />
            <div fxLayout="column" fxLayoutAlign="center center">
              <span class="data marginAli">
                {{stats.Courses}}
              </span>
              <span class="header">
                COURSES
              </span>
            </div>
          </div>
          <div class="backgroundFirstRowMobile" fxFlex="50%" fxLayout="row">
            <img src="./../../assets/images/StatsOnHomePage/skills.png" class="centerAli" />
            <div fxLayout="column" fxLayoutAlign="center center">
              <span class="data marginAli">
                {{stats.Skills}}
              </span>
              <span class="header">
                SKILLS
              </span>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="7px" class="firstRow">
          <div class="backgroundFirstRowMobile" fxFlex="50%" fxLayout="row">
            <img src="./../../assets/images/StatsOnHomePage/courseCompletions.png" class="centerAli" />
            <div fxLayout="column" fxLayoutAlign="center center">
              <span class="data">
                {{stats.Coursecompletions}}
              </span>
              <span class="header">
                COURSE COMPLETION
              </span>
            </div>
          </div>
          <div class="backgroundFirstRowMobile" fxFlex="50%" fxLayout="row">
            <img src="./../../assets/images/StatsOnHomePage/uniqueLearners.png" class="centerAli" />
            <div fxLayout="column" fxLayoutAlign="center center">
              <span class="data">
                {{stats.UniqueLearners}}
              </span>
              <span class="header">
                UNIQUE LEARNERS
              </span>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="7px" class="firstRow">
          <div class="backgroundFirstRowMobile" fxFlex="100%" fxLayout="row" fxLayoutGap="15px">
            <img src="./../../assets/images/StatsOnHomePage/Feedback.png" class="centerAli" />
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
              <span class="data">
                {{stats.Feedback}}
              </span>
              <span class="header feedbackAlign">
                AVERAGE LEARNER FEEDBACK
              </span>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="7px" class="firstRow">
          <div class="backgroundFirstRowMobile" fxFlex="50%" fxLayout="column">
            <span class="header">TOTAL VIEW TIME</span>
            <span class="data totViewTimeMobile">{{stats.TotViewTime}} hrs</span>
          </div>
          <div class="backgroundFirstRowMobile" fxFlex="50%" fxLayout="column">
            <span class="header">AVERAGE VIEW TIME</span>
            <span class="data avgViewTimeMobile">{{stats.AvgViewTime}} hrs</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="7px" fxFlex="100%">
          <div class="backgroundSecondRowMobile" fxLayout="column" fxLayoutAlign="center center">
            <div fxLayout="row">
              <h3 class="top5Skills aligncenter">TOP 5 SKILLS</h3>
            </div>
            <div fxLayout="column">
              <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
                <div class="circleMobile">
                  <div class="text">{{stats.TopSkills[0]}}</div>
                </div>
                <div class="circleMobile">
                  <div class="text">{{stats.TopSkills[1]}}</div>
                </div>
                <div class="circleMobile">
                  <div class="text">{{stats.TopSkills[2]}}</div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
                <div class="circleMobile">
                  <div class="text">{{stats.TopSkills[3]}}</div>
                </div>
                <div class="circleMobile">
                  <div class="text">{{stats.TopSkills[4]}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--End of Stats on Home Page-->
      <!-- <yorbit-top-facilitators></yorbit-top-facilitators> -->
      <!--End Top Learners -->

    <!-- Microsoft ESI -->
    <div class="nasscom-component-holder" *ngIf="true">
      <!-- <yorbit-wfh-courses ></yorbit-wfh-courses> -->
      <a href=" https://esi.microsoft.com/"  rel="noreferrer" target="_blank">
        <img fxFlex="100%" class="desktopview" src="./assets/images/MicrosoftTile.png"
          title="Click To View More Details" (click)="captureMicrosoftESIInsights('Desktop')" />
      </a>
      <a href=" https://esi.microsoft.com/"  rel="noreferrer" target="_blank">
        <img fxFlex="100%" class="mobileview" src="./assets/images/MicrosoftTile-m.png"
          (click)="captureMicrosoftESIInsights('Mobile')" />
      </a>
    </div>
    <!-- End of Microsoft ESI -->

      <!-- Future Skill REcommendations-->
      <!-- <div class="nasscom-component-holder">
      <yorbit-nasscom-recommendations [number]="8" [showHeader]="true" [showDescription]="false" [location]="'Home Page'"></yorbit-nasscom-recommendations>
    </div> -->
      <!-- End of Future Skill Recommendations-->

      <!-- Recognition Ribbon-->
      <!-- <yorbit-learner-recognition class="recognition"></yorbit-learner-recognition> -->
      <!-- End of Recognition Ribbon -->
      <!-- <img [fxShow.xs]="false" [fxShow.gt-xs]="true" class="fullwidth" src="../../assets/images/Marketing/angular-strip.jpg" />
    <img [fxShow.xs]="true" [fxShow.gt-xs]="false" src="../../assets/images/Marketing/angular-strip-m.jpg" />
    <img *ngIf="false" src="../../assets/images/Marketing/text-strip.png" class="imgmargin"
    /> -->
      <!--  <div class="info-text-strip" fxLayout="column" fxLayoutAlign="center center" [fxShow.xs]="false" [fxShow.gt-xs]="true">
    <div class="info-text-strip" fxLayout="column" fxLayoutAlign="center center" [fxShow.xs]="false"
      [fxShow.gt-xs]="true">
      <div><span class="number">3369 </span>courses covering <span class="number">1223 </span>skills,</div>
      <div><span class="number">1200+ </span>course completions</div>
      <div>every day, infinite possibilities</div>
    </div> -->
      <!-- Dynamic Content for Marketing -->
      <div class="dynamic content" fxLayout="column">
        <div #testOutlet></div>
      </div>
      <!-- End of Dynamic Content for Martketing -->

      <!-- New and Trending-->
      <div class="new-and-trending" fxLayoutAlign="center start" fxLayout="row wrap" fxShow fxHide.lt-md="true"
        *ngIf="!isnewAndTrendingLoading">
        <ng-template [ngTemplateOutlet]="newAndTrendDesktop"></ng-template>
      </div>
      <div class="new-and-trending" fxLayoutAlign="center start" fxLayout="row wrap" fxHide fxShow.lt-md="true"
        *ngIf="!isnewAndTrendingLoading">
        <ng-template [ngTemplateOutlet]="newAndTrendMobile"></ng-template>
      </div>
      <div class="new-and-trending" fxLayoutAlign="center start" fxLayout="row wrap" fxHide fxShow.lt-lg="true"
        *ngIf="isnewAndTrendingLoading">
        <app-preloader></app-preloader>
      </div>
      <!-- End of New and Trending-->
    </div>



    <!-- <ng-template #topCoursesDesktop>
  <div
    class="top-course"
    fxFlex="250px"
    fxLayout="column"
    [ngStyle.gt-sm]="{ 'margin-top': '-114px' }"
    *ngFor="let course of recommendations; let i = index"
  >
    <div fxFlex="330px" fxLayoutAlign="center center">
      <yorbit-content-tile-browse
        class="mousePointer"
      *ngIf="!('FamilyPackage'===course.ItemType||'Package'===course.ItemType)"
        [cardDetails]="course"
        [featuredIn]="'Recommendation Course'"
        fxFlex="225px"
        fxLayoutAlign="start start"
        fxFlexFill
      >
      </yorbit-content-tile-browse>

        <yorbit-package-tile class="mousePointer" *ngIf="'FamilyPackage'===course.ItemType||'Package'===course.ItemType"
          [cardDetails]="course" [featuredIn]="'Recomendation Course'" fxFlex="225px" fxLayoutAlign="start start"
          fxFlexFill>
        </yorbit-package-tile>
      </div>
    </div>
  </ng-template>
  <ng-template #topCoursesMobile let-tiles="tiles">
    <div class="top-learners" fxLayoutAlign="center center" [ngStyle]="{'padding':(recommendations.length == 0) ? '12px 0px':'28px 0px'}">
      <div fxFlex="310px" fxLayoutAlign="center end" class="positionrel" *ngIf="recommendations.length > 0">
        <img fxFlex="225px" src="./assets/images/top-courses.jpg" />
      </div>
      <div *ngIf="recommendations.length == 0" fxLayoutAlign="center center" class="positionrel">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div class="top-learner-label" fxFlex="90" *ngIf="!isTopLearnersLoading && topLearnersList.length > 0">
            Top Learners
            <span class="month">{{"("+ this.previousMonth +")"}}</span>
          </div>
          <div class="yorbitSprite doubleDownArrow doubleDownArrowPos"></div>
        </div>
      </div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="recommendmargin" *ngIf="recommendations.length > 0">
      <ngb-carousel *ngIf="recommendations.length > 0">
        <ng-template ngbSlide *ngFor="let tile of tiles; let i = index">
          <div class="topmaincourse">
            <div class="top-course" fxFlex="250px" fxLayout="column">
              <div fxFlex="330px" fxLayoutAlign="center center">
                <yorbit-content-tile-browse class="mousePointer" *ngIf="!('FamilyPackage'===tile.ItemType||'Package'===tile.ItemType)"
                  [cardDetails]="tile" [featuredIn]="'Top Course'" fxFlex="225px" fxLayoutAlign="start start"
                  fxFlexFill>
                </yorbit-content-tile-browse>

              <yorbit-package-tile
      class="mousePointer"
      *ngIf="'FamilyPackage'===tile.ItemType||'Package'===tile.ItemType"
      [cardDetails]="tile" [featuredIn]="'Top Course'"
      fxFlex="225px"
      fxLayoutAlign="start start"
      fxFlexFill>
    </yorbit-package-tile>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
  <div
    class="titlesstyle"
    fxLayout="row"
    fxLayoutAlign="center center"
    *ngIf="recommendations.length == 0"
  >
    <ngb-carousel *ngIf="false">
      <ng-template ngbSlide *ngFor="let tile of tiles; let i = index">
        <div class="topcoursestyle">
          <div
            class="top-course"
            fxFlex="250px"
            fxLayout="column"
            fxLayoutAlign="center center"
          >
            <div fxFlex="250px" class="top-learners zeropadding">
              <div class="top-learner-points textaligncen">
                <div class="pic">
                  <img [src]="tile.Image" />
                </div>
                <div class="learner-info">
                  <div class="profilecustom">
                    <div class="name">{{ tile.Name }}</div>
                    <div class="designation">
                      {{ tile.Designation | lowercase }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
    <div fxLayout="column" class="fullwidth">
      <div class="toplearnerinfo" fxLayoutAlign="center center"
      *ngFor="let tile of tiles; let i = index">
        <div
          class="top-course top-learner-info"
          fxLayout="column"
          fxLayoutAlign="center center"

        >
          <div class="top-learnerscustom" fxLayoutAlign="center center">
            <div class="top-learner-points" [ngStyle.gt-xs]="{'width':'50%'}" [ngStyle.xs]="{'width':'100%'}"
            fxLayout="row" fxLayoutAlign.lt-md="start start"
            fxLayoutAlign.gt-sm="center start">
              <div class="pic"  [ngStyle.lt-md]="{'border-bottom':'none'}" fxFlex="20">
                <img [src]="tile.Image" class="tileimage"/>
              </div>
              <div class="learner-infocustom" fxFlex="80">
                <div class="profile" fxLayout="column" [ngStyle.lt-md]="{'margin-left':'40px'}">
                  <div class="name" >{{ tile.Name }}</div>
                  <div class="designation">
                    {{ tile.Designation | lowercase }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template> -->
    <ng-template #newAndTrendDesktop>
      <div fxFlex="100%" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayoutAlign="center center" class="header">
          NEW AND TRENDING
        </div>
      </div>
      <div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <div (click)="previous(navCount, false)">
            <div class="iconSpriteImage newAndTrendingPrev" [ngClass]="{
            'grey-out-elm': navCount <= 0,
            'active-elm': navCount > 0
          }"></div>
        </div>
        <div fxFlex="250px" input type="text" fxLayout="column" *ngFor="
          let tile of (newAndTrending | slice: startCount:endCount);
          let i = index
        ">
            <div fxFlex="300px" fxLayoutAlign="center center" class="cardMargin">
              <yorbit-content-tile-browse class="mousePointer"
                *ngIf="!('FamilyPackage'===tile.ItemType||'Package'===tile.ItemType)" [cardDetails]="tile"
                [featuredIn]="'New and Trending'" fxFlex="225px" fxLayoutAlign="start start" fxFlexFill>
              </yorbit-content-tile-browse>

              <yorbit-package-tile class="mousePointer"
                *ngIf="'FamilyPackage'===tile.ItemType||'Package'===tile.ItemType" [cardDetails]="tile"
                [featuredIn]="'New and Trending'" fxFlex="225px" fxLayoutAlign="start start" fxFlexFill>
              </yorbit-package-tile>
            </div>
          </div>
          <div (click)="next(navCount, false)">
            <div class="iconSpriteImage newAndTrendingNext" [ngClass]="{
            'grey-out-elm': navCount == 3,
            'active-elm': navCount < 3
          }"></div>
          </div>
        </div>
        <div fxLayoutAlign="center center" class="paginate">
          {{ startCount + 1 + ' to ' + endCount + ' of ' + (newAndTrending.length - 1) }}
        </div>
      </div>
    </ng-template>
    <ng-template #newAndTrendMobile>
      <div fxFlex="100%" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayoutAlign="center center" class="header">
          NEW AND TRENDING
        </div>
      </div>
      <div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <div (click)="previous(navCount, true)">
            <div class="iconSpriteImage newAndTrendingPrev" [ngClass]="{
            'grey-out-elm': startCount <= 0,
            'active-elm': startCount > 0
          }"></div>
        </div>
        <div fxFlex="250px" input type="text"  fxLayout="column" *ngFor="
          let tile of (newAndTrending | slice: startCount:startCount + 1);
          let i = index
        ">
            <div fxFlex="300px" fxLayoutAlign="center center" class="cardMargin">
              <yorbit-content-tile-browse class="mousePointer"
                *ngIf="!('FamilyPackage'===tile.ItemType||'Package'===tile.ItemType)" [cardDetails]="tile"
                [featuredIn]="'New and Trending'" fxFlex="225px" fxLayoutAlign="start start" fxFlexFill>
              </yorbit-content-tile-browse>


              <yorbit-package-tile class="mousePointer"
                *ngIf="'FamilyPackage'===tile.ItemType||'Package'===tile.ItemType" [cardDetails]="tile"
                [featuredIn]="'New and Trending'" fxFlex="225px" fxLayoutAlign="start start" fxFlexFill>
              </yorbit-package-tile>
            </div>
          </div>
          <div (click)="next(navCount, true)">
            <div class="iconSpriteImage newAndTrendingNext" [ngClass]="{
            'grey-out-elm': endCount == newAndTrending.length - 1,
            'active-elm': endCount < newAndTrending.length - 1
          }"></div>
          </div>
        </div>
        <div fxLayoutAlign="center center" class="paginate">
          {{ startCount + 1 + ' of ' + (newAndTrending.length - 1) }}
        </div>
      </div>
    </ng-template>
  </div>

  <div *ngIf="false">
    <input type="file" id="file" (change)="handleFileInput($event.target.files)">
    <button (click)="uploadFileToActivity()">Upload</button>
  </div>