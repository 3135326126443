<div class="article-container" fxLayout="column" [ngClass.sm]="['sm']" [ngClass.xs]="['xs']" [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']"
  (click)="gotoArticle()">
  <div class="article-cover" fxFlex="1 1 50%"
    [style.background-image]="articleDetail.ThumNailImageURl?'url(' + articleDetail.ThumNailImageURl + ')' :'url(../../../../assets/images/DandI/article-img.jpg)'"
    fxLayout="row" fxLayoutAlign="center center">

  </div>
  <div class="article-description" fxFlex="1 1 50%" fxLayout="column wrap" fxLayoutGap.gt-md="5px" fxLayoutGap.md="7px"
    fxLayoutGap.xs="3px">
    <div class="title" [ngClass.xs]="['xs']" title="{{articleDetail.Name}}">{{articleDetail.Name}}</div>
    <div class="description" [ngClass.xs]="['xs']" [ngClass.md]="['md']" title="{{articleDetail.Description}}">{{articleDetail.Description}}</div>

    <div>
      <span class="news-article" *ngIf="articleDetail.Type=='article'">
        News Article
      </span>

      <span class="video" *ngIf="articleDetail.Type=='Video'">
        Video
      </span>

      <span class="publication" *ngIf="articleDetail.Type=='publication'">
        Publication
      </span>
    </div>



  </div>
</div>