import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AoRoutingModule } from './ao-routing.module';
import { AoComponent } from './ao.component';
import { ContentVerificationModule } from '../content-verification/content-verification.module';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';
import { NewCourseRequestApprovalComponent } from './new-course-request-approval/new-course-request-approval.component';
import { ContentUploadComponent } from './content-upload/content-upload.component';

//import { MatInputModule, } from '@angular/material';
import { ContentUploadPopupComponent } from './content-upload-popup/content-upload-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@YorbitWorkspace/directives';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatBadgeModule } from '@angular/material/badge'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PipesModule } from '@YorbitWorkspace/pipes';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ContentUploadStatusPopupComponent } from './content-upload-status-popup/content-upload-status-popup.component';
import { ShareContentModule } from '../shared/card-feature/share-content/share-content.module';
import { YorbitCoursesModule } from '../shared/yorbit-courses/yorbit-courses.module';
import { SearchEvaluatorModule } from './content-upload/search-evaluator/search-evaluator.module';
import { DownloadBlobPopupModule } from '../shared/download-blob-popup/download-blob-popup.module';
import { DownloadBlobPopupComponent } from '../shared/download-blob-popup/download-blob-popup.component';
import { QuizForm101Component } from './content-upload-101/quiz-form101/quiz-form101.component';
import { CoursedetailsForm101Component } from './content-upload-101/coursedetails-form101/coursedetails-form101.component';
import { ContentUpload101PopupsComponent } from './content-upload-101/content-upload101-popups/content-upload101-popups.component';
import { MatButtonModule } from '@angular/material/button';
import { ContentUploadPackageComponent } from './content-upload-package/content-upload-package.component';
import { ContentUpload301Component } from './content-upload301/content-upload301.component';
import { UnitsAndTopicsComponent } from './content-upload-101/units-and-topics/units-and-topics.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { BulkUploadTemplateComponent } from './bulk-upload-template/bulk-upload-template.component';
import { MatTableModule } from '@angular/material/table';
// import { PssModule } from '../pss/pss.module';

@NgModule({
  imports: [
    CommonModule,
    AoRoutingModule,
    ShareContentModule,

    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMenuModule,
    MatTableModule,
    ReusableUiModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,

    DirectivesModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,

    PipesModule,
    DragDropModule,
    ContentVerificationModule,
    YorbitCoursesModule,
    SearchEvaluatorModule,
    DownloadBlobPopupModule,
    // PssModule
  ],
  declarations: [
    AoComponent,
    NewCourseRequestApprovalComponent,
    ContentUploadComponent,
    ContentUploadPopupComponent,
    ContentUploadStatusPopupComponent,
    QuizForm101Component,
    CoursedetailsForm101Component,
    ContentUpload101PopupsComponent,
    ContentUploadPackageComponent,
    ContentUpload301Component,
    UnitsAndTopicsComponent,
    BulkUploadTemplateComponent
  ],

})
export class AoModule { }
