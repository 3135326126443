// tslint:disable-next-line:no-inferrable-types
export const region: string = 'prod';

// region === 'local' | 'dev' | 'fort' | 'knights' | 'st' | 'bunker' | 'staging' | 'prod' | 'next' | 'pre-prod'

//dev
// export const config =
// {
//   appUrl: 'https://yorbitdev.azurewebsites.net/',
//   //appUrl: 'http://localhost:4200/',
//   apiUrl: 'https://yorbitapidev.azurewebsites.net/',
//   apiUrlYorbitYammer: 'https://yorbitdev.azurewebsites.net/detailsPage/',
//   apiUrlAPYammer: 'https://yorbitdev.azurewebsites.net/PdetailsPage/',
//   appInsightKey: '0781f5de-4a71-4a8a-88a6-80c1b4ad5b66',
//   adalConfig: {
//     tenant: 'mindtreeonline.onmicrosoft.com',
//     clientId: 'd5324e3c-3c47-4053-b30c-08d5ca192eb9',
//     endpoints: {
//       'https://yorbitapidev.azurewebsites.net':
//         '934e7127-1aba-4835-9325-57d80d112f75', //DEV
//       'https://mindtreeonline.sharepoint.com':
//         'https://mindtreeonline.sharepoint.com',
//       'https://api.powerbi.com': 'https://analysis.windows.net/powerbi/api',
//       'https://graph.windows.net': '00000002-0000-0000-c000-000000000000',
//       'https://graph.microsoft.com': 'https://graph.microsoft.com'
//     },
//     redirectUri: '',
//     postLogoutRedirectUri: '',
//     cacheLocation: 'localStorage'
//   },
//   graphUrl: 'https://graph.microsoft.com/v1.0/users',
//   IDPUrl: 'https://corpappsazqa.azurewebsites.net/IDP',
//   isProd: false,
//   isSiteDown: false,
//   yammerFeedId: 9595419,
//   authClientId: 'a37e1a24-ee64-4c69-9180-20f04907bade',
//   authority: 'https://login.microsoftonline.com/ltimindtree.onmicrosoft.com',
//   scopes: ['https://yorbitqaapi.ltimindtree.com/user_impersonation']
// };

// //bunker
// export const config = {
//     appUrl: 'https://yorbitdev-bunker.azurewebsites.net/',
//     apiUrl: 'https://yorbitapidev-bunker.azurewebsites.net/',
//     apiUrlYorbitYammer: 'https://yorbitdev-bunker.azurewebsites.net/detailsPage/',
//     apiUrlAPYammer: 'https://yorbitdev-bunker.azurewebsites.net/PdetailsPage/',
//     appInsightKey: '0781f5de-4a71-4a8a-88a6-80c1b4ad5b66',
//     adalConfig: {
//         tenant: 'mindtreeonline.onmicrosoft.com',
//         clientId: 'd5324e3c-3c47-4053-b30c-08d5ca192eb9',
//         endpoints: {
//             'https://yorbitapidev-bunker.azurewebsites.net':
//                 '934e7127-1aba-4835-9325-57d80d112f75',
//             'https://mindtreeonline.sharepoint.com':
//                 'https://mindtreeonline.sharepoint.com',
//             'https://graph.windows.net': '00000002-0000-0000-c000-000000000000',
//             'https://graph.microsoft.com': 'https://graph.microsoft.com',
//             'https://api.powerbi.com': 'https://analysis.windows.net/powerbi/api'
//         },
//         redirectUri: '',
//         postLogoutRedirectUri: '',
//         cacheLocation: 'localStorage'
//     },
//     graphUrl: 'https://graph.microsoft.com/v1.0/users',
//     IDPUrl: 'https://corpappsazqa.azurewebsites.net/IDP',
//     isProd: false,
//     isSiteDown: false,
//     yammerFeedId: 9595419,
//     authClientId: 'a37e1a24-ee64-4c69-9180-20f04907bade',
//     authority: 'https://login.microsoftonline.com/ltimindtree.onmicrosoft.com',
//     scopes: ['https://yorbitqaapi.ltimindtree.com/user_impersonation']
// };

//fort
// export const config = {
//   appUrl: 'https://yorbitdev-fort.azurewebsites.net/',
//   apiUrl: 'https://yorbitapidev-fort.azurewebsites.net/',
//   apiUrlYorbitYammer: 'https://yorbitdev-fort.azurewebsites.net/detailsPage/',
//   apiUrlAPYammer: 'https://yorbitdev-fort.azurewebsites.net/PdetailsPage/',
//   appInsightKey: '0781f5de-4a71-4a8a-88a6-80c1b4ad5b66',
//   adalConfig: {
//     tenant: 'mindtreeonline.onmicrosoft.com',
//     clientId: 'd5324e3c-3c47-4053-b30c-08d5ca192eb9',
//     endpoints: {
//       'https://yorbitapidev-fort.azurewebsites.net':
//         '934e7127-1aba-4835-9325-57d80d112f75',
//       'https://mindtreeonline.sharepoint.com':
//         'https://mindtreeonline.sharepoint.com',
//       'https://graph.windows.net': '00000002-0000-0000-c000-000000000000',
//       'https://graph.microsoft.com': 'https://graph.microsoft.com',
//       'https://api.powerbi.com': 'https://analysis.windows.net/powerbi/api'
//     },
//     redirectUri: '',
//     postLogoutRedirectUri: '',
//     cacheLocation: 'localStorage'
//   },
//   graphUrl: 'https://graph.microsoft.com/v1.0/users',
//   IDPUrl: 'https://corpappsazqa.azurewebsites.net/IDP',
//   isProd: false,
//   isSiteDown: false,
//   yammerFeedId: 9595419,
//   authClientId: 'a37e1a24-ee64-4c69-9180-20f04907bade',
//   authority: 'https://login.microsoftonline.com/ltimindtree.onmicrosoft.com',
//   scopes: ['https://yorbitqaapi.ltimindtree.com/user_impersonation']
// };

//knights
// export const config = {
//   //  appUrl: 'https://yorbitdev-knights.azurewebsites.net/',
//    appUrl: 'http://localhost:4200/',
//    apiUrl: 'https://yorbitapidev-knights.azurewebsites.net/',
//    apiUrlYorbitYammer:
//        'https://yorbitdev-knights.azurewebsites.net/detailsPage/',
//    apiUrlAPYammer: 'https://yorbitdev-knights.azurewebsites.net/PdetailsPage/',
//    appInsightKey: '0781f5de-4a71-4a8a-88a6-80c1b4ad5b66',
//    adalConfig: {
//        tenant: 'mindtreeonline.onmicrosoft.com',
//        clientId: 'd5324e3c-3c47-4053-b30c-08d5ca192eb9',
//        endpoints: {
//            'https://yorbitapidev-knights.azurewebsites.net':
//                '934e7127-1aba-4835-9325-57d80d112f75',
//            'https://mindtreeonline.sharepoint.com':
//                'https://mindtreeonline.sharepoint.com',
//            'https://api.powerbi.com': 'https://analysis.windows.net/powerbi/api',
//            'https://graph.windows.net': '00000002-0000-0000-c000-000000000000',
//            'https://graph.microsoft.com': 'https://graph.microsoft.com'
//        },
//        redirectUri: '',
//        postLogoutRedirectUri: '',
//        cacheLocation: 'localStorage'
//    },
//    graphUrl: 'https://graph.microsoft.com/v1.0/users',
//    IDPUrl: 'https://corpappsazqa.azurewebsites.net/IDP',
//    isProd: false,
//    isSiteDown: false,
//    yammerFeedId: 9595419,
//    authClientId: 'a37e1a24-ee64-4c69-9180-20f04907bade',
//    authority: 'https://login.microsoftonline.com/ltimindtree.onmicrosoft.com',
//    scopes: ['https://yorbitqaapi.ltimindtree.com/user_impersonation']
// };

// //next
// export const config = {
//   appUrl: 'https://yorbitdev-next.azurewebsites.net/',
//   apiUrl: 'https://yorbitapidev-next.azurewebsites.net/',
//   apiUrlYorbitYammer: 'https://yorbitdev-next.azurewebsites.net/detailsPage/',
//   apiUrlAPYammer: 'https://yorbitdev-next.azurewebsites.net/PdetailsPage/',
//   appInsightKey: '0781f5de-4a71-4a8a-88a6-80c1b4ad5b66',
//   adalConfig: {
//     tenant: 'mindtreeonline.onmicrosoft.com',
//     clientId: 'd5324e3c-3c47-4053-b30c-08d5ca192eb9',
//     endpoints: {
//       'https://yorbitapidev-next.azurewebsites.net':
//         '934e7127-1aba-4835-9325-57d80d112f75',
//       'https://mindtreeonline.sharepoint.com':
//         'https://mindtreeonline.sharepoint.com',
//       'https://api.powerbi.com': 'https://analysis.windows.net/powerbi/api',
//       'https://graph.windows.net': '00000002-0000-0000-c000-000000000000',
//       'https://graph.microsoft.com': 'https://graph.microsoft.com'
//     },
//     redirectUri: '',
//     postLogoutRedirectUri: '',
//     cacheLocation: 'localStorage'
//   },
//   graphUrl: 'https://graph.microsoft.com/v1.0/users',
//   IDPUrl: 'https://corpappsazqa.azurewebsites.net/IDP',
//   isProd: false,
//   isSiteDown: false,
//   yammerFeedId: 9595419,
//   authClientId: 'a37e1a24-ee64-4c69-9180-20f04907bade',
//   authority: 'https://login.microsoftonline.com/ltimindtree.onmicrosoft.com',
//   scopes: ['https://yorbitqaapi.ltimindtree.com/user_impersonation']
// };

// local(UI)
// export const config = {
//   appUrl: 'http://localhost:4200/',
//   apiUrl: 'https://yorbitapidev.azurewebsites.net/',
//   apiUrlYorbitYammer: 'http://localhost:4200/detailsPage/',
//   apiUrlAPYammer: 'https://yorbitapidev.azurewebsites.net/PdetailsPage/',
//   appInsightKey: '0781f5de-4a71-4a8a-88a6-80c1b4ad5b66',
//   adalConfig: {
//     tenant: 'mindtreeonline.onmicrosoft.com',
//     clientId: 'd5324e3c-3c47-4053-b30c-08d5ca192eb9',
//     endpoints: {
//       'https://yorbitapidev.azurewebsites.net/':
//         '934e7127-1aba-4835-9325-57d80d112f75', //DEV
//       'https://mindtreeonline.sharepoint.com':
//         'https://mindtreeonline.sharepoint.com',
//       "https://api.powerbi.com": "https://analysis.windows.net/powerbi/api",
//       'https://graph.windows.net': '00000002-0000-0000-c000-000000000000',
//       'https://graph.microsoft.com': 'https://graph.microsoft.com'
//     },
//     redirectUri: '',
//     postLogoutRedirectUri: '',
//     cacheLocation: 'localStorage'
//   },
//   graphUrl: 'https://graph.microsoft.com/v1.0/users',
//   IDPUrl: 'https://corpappsazqa.azurewebsites.net/IDP',
//   isProd: false,
//   isSiteDown: false,
//   yammerFeedId: 9595419,
//   authClientId: 'a37e1a24-ee64-4c69-9180-20f04907bade',
//   authority: 'https://login.microsoftonline.com/ltimindtree.onmicrosoft.com',
//   scopes: ['https://yorbitqaapi.ltimindtree.com/user_impersonation']
// };

// local(UI & API)
// export const config = {
//   appUrl: 'http://localhost:4200/',
//   apiUrl: 'http://localhost:51703/',
//   apiUrlYorbitYammer: 'http://localhost:4200/detailsPage/',
//   apiUrlAPYammer: 'https://yorbitdev.azurewebsites.net/PdetailsPage/',
//   appInsightKey: '0781f5de-4a71-4a8a-88a6-80c1b4ad5b66',
//   adalConfig: {
//     tenant: 'mindtreeonline.onmicrosoft.com',
//     clientId: 'd5324e3c-3c47-4053-b30c-08d5ca192eb9',
//     endpoints: {
//       'http://localhost:51703':
//         '934e7127-1aba-4835-9325-57d80d112f75', //DEV
//       'https://mindtreeonline.sharepoint.com':
//         'https://mindtreeonline.sharepoint.com',
//       "https://api.powerbi.com": "https://analysis.windows.net/powerbi/api",
//       'https://graph.windows.net': '00000002-0000-0000-c000-000000000000',
//       'https://graph.microsoft.com': 'https://graph.microsoft.com'
//     },
//     redirectUri: '',
//     postLogoutRedirectUri: '',
//     cacheLocation: 'localStorage'
//   },
//   graphUrl: 'https://graph.microsoft.com/v1.0/users',
//   IDPUrl: 'https://corpappsazqa.azurewebsites.net/IDP',
//   isProd: false,
//   isSiteDown: false,
//   yammerFeedId: 9595419,
//   authClientId: 'a37e1a24-ee64-4c69-9180-20f04907bade',
//   authority: 'https://login.microsoftonline.com/ltimindtree.onmicrosoft.com',
//   scopes: ['https://yorbitqaapi.ltimindtree.com/user_impersonation']
// };

// //staging
// export const config = {
//     appUrl: 'https://yorbitst-staging.azurewebsites.net/',
//     apiUrl: 'https://yorbitapist-staging.azurewebsites.net/',
//     apiUrlYorbitYammer: 'https://yorbitdev.azurewebsites.net/detailsPage/',
//     apiUrlAPYammer: 'https://yorbitdev.azurewebsites.net/PdetailsPage/',
//     appInsightKey: '0781f5de-4a71-4a8a-88a6-80c1b4ad5b66',
//     adalConfig: {
//         tenant: 'mindtreeonline.onmicrosoft.com',
//         clientId: 'd5324e3c-3c47-4053-b30c-08d5ca192eb9',
//         endpoints: {
//             'https://yorbitapist-staging.azurewebsites.net':
//                 '934e7127-1aba-4835-9325-57d80d112f75', //DEV
//             'https://mindtreeonline.sharepoint.com':
//                 'https://mindtreeonline.sharepoint.com',
//             'https://api.powerbi.com': 'https://analysis.windows.net/powerbi/api',
//             'https://graph.windows.net': '00000002-0000-0000-c000-000000000000',
//             'https://graph.microsoft.com': 'https://graph.microsoft.com'
//         },
//         redirectUri: '',
//         postLogoutRedirectUri: '',
//         cacheLocation: 'localStorage'
//     },
//     graphUrl: 'https://graph.windows.net/azurewebsites.net/users',
//     IDPUrl: 'https://corpappsazqa.azurewebsites.net/IDP',
//     isProd: false,
//     isSiteDown: false,
//     yammerFeedId: 9595419,
//     authClientId: 'a37e1a24-ee64-4c69-9180-20f04907bade',
//     authority: 'https://login.microsoftonline.com/ltimindtree.onmicrosoft.com',
//     scopes: ['https://yorbitqaapi.ltimindtree.com/user_impersonation']
// };

//Preprod
// export const config = {
//   appUrl: 'https://yorbitpreprod.azurewebsites.net/',
//   apiUrl: 'https://yorbitpreprodapi.azurewebsites.net/',
//   apiUrlYorbitYammer: 'https://yorbitst.azurewebsites.net/detailsPage/',
//   apiUrlAPYammer: 'https://yorbitst.azurewebsites.net/PdetailsPage/',
//   appInsightKey: '0781f5de-4a71-4a8a-88a6-80c1b4ad5b66',
//   adalConfig: {
//     tenant: 'mindtreeonline.onmicrosoft.com',
//     clientId: '1b43da47-e691-4ccc-8c2f-9068c24a1700',
//     //clientId: 'd5324e3c-3c47-4053-b30c-08d5ca192eb9',
//     endpoints: {
//       'https://yorbitpreprodapi.azurewebsites.net':
//         '443be25e-43b5-4132-8425-1598b55cff02', //DEV
//       'https://mindtreeonline.sharepoint.com':
//         'https://mindtreeonline.sharepoint.com',
//       'https://api.powerbi.com': 'https://analysis.windows.net/powerbi/api',
//       'https://graph.windows.net': '00000002-0000-0000-c000-000000000000',
//       'https://graph.microsoft.com': 'https://graph.microsoft.com'
//     },
//     redirectUri: 'https://yorbitpreprod.azurewebsites.net',
//     postLogoutRedirectUri: 'https://yorbitpreprod.azurewebsites.net',
//     cacheLocation: 'localStorage'
//   },
//   graphUrl: 'https://graph.microsoft.com/v1.0/users',
//   IDPUrl: 'https://corpappsazqa.azurewebsites.net/IDP',
//   isProd: false,
//   isSiteDown: false,
//   yammerFeedId: 9595419,
//   authClientId: 'a37e1a24-ee64-4c69-9180-20f04907bade',
//   authority: 'https://login.microsoftonline.com/ltimindtree.onmicrosoft.com',
//   scopes: ['https://yorbitqaapi.ltimindtree.com/user_impersonation']
// };

//st
// export const config = {
//   appUrl: 'https://yorbitst.azurewebsites.net/',
//   apiUrl: 'https://yorbitapist.azurewebsites.net/',
//   apiUrlYorbitYammer: 'https://yorbitst.azurewebsites.net/detailsPage/',
//   apiUrlAPYammer: 'https://yorbitst.azurewebsites.net/PdetailsPage/',
//   appInsightKey: '0781f5de-4a71-4a8a-88a6-80c1b4ad5b66',
//   adalConfig: {
//     tenant: 'mindtreeonline.onmicrosoft.com',
//     clientId: '1b43da47-e691-4ccc-8c2f-9068c24a1700',
//     //clientId: 'd5324e3c-3c47-4053-b30c-08d5ca192eb9',
//     endpoints: {
//       'https://yorbitapist.azurewebsites.net':
//         '443be25e-43b5-4132-8425-1598b55cff02', //DEV
//       'https://mindtreeonline.sharepoint.com':
//         'https://mindtreeonline.sharepoint.com',
//       'https://api.powerbi.com': 'https://analysis.windows.net/powerbi/api',
//       'https://graph.windows.net': '00000002-0000-0000-c000-000000000000',
//       'https://graph.microsoft.com': 'https://graph.microsoft.com'
//     },
//     redirectUri: 'https://yorbitst.azurewebsites.net',
//     postLogoutRedirectUri: 'https://yorbitst.azurewebsites.net',
//     cacheLocation: 'localStorage'
//   },
//   graphUrl: 'https://graph.microsoft.com/v1.0/users',
//   IDPUrl: 'https://corpappsazqa.azurewebsites.net/IDP',
//   isProd: false,
//   isSiteDown: false,
//   yammerFeedId: 9595419,
//   authClientId: 'a37e1a24-ee64-4c69-9180-20f04907bade',
//   authority: 'https://login.microsoftonline.com/ltimindtree.onmicrosoft.com',
//   scopes: ['https://yorbitqaapi.ltimindtree.com/user_impersonation']
// };

//prod
export const config = {
  appUrl: 'https://shoshin.ltimindtree.com/',
  apiUrl: 'https://yorbitapi.ltimindtree.com/',
  apiUrlYorbitYammer: 'https://shoshin.ltimindtree.com/detailsPage/',
  apiUrlAPYammer: 'https://shoshin.ltimindtree.com/PdetailsPage/',
  appInsightKey: '6598176a-c8f6-4cdf-a956-4e842c66bb42',
  adalConfig: {
    tenant: 'ltimindtree.onmicrosoft.com',
    clientId: '96ddf7da-b0b3-45c8-8085-e45788ad0a70',
    endpoints: {
      'https://yorbitapi.ltimindtree.com': '934e7127-1aba-4835-9325-57d80d112f75',
      'https://mindtreeonline.sharepoint.com': 'https://mindtreeonline.sharepoint.com',
      'https://api.powerbi.com': 'https://analysis.windows.net/powerbi/api',
      'https://graph.windows.net': '00000002-0000-0000-c000-000000000000',
      'https://graph.microsoft.com': 'https://graph.microsoft.com'
    },
    redirectUri: '',
    postLogoutRedirectUri: '',
    cacheLocation: 'localStorage'
  },
  graphUrl: 'https://graph.microsoft.com/v1.0/users',
  IDPUrl: 'https://corpapps.ltimindtree.com/IDP',
  isProd: true,
  isSiteDown: false,
  yammerFeedId: 7457097,
  authClientId: '96ddf7da-b0b3-45c8-8085-e45788ad0a70',
  authority: 'https://login.microsoftonline.com/ltimindtree.onmicrosoft.com',
  scopes: ['https://yorbitprodapi.ltimindtree.com/user_impersonation']
};

