import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DiversityAndInclusionComponent } from './diversity-and-inclusion.component';
import { VideoContainerComponent } from './video-container/video-container.component';
import { VideoPlayersModule } from '../shared/video-players/video-players.module';
import { RelatedVideoComponent } from './related-video/related-video.component';
import { ArticleTileComponent } from './article-tile/article-tile.component';
import { ContentTilesModule } from '../shared/content-tiles/content-tiles.module';
import { DiversityAndInclusionRoutingModule } from './diversity-and-inclusion.routeing';
import { ReusableUiModule } from '@YorbitWorkspace/reusable-ui';

@NgModule({
  declarations: [
    DiversityAndInclusionComponent,
    VideoContainerComponent,
    RelatedVideoComponent,
    ArticleTileComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDividerModule,
    ReusableUiModule,
    MatIconModule,
    VideoPlayersModule,
    ContentTilesModule,
    DiversityAndInclusionRoutingModule
  ],

  exports: [DiversityAndInclusionComponent, VideoContainerComponent,
    RelatedVideoComponent,
    ArticleTileComponent]
})
export class DiversityAndInclusionModule {}
