<div class="diversity-and-inclusion" fxLayout="column" fxLayoutAlign="start stretch" >

  <!--D&I Banner -->
  <div class="banner-holder" fxLayout="column" >
    <div class="banner" [ngClass.xs]="['xs']" [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']">
      <div class="desktop-banner" fxLayout="row" fxLayoutAlign="center" [ngClass.xs]="['xs']" [ngClass.md]="['md']"
        [ngClass.gt-md]="['gt-md']">
        <!-- <div class="title" fxLayout="row" fxLayoutAlign="start center">
          <div fxLayout="column" >
            Diversity <br> & Inclusion
            <div class="line"></div>
          </div>
        </div> -->

        <div fxLayout="row" fxLayoutAlign="start center"  fxFlex.md="888px" fxFlex.gt-md="1080px">
            <span class="title">
              Diversity, Equity & Inclusion
            </span>
        </div>
      </div>


      <div class="mobile-banner" [ngClass.xs]="['xs']" [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']"
        fxLayout="column">
        <div fxFlex="95%" fxLayout="row" fxLayoutAlign="center center" class="title">
          Diversity, Equity & Inclusion 
        </div>
        <div fxFlex="5%">
          <div class="linecustom"></div>
        </div>

      </div>
    </div>
  </div>

  <div id="videoContainer"></div>

  <div fxLayout="column"   class="video-section1" [ngClass.xs]="['xs']" fxLayoutGap="3%" fxLayoutAlign="start stretch" >


    <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="isVideoLoading">
      <app-preloader></app-preloader>
    </div>

    <!-- Video's Section -->
    <div class="video-section"  [ngClass.xs]="['xs']" fxLayout="row" fxLayoutAlign="center stretch" >
      <div fxLayout="row" fxLayoutAlign="stretch stretch" fxLayout.xs="column" fxLayoutGap="6%" fxFlex.xs="100%"
        fxFlex.md="888px" fxFlex.gt-md="1080px">
        <div fxLayout="column" class='video' fxFlex.md="1 0 70%" fxFlex.gt-md="1 0 70%">
          <yorbit-video-container *ngIf="selectedVideo" [videoData]="selectedVideo">
          </yorbit-video-container>
        </div>
        <div fxLayout="column" class='related-video-section' fxFlex="1 1 auto" fxFlex.md="1 0 25%"
          fxFlex.gt-md="1 0 25%">
          <label class='related-videos' *ngIf="!isVideoLoading && videoList.length>1">Related Videos</label>

          <!-- <div fxLayout="row"  fxFlex fxLayoutAlign="center center" *ngIf="!isVideoLoading && videoList.length<=1">
          <span  class="not-data-found">Related Videos Not Found!!! </span>
         </div> -->
          <div class="related-video-container" fxLayout="column" fxLayout.xs="row"
            *ngIf="!isVideoLoading && videoList.length>1">
            <div *ngFor="let item of videoList;index as i">
              <yorbit-related-video *ngIf="item.URl!=selectedVideo.URl" [videoData]="item"
                (click)="clickOfReltatedVideoSelect(item)"></yorbit-related-video>
            </div>

          </div>
        </div>
      </div>
    </div>

    <mat-divider class="line" [ngClass.xs]="['xs']" [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']"></mat-divider>

    <!-- Diversity & Inclusion Article GRID -->
    <div class="article-section" [ngClass.xs]="['xs']" fxLayout="row" fxLayoutAlign="center stretch">
      <div fxLayout="column" fxLayoutGap="20px" fxFlex.xs="100%" fxFlex.md="910px" fxFlex.gt-md="1110px" 
      class="article-margin" [ngClass.xs]="['xs']" [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']">
        <!-- Header And Filter -->
        <div fxLayout="row" class="article-header" fxLayout.xs='column' [ngClass.xs]="['xs']" [ngClass.md]="['md']" [ngClass.gt-md]="['gt-md']" >
          <div class="section-header" [ngClass.xs]="['xs']" fxFlex.md="1 0 45%" fxFlex.gt-md="1 0 35%">Diversity, Equity & Inclusion</div>
          <div fxLayout="row" fxFlex.md="1 0 55%" fxFlex.gt-md="1 0 65%" fxLayoutAlign="end center"
            fxLayoutAlign.xs="start center">
            <div *ngFor="let item of ['all','video','article','Publication']" class="filter-tag active"
              [class.selected-filter-tag]="selectedArticleFilter === item" (click)="clickSelectedFilter(item)">
              <label>{{item}}</label>
            </div>
            <img class="reload-icon" (click)="clickSelectedFilter('all')"
              src="../../../../assets/images/DandI/ic-reload.png" alt="">
          </div>
        </div>

        <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="isArticleLoading">
          <app-preloader></app-preloader>
        </div>

        <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="!isArticleLoading && articleList.length==0">
          <span *ngIf="selectedArticleFilter=='article'" class="not-data-found">Article(s) currently not available
          </span>
          <span *ngIf="selectedArticleFilter=='video'" class="not-data-found">Video(s) currently not available </span>
          <span *ngIf="selectedArticleFilter=='Publication'" class="not-data-found">Publication(s) currently not
            available </span>
        </div>
 

        <!-- Grid Articles -->
        <div fxLayout="row wrap" fxLayoutGap.gt-md="27px" fxLayoutGap.md="27px"  *ngIf="!isArticleLoading" >
          <div *ngFor="let item of articleList; index as i"  class="article" [ngClass.xs]="['xs']">
            <yorbit-article-tile (click)="item.Type=='Video'?clickOfArticleTile(item):''" [articleDetail]="item"
              *ngIf='i<8 || seeMoreFlag'></yorbit-article-tile>
          </div>
        </div>

 
        <!-- Show More Artcles -->
        <div fxLayout="row" fxLayoutAlign="center" class="see-more"
          *ngIf="articleList.length>8">
          <span (click)="seeMoreFlag=!seeMoreFlag">{{seeMoreFlag?'See Less':'See More'}}</span>
        </div>

      </div>

    </div>

    <!-- Courses On Diversity And Inclusion -->
    <div fxLayout="row" class="courses-on-di" [ngClass.xs]="['xs']" fxLayoutAlign="center stretch">
      <div fxLayout="column" fxFlex.xs="100%" fxFlex.md="888px" fxFlex.gt-md="1080px">
        <div class="section-header-courses-on-di" [ngClass.xs]="['xs']">Courses on Diversity, Equity & Inclusion</div>

        <div fxLayout="row" fxFlex fxLayoutAlign="center center" *ngIf="isCoursesLoading">
          <app-preloader></app-preloader>
        </div>

        <div fxLayout="row wrap" class="course-container" [ngClass.xs]="['xs']" [ngClass.md]="['md']"
          [ngClass.gt-md]="['gt-md']" fxLayout.xs="row" *ngIf="!isCoursesLoading">
          <div class="browsestyle" *ngFor="let item of coursesList">
            <yorbit-content-tile-browse [cardDetails]="item" [featuredIn]="'Browse'" fxFlex="230px"
              fxLayoutAlign="start start" fxFlexFill>
            </yorbit-content-tile-browse>

          </div>


        </div>
      </div>

    </div>


  </div>

</div>