import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as fromUserDetailsStore from '../user-details/store';
import { select, Store } from '@ngrx/store';
import { ArrayPropertyFilterPipe } from '@YorbitWorkspace/pipes';
import { Globals } from '../../globals';
import { BehaviorSubject, from, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as fromCloudlabStore from '../cloudlab-store/store';
import * as fromRoleBasedStore from '../../shared/content-tiles/rolebased-store/store';

import { Router } from '@angular/router';
import {
  Ienvironment,
  EnvironmentService
} from '@YorbitWorkspace/global-environments';
@Injectable({
  providedIn: 'root'
})
export class AddedContentDataService {
  config: Ienvironment;
  arrayFilterPipe: any;
  contentAddedDetails = null;
  contentAddedDeatilsForSearch = [];
  contentAddedDetailsLoaded = new BehaviorSubject<boolean>(false);
  contentAddedDetailsForSearchLoaded = new BehaviorSubject<boolean>(false);
  contentAddedDetailsForPackagesLoaded = new BehaviorSubject<boolean>(false);
  isContentAdded = <BehaviorSubject<boolean>>new BehaviorSubject(false);
  packageCourseMappings = null;
  getAddedContentDetailsPromise = null;
  riskBrigadeItemType: string = 'RiskBrigadeCourse';
  constructor(
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private cloudlabStore: Store<fromCloudlabStore.IcloudlabState>,
    private globals: Globals,
    private router: Router,
    private http: HttpClient,
    private roleBasedStore: Store<fromRoleBasedStore.IrolebasedData>,
    private _envSvc: EnvironmentService,
    private _store: Store
  ) {
    this.config = this._envSvc.getEnvironment();
    this.arrayFilterPipe = new ArrayPropertyFilterPipe();
  }
  addedContentDetailsInit() {
    this.contentAddedDetails = null;
    this.contentAddedDeatilsForSearch = [];
    this.contentAddedDetailsLoaded = new BehaviorSubject<boolean>(false);
    this.contentAddedDetailsForSearchLoaded = new BehaviorSubject<boolean>(false);
    this.contentAddedDetailsForPackagesLoaded = new BehaviorSubject<boolean>(false);
    this.isContentAdded = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.packageCourseMappings = null;
  }
  getContentAdditionStatus() {
    return this.isContentAdded.asObservable();
  }
  IsContentAddedToLP(content) {
    const promise = new Promise((resolve, reject) => {
      let contentAdded = false;
      let LPDetails = null;
      let addedStatus = {
        IsAdded: contentAdded,
        addedLPDetails: {
          LPId: '',
          LPCategory: '',
          CourseId: '',
          PackageId: null
        }
      };
      if (content) {
        if (content.ItemId != undefined) {
          content.Id = content.ItemId.toString();
        } else if (content.Id) {
          content.Id = content.Id.toString();
        }
        if (content.AccountPackage == "true" || content.AccountPackage === true) {
          content.AccountPackage = true;
        }
        else {
          content.AccountPackage = false;
        }
        if (content.ProjectPackage == "true" || content.ProjectPackage === true) {
          content.ProjectPackage = true;
        }
        else {
          content.ProjectPackage = false;
        }
        if (content.AccountPackage || content.ProjectPackage) {
          content.Id = content.UniqueId;
          if (content.ItemType == 'Course') {
            if (this.contentAddedDetails.NonYorbitCourses[content.Id] && this.contentAddedDetails.NonYorbitCourses[content.Id].length > 0) {
              addedStatus.IsAdded = true;
              if (this.contentAddedDetails.NonYorbitCourses[content.Id].length == 1 &&
                this.contentAddedDetails.NonYorbitCourses[content.Id][0].PackageId) {
                addedStatus.addedLPDetails.PackageId = this.contentAddedDetails.NonYorbitCourses[content.Id][0].PackageId;
                LPDetails = this.contentAddedDetails.NonYorbitCourses[
                  content.Id
                ][0];
              } else if (this.contentAddedDetails.NonYorbitCourses[content.Id].length == 1) {
                addedStatus.addedLPDetails.PackageId = null;
                LPDetails = this.contentAddedDetails.NonYorbitCourses[
                  content.Id
                ][0];
              }
              if (this.contentAddedDetails.NonYorbitCourses[content.Id].length > 1) {
                let course = this.contentAddedDetails.NonYorbitCourses[content.Id].filter(x => x.PackageId == null || x.PackageId == "")[0];
                if (course) {
                  LPDetails = course;
                }
                else {
                  let mandatoryCourse = this.contentAddedDetails.NonYorbitCourses[content.Id].filter(x => x.IsMandatory && !x.IsSkillPlan)[0];
                  if (mandatoryCourse) {
                    LPDetails = mandatoryCourse;
                  } else {
                    let skillPlanCourse = this.contentAddedDetails.NonYorbitCourses[content.Id].filter(x => x.IsSkillPlan)[0];
                    if (skillPlanCourse) {
                      LPDetails = skillPlanCourse;
                    } else {
                      let userAddedCourse = this.contentAddedDetails.NonYorbitCourses[content.Id].filter(x => !x.IsMandatory && !x.IsSkillPlan)[0];
                      LPDetails = userAddedCourse;
                    }
                  }
                }
              }
              addedStatus.addedLPDetails.CourseId = content.Id;
            }
          } else {
            if (this.contentAddedDetails.NonYorbitPackages[content.Id] && this.contentAddedDetails.NonYorbitPackages[content.Id].length > 0) {
              addedStatus.IsAdded = true;
              addedStatus.addedLPDetails.PackageId = content.Id;
              addedStatus.addedLPDetails.CourseId = null;
              let mandatoryPackage = this.contentAddedDetails.NonYorbitPackages[content.Id].filter(x => x.IsMandatory && !x.IsSkillPlan)[0];
              if (mandatoryPackage) {
                LPDetails = mandatoryPackage;
              } else {
                let userAddedPackage = this.contentAddedDetails.NonYorbitPackages[content.Id].filter(x => !x.IsMandatory && !x.IsSkillPlan)[0];
                LPDetails = userAddedPackage;
              }
            }
          }
        } else {
          if (content.ItemType == 'Course' || content.ItemType == this.riskBrigadeItemType) {
            if (this.contentAddedDetails.YorbitCourses[content.Id] && this.contentAddedDetails.YorbitCourses[content.Id].length > 0) {
              addedStatus.IsAdded = true;
              if (this.contentAddedDetails.YorbitCourses[content.Id].length == 1 &&
                this.contentAddedDetails.YorbitCourses[content.Id][0].PackageId) {
                addedStatus.addedLPDetails.PackageId = this.contentAddedDetails.YorbitCourses[content.Id][0].PackageId;
                LPDetails = this.contentAddedDetails.YorbitCourses[
                  content.Id
                ][0];
              } else if (this.contentAddedDetails.YorbitCourses[content.Id].length == 1) {
                addedStatus.addedLPDetails.PackageId = null;
                LPDetails = this.contentAddedDetails.YorbitCourses[
                  content.Id
                ][0];
              }
              if (this.contentAddedDetails.YorbitCourses[content.Id].length > 1) {
                let course = this.contentAddedDetails.YorbitCourses[content.Id].filter(x => x.PackageId == null || x.PackageId == "")[0];
                if (course) {
                  LPDetails = course;
                }
                else {
                  let mandatoryCourse = this.contentAddedDetails.YorbitCourses[content.Id].filter(x => x.IsMandatory && !x.IsSkillPlan)[0];
                  if (mandatoryCourse) {
                    LPDetails = mandatoryCourse;
                  } else {
                    let skillPlanCourse = this.contentAddedDetails.YorbitCourses[content.Id].filter(x => x.IsSkillPlan)[0];
                    if (skillPlanCourse) {
                      LPDetails = skillPlanCourse;
                    } else {
                      let userAddedCourse = this.contentAddedDetails.YorbitCourses[content.Id].filter(x => !x.IsMandatory && !x.IsSkillPlan)[0];
                      LPDetails = userAddedCourse;
                    }
                  }
                }
              }
              addedStatus.addedLPDetails.CourseId = content.Id;
            } else {
              //rblpackage
              if (this.contentAddedDetails.RblPackages) {

                Object.keys(this.contentAddedDetails.RblPackages).forEach(
                  value => {
                    let list = [];
                    this.roleBasedStore
                      .select(fromRoleBasedStore.getRolebasedDetailsByPackageId(value))
                      .subscribe(rblData => {
                        if (rblData) {
                          rblData.CoursesWithCategory.forEach((course, index) => {
                            if ("string" == typeof course.CourseIds) {
                              course.CourseIds.split(',').forEach((id) => {
                                list.push(id);
                              })
                            } else {
                              course.CourseIds.forEach((id) => {
                                list.push(id);
                              })
                            }
                          })
                        }
                      })


                    if (-1 != list.indexOf(content.Id)) {
                      addedStatus.IsAdded = true;
                      addedStatus.addedLPDetails.PackageId = value;
                      addedStatus.addedLPDetails.CourseId = content.Id;

                      LPDetails = this.contentAddedDetails.RblPackages[value][0];
                      LPDetails.PackageId = this.contentAddedDetails.RblPackages[value][0].Id;
                    }

                  });
              }

            }
          } else {
            if (this.contentAddedDetails.YorbitPackages[content.Id] && this.contentAddedDetails.YorbitPackages[content.Id].length > 0) {
              addedStatus.addedLPDetails.PackageId = content.Id;
              addedStatus.addedLPDetails.CourseId = null;
              addedStatus.IsAdded = true;
              let mandatoryPackage = this.contentAddedDetails.YorbitPackages[content.Id].filter(x => x.IsMandatory && !x.IsSkillPlan)[0];
              if (mandatoryPackage) {
                LPDetails = mandatoryPackage;
              } else {
                let userAddedPackage = this.contentAddedDetails.YorbitPackages[content.Id].filter(x => !x.IsMandatory && !x.IsSkillPlan)[0];
                LPDetails = userAddedPackage;
              }
            }
          }
        }
        if (addedStatus.IsAdded) {
          if (LPDetails.PackageId) {
            addedStatus.addedLPDetails.PackageId = LPDetails.PackageId;
          }
          this.isContentAdded.next(true);
          if (LPDetails.IsMandatory) {
            if (
              LPDetails.IsSkillPlan ||
              LPDetails.IsCrossSkill ||
              LPDetails.IsFutureSkill
            ) {
              addedStatus.addedLPDetails.LPCategory = 'skilling';
              addedStatus.addedLPDetails.LPId = LPDetails.PathId;
            } else if (
              LPDetails.PathName.toLowerCase() ==
              this.globals.CocLpName.toLowerCase() || LPDetails.PathName.toLowerCase() == this.globals.BEACLpName.toLowerCase() ||
              LPDetails.PathName.toLowerCase() ==
              'sexual harassment prevention training - usa' ||
              LPDetails.PathName.toUpperCase() == this.globals.newPOSHUSLpName

            ) {
              addedStatus.addedLPDetails.LPCategory = 'myCompliance';
              addedStatus.addedLPDetails.LPId = LPDetails.PathName;
            } else if (LPDetails.PathName.toLowerCase() == "role based learning") {
              addedStatus.addedLPDetails.LPCategory = 'rolebased';
              addedStatus.addedLPDetails.LPId = LPDetails.PathName;
            } else {
              addedStatus.addedLPDetails.LPCategory = 'mandatory';
              addedStatus.addedLPDetails.LPId = LPDetails.PathName;
            }
          } else {
            addedStatus.addedLPDetails.LPCategory = 'self';
            addedStatus.addedLPDetails.LPId = LPDetails.PathId;
          }
        }
        resolve(addedStatus);
      }
    });
    return promise;
  }
  getAddedContentDetails(userDetails) {
    this.contentAddedDeatilsForSearch = []
    // let userDetails$ = this._store.pipe(select(fromUserDetailsStore.selectUserDetails));
    // let subscription: Subscription; // Declare the subscription variable
    // subscription=userDetails$.subscribe(userDetails => {
    //   console.log("User Details:", userDetails);
    if (userDetails) {
      const addedContent = this.getAddedContent(userDetails);
      //console.log('addedContentssssssssssss', addedContent);
      if (addedContent) {
        this.contentAddedDetails = addedContent;
        if (this.contentAddedDetails.YorbitCourses) {
          let courseIdList = Object.keys(
            this.contentAddedDetails.YorbitCourses
          );
          courseIdList.forEach(value => {
            this.contentAddedDeatilsForSearch = this.contentAddedDeatilsForSearch.concat(
              this.contentAddedDetails.YorbitCourses[value]
            );
          });
        }
        if (this.contentAddedDetails.NonYorbitCourses) {
          Object.keys(this.contentAddedDetails.NonYorbitCourses).forEach(
            value => {
              this.contentAddedDeatilsForSearch = this.contentAddedDeatilsForSearch.concat(
                this.contentAddedDetails.NonYorbitCourses[value]
              );
            }
          );
        }
        if (this.contentAddedDetails.YorbitPackages) {
          Object.keys(this.contentAddedDetails.YorbitPackages).forEach(
            value => {
              this.contentAddedDeatilsForSearch = this.contentAddedDeatilsForSearch.concat(
                this.contentAddedDetails.YorbitPackages[value]
              );
            }
          );
        }
        if (this.contentAddedDetails.NonYorbitPackages) {
          Object.keys(this.contentAddedDetails.NonYorbitPackages).forEach(
            value => {
              this.contentAddedDeatilsForSearch = this.contentAddedDeatilsForSearch.concat(
                this.contentAddedDetails.NonYorbitPackages[value]
              );
            }
          );
        }
        if (this.contentAddedDetails.RblPackages) {
          Object.keys(this.contentAddedDetails.RblPackages).forEach(
            value => {
              this.contentAddedDeatilsForSearch = this.contentAddedDeatilsForSearch.concat(
                this.contentAddedDetails.RblPackages[value]
              );
            }
          );
        }
      } else {
        this.contentAddedDetails = {
          YorbitCourses: {},
          NonYorbitCourses: {},
          YorbitPackages: {},
          NonYorbitPackages: {},
          YorbitPackageIds: [],
          NonYorbitPackageIds: [],
          YorbitPackageCourseMappings: {},
          NonYorbitPackageCourseMappings: {},
          RblPackages: {},
          RblPackageIds: []
        };
      }
      //this.updatePackageCourseMappingsInStore();
      this.cloudlabStore.dispatch(
        new fromCloudlabStore.CloudlabGetStatus(
          Object.keys(this.contentAddedDetails.YorbitCourses)
        )
      );
      this.contentAddedDetailsLoaded.next(true);
      this.contentAddedDetailsForSearchLoaded.next(true);
      this.updatePackageCourseMappingsInStore();
    }
    // if (subscription) {
    //   subscription.unsubscribe(); // Unsubscribe after processing the data
    // }
    // });
  }
  searchForContentInsideLP(text) {
    const promise = new Promise((resolve, reject) => {
      this.contentAddedDetailsLoaded.subscribe(contentDetailsLoaded => {
        if (contentDetailsLoaded) {
          this.contentAddedDetailsForSearchLoaded
            .subscribe(searchDetailsLoaded => {
              if (searchDetailsLoaded) {
                var searchResults = this.contentAddedDeatilsForSearch.filter(
                  val => {
                    if (
                      val.Name.toLowerCase().indexOf(text.toLowerCase()) != -1
                    ) {
                      if (val.PackageId && val.Type == 'Course') {
                        if (
                          this.contentAddedDetails['NonYorbitPackages'][
                          val.PackageId
                          ] &&
                          this.contentAddedDetails['NonYorbitPackages'][
                            val.PackageId
                          ].length > 0
                        ) {
                          val.PackageName = this.contentAddedDetails[
                            'NonYorbitPackages'
                          ][val.PackageId][0].Name;
                        } else if (
                          this.contentAddedDetails['YorbitPackages'][
                          val.PackageId
                          ] &&
                          this.contentAddedDetails['YorbitPackages'][
                            val.PackageId
                          ].length > 0
                        ) {
                          val.PackageName = this.contentAddedDetails[
                            'YorbitPackages'
                          ][val.PackageId][0].Name;
                        } else if (this.contentAddedDetails['RblPackages'][val.PackageId]
                          && this.contentAddedDetails['RblPackages'][val.PackageId].length > 0) {
                          val.PackageName = this.contentAddedDetails[
                            'RblPackages'
                          ][val.PackageId][0].Name;
                        }
                        if (val.PackageName.length > 28) {
                          val.PackageName =
                            val.PackageName.substring(0, 25) + '...';
                        }
                        if (val.IsSkillPlan && val.PathName) {
                          val.PathName = val.PathName.substring(0, 16) + '...';
                        }
                      }
                      if (val.Name.length > 72) {
                        val.Name = val.Name.substring(0, 69) + '...';
                      }
                      return true;
                    } else {
                      return false;
                    }
                  }
                );
                if (searchResults.length > 0) {
                  resolve(searchResults);
                } else {
                  resolve(null);
                }
              }
            });
        }
      });
    });
    return promise;
  }
  addDataToAddedLPContentDetails(type, data) {
    if (this.contentAddedDetails[type]) {
      if (this.contentAddedDetails[type][data.Id]) {
        this.contentAddedDetails[type][data.Id].push(data);
      } else {
        this.contentAddedDetails[type][data.Id] = [];
        this.contentAddedDetails[type][data.Id].push(data);
      }
    }
    if (type == 'YorbitPackages') {
      this.contentAddedDetails['YorbitPackageIds'].push(data.Id);
    } else if (type == 'NonYorbitPackages') {
      this.contentAddedDetails['NonYorbitPackageIds'].push(data.Id);
    }
    //this.contentAddedDetails = { ...this.contentAddedDetails };
    this.contentAddedDeatilsForSearch.push(data);
    //console.log("testdata addDataToAddedLPContentDetails addedcontentdata", this.contentAddedDetails);
    //console.log("testdata addDataToAddedLPContentDetails contentaddeddetailsforsearch", this.contentAddedDeatilsForSearch);
  }

  updatePackageCourseMappingsInStore() {
    let userDetailsLoadedSubscription = this.userDetailsStore.select(fromUserDetailsStore.getUserDetailLoadedStatus).subscribe(loaded => {
      if (loaded) {
        if (!this.packageCourseMappings) {
          this.packageCourseMappings = {};
        }
        let packageIds = [
          ...this.contentAddedDetails.YorbitPackageIds,
          ...this.contentAddedDetails.NonYorbitPackageIds
        ];
        let userDetailsCourseProgressSubscription = this.userDetailsStore
          .select(fromUserDetailsStore.getCourseProgressListSelector)
          .subscribe(courseProgressList => {
            packageIds.forEach(packageId => {
              let courseIds = [];
              this.packageCourseMappings[packageId] = [];
              if (
                packageId &&
                this.contentAddedDetails.YorbitPackageCourseMappings[packageId]
              ) {
                courseIds = this.contentAddedDetails.YorbitPackageCourseMappings[
                  packageId
                ];
              } else if (
                packageId &&
                this.contentAddedDetails.NonYorbitPackageCourseMappings[packageId]
              ) {
                courseIds = this.contentAddedDetails.NonYorbitPackageCourseMappings[
                  packageId
                ];
              }
              if (courseIds) {
                courseIds.forEach(courseId => {
                  let courseDetails = this.arrayFilterPipe.transform(
                    courseProgressList,
                    {
                      property: 'CourseId',
                      flag: courseId.toString()
                    }
                  )[0];
                  if (courseDetails != undefined) {
                    if (courseDetails.Duration == null) {
                      courseDetails.Duration = '00:00:00';
                    }
                    let course = {
                      ItemId: courseDetails.CourseId,
                      RequestId: courseDetails.RequestID,
                      ItemType: 'Course',
                      ItemName: courseDetails.CourseName,
                      ItemExpertise: courseDetails.Expertise,
                      ImageUrl: '',
                      VideoCount: courseDetails.ContentCount,
                      Badges: [],
                      BadgeIds: null,
                      PackageProgress: {
                        Progress: courseDetails.Progress,
                        ConsumedTime: courseDetails.ConsumedTime,
                        TimeLeft: courseDetails.TimeLeft,
                        TotalTime: courseDetails.Duration
                      },
                      ProgressList: [],
                      Declaration: false,
                      IsMandatory: true,
                      AssignedBy:
                        courseDetails.IsAccount || courseDetails.IsProject
                          ? this.contentAddedDetails.NonYorbitPackages[packageId]
                            ? this.contentAddedDetails.NonYorbitPackages[packageId]
                              .AssignedBy
                            : ''
                          : this.contentAddedDetails.YorbitPackages[packageId]
                            ? this.contentAddedDetails.YorbitPackages[packageId]
                              .AssignedBy
                            : '',
                      AssignorRole: null,
                      Department: null,
                      IsCancelable: false,
                      AccountPackage: courseDetails.IsAccount,
                      ProjectPackage: courseDetails.IsProject,
                      Category: null,
                      AccountId: courseDetails.AccountId,
                      ProjectId: courseDetails.ProjectId,
                      WorflowStatus: courseDetails.WorflowStatus,
                      SubStatus: courseDetails.SubStatus,
                      DueDate: '0001-01-01T00:00:00',
                      IsDeleted: false,
                      CreatedDate: '0001-01-01T00:00:00',
                      ModifiedDate: '0001-01-01T00:00:00',
                      DeletedDate: '0001-01-01T00:00:00',
                      CompletedDate: '0001-01-01T00:00:00',
                      IsInIDP: false,
                      IsBlocked: false
                    };
                    this.userDetailsStore
                      .select(
                        fromUserDetailsStore.getBadgesByCourseIdSelector(courseId)
                      )
                      .subscribe(badge => {
                        //////console.log("badges",badge);
                        if (badge != undefined && badge != null && badge.BadgeId) {
                          course.Badges = [];
                          course.Badges.push(badge);
                        }
                      });
                    if (!this.packageCourseMappings[packageId]) {
                      this.packageCourseMappings[packageId] = [];
                    }
                    this.packageCourseMappings[packageId].push(course);
                  }
                });
              }
            });
          });
        this.contentAddedDetailsForPackagesLoaded.next(true);
        userDetailsCourseProgressSubscription.unsubscribe();
      };
    });
    userDetailsLoadedSubscription.unsubscribe();


  }
  updateCoursesInsidePackagesAfterAddition(
    type,
    learningPath,
    packageId,
    courseIds
  ) {
    if (!this.packageCourseMappings) {
      this.packageCourseMappings = {};
    }
    this.packageCourseMappings[packageId] = [];
    //update course ids in packages inside addedcontent details
    if (type == "Yorbit" &&
      packageId
    ) {
      this.contentAddedDetails.YorbitPackageCourseMappings[
        packageId
      ] = courseIds;
    } else if (type == "NonYorbit" &&
      packageId
    ) {
      this.contentAddedDetails.NonYorbitPackageCourseMappings[
        packageId
      ] = courseIds;
    }
    if (courseIds) {
      let getCourseProgressListSelectorSub = this.userDetailsStore
        .select(fromUserDetailsStore.getCourseProgressListSelector)
        .subscribe(courseProgressList => {
          //console.log(
          // 'courseProgressList inside updateCoursesInsidePackagesAfterAddition',
          packageId
          //);
          courseIds.forEach(courseId => {
            let courseDetails = this.arrayFilterPipe.transform(
              courseProgressList,
              {
                property: 'CourseId',
                flag: courseId.toString()
              }
            )[0];
            if (courseDetails != undefined) {
              if (courseDetails.Duration == null) {
                courseDetails.Duration = '00:00:00';
              }

              let course = {
                ItemId: courseDetails.CourseId,
                RequestId: courseDetails.RequestID,
                ItemType: 'Course',
                ItemName: courseDetails.CourseName,
                ItemExpertise: courseDetails.Expertise,
                ImageUrl: '',
                VideoCount: courseDetails.ContentCount,
                Badges: [],
                BadgeIds: null,
                PackageProgress: {
                  Progress: courseDetails.Progress,
                  ConsumedTime: courseDetails.ConsumedTime,
                  TimeLeft: courseDetails.TimeLeft,
                  TotalTime: courseDetails.Duration
                },
                ProgressList: [],
                Declaration: false,
                IsMandatory: true,
                AssignedBy: type == "Yorbit" ? this.contentAddedDetails.YorbitPackages[packageId]
                  .AssignedBy : this.contentAddedDetails.NonYorbitPackages[packageId]
                  .AssignedBy,
                AssignorRole: null,
                Department: null,
                IsCancelable: false,
                AccountPackage: courseDetails.IsAccount,
                ProjectPackage: courseDetails.IsProject,
                Category: null,
                AccountId: courseDetails.AccountId,
                ProjectId: courseDetails.ProjectId,
                WorflowStatus: courseDetails.WorflowStatus,
                SubStatus: courseDetails.SubStatus,
                DueDate: '0001-01-01T00:00:00',
                IsDeleted: false,
                CreatedDate: '0001-01-01T00:00:00',
                ModifiedDate: '0001-01-01T00:00:00',
                DeletedDate: '0001-01-01T00:00:00',
                CompletedDate: '0001-01-01T00:00:00',
                IsInIDP: false,
                IsBlocked: false
              };
              //update addedcontentdatails with courses under package
              let addedContentDetails = {
                PathId: learningPath.PathId,
                PathName: learningPath.PathName,
                IsMandatory: false,
                IsCrossSkill: false,
                IsFutureSkill: false,
                IsSkillPlan: false,
                Id: course.ItemId,
                PackageId: packageId,
                Expertise: course.ItemExpertise,
                Name: course.ItemName,
                AssignedBy: '',
                Type: course.ItemType,
                IsAccount: false,
                IsProject: false
              };
              let addedContentType =
                (!courseDetails.IsAccount && !courseDetails.IsProject) ? 'YorbitCourses' : 'NonYorbitCourses';
              this.addDataToAddedLPContentDetails(
                addedContentType,
                addedContentDetails
              );
              this.userDetailsStore
                .select(
                  fromUserDetailsStore.getBadgesByCourseIdSelector(courseId)
                )
                .subscribe(badge => {
                  //////console.log("badges",badge);
                  if (badge != undefined && badge != null && badge.BadgeId) {
                    course.Badges = [];
                    course.Badges.push(badge);
                  }
                });
              this.packageCourseMappings[packageId].push(course);
            }
          });
        });
      this.contentAddedDetailsForPackagesLoaded.next(true);
      getCourseProgressListSelectorSub.unsubscribe();
    }
    //console.log("testdata updateCoursesInsidePackagesAfterAddition addedcontentdata", this.contentAddedDetails);
    //console.log("testdata updateCoursesInsidePackagesAfterAddition contentaddeddetailsforsearch", this.contentAddedDeatilsForSearch);
  }
  deleteDataFromAddedLPContentDetails(type, data) {
    //delete entry from contentAddedDetails
    if (this.contentAddedDetails[type]) {
      if (this.contentAddedDetails[type][data.Id]) {
        this.contentAddedDetails[type][data.Id] = this.contentAddedDetails[type][data.Id].filter(val => {
          return !(val.Id == data.Id &&
            val.PackageId == data.PackageId &&
            val.PathId == data.PathId &&
            val.Type == data.Type);
        });
      }
    }
    //delete entry from contentAddedDetails for all courses inside package
    if (type == 'YorbitPackages') {
      this.contentAddedDetails['YorbitPackageIds'].splice(
        this.contentAddedDetails['YorbitPackageIds'].indexOf(data.Id),
        1
      );
      //get courses inside package and delete them from yorbitcourses

      this.packageCourseMappings[data.Id].forEach(course => {
        this.contentAddedDetails['YorbitCourses'][course.ItemId] = this.contentAddedDetails['YorbitCourses'][course.ItemId].filter(val => {
          return !(val.PackageId == data.Id &&
            val.PathId == data.PathId);
        });
      });
    } else if (type == 'NonYorbitPackages') {
      this.contentAddedDetails['NonYorbitPackageIds'].splice(
        this.contentAddedDetails['NonYorbitPackageIds'].indexOf(data.Id),
        1
      );
      //get courses inside package and delete them from both yorbitcourses and nonyorbitcourses
      this.packageCourseMappings[data.Id].forEach(course => {
        if (course.AccountId || course.ProjectId) {
          this.contentAddedDetails['NonYorbitCourses'][course.ItemId] = this.contentAddedDetails['NonYorbitCourses'][course.ItemId].filter(val => {
            return !(val.PackageId == data.Id &&
              val.PathId == data.PathId);
          });
        } else {
          this.contentAddedDetails['YorbitCourses'][course.ItemId] = this.contentAddedDetails['YorbitCourses'][course.ItemId].filter(val => {
            return !(val.PackageId == data.Id &&
              val.PathId == data.PathId)
          });
        }
      });
    }
    //delete entry from contentAddedDeatilsForSearch
    if (type == 'YorbitCourses' || type == 'NonYorbitCourses') {
      this.contentAddedDeatilsForSearch = this.contentAddedDeatilsForSearch.filter(val => {
        return !(val.Id == data.Id &&
          val.PathId == data.PathId &&
          val.Type == data.Type);
      });
    }
    if (type == 'YorbitPackages' || type == 'NonYorbitPackages') {
      this.contentAddedDeatilsForSearch = this.contentAddedDeatilsForSearch.filter(val => {
        return !(val.PackageId == data.Id &&
          val.PathId == data.PathId);
      });
    }
    //this.contentAddedDetails = { ...this.contentAddedDetails };
    //console.log("testdata deleteDataFromAddedLPContentDetails addedcontentdata", this.contentAddedDetails);
    //console.log("testdata deleteDataFromAddedLPContentDetails contentaddeddetailsforsearch", this.contentAddedDeatilsForSearch);
  }
  deleteAddedLPContentDetailsAfterLPRemoval(learningPath, packageList) {
    if (packageList) {
      //console.log("testdata deleteAddedLPContentDetailsAfterLPRemoval");
      packageList.forEach(content => {
        let addedContentDetails = {
          PathId: learningPath.PathId,
          PathName: learningPath.PathName,
          IsMandatory: false,
          IsCrossSkill: false,
          IsFutureSkill: false,
          IsSkillPlan: false,
          Id: content.ItemId,
          PackageId: content.ItemType == 'Course' ? '' : content.ItemId,
          Expertise: content.ItemExpertise,
          Name: content.ItemName,
          AssignedBy: '',
          Type: content.ItemType,
          IsAccount: false,
          IsProject: false
        };
        let addedContentType = '';
        if (content.AccountId || content.ProjectId) {
          addedContentDetails.IsAccount = content.AccountPackage;
          addedContentDetails.IsProject = content.ProjectPackage;
          if (content.ItemType == 'Course') {
            addedContentType = 'NonYorbitCourses';
          } else {
            addedContentType = 'NonYorbitPackages';
          }
        } else {
          if (content.ItemType == 'Course') {
            addedContentType = 'YorbitCourses';
          } else {
            addedContentType = 'YorbitPackages';
          }
        }
        this.deleteDataFromAddedLPContentDetails(
          addedContentType,
          addedContentDetails
        );
        //console.log("testdata deleteDataFromAddedLPContentDetails", content.ItemId, content.ItemName);
      });
    }
  }

  updateCourseProgressInPackage(PackageId, CourseId, Progress) {
    ////console.log("updateCourseProgressInPackage before", this.packageCourseMappings[PackageId])

    if (!this.packageCourseMappings) {
      this.packageCourseMappings = {};
    }
    if (this.packageCourseMappings[PackageId]) {
      let courses = this.packageCourseMappings[PackageId];
      for (
        let index = 0;
        index < courses.length;
        index++
      ) {
        if (courses[index].ItemId == CourseId) {
          courses[
            index
          ].PackageProgress.Progress = Progress;
          break;
        }
      }
      this.packageCourseMappings[PackageId] = [...courses];
      ////console.log("updateCourseProgressInPackage after", this.packageCourseMappings[PackageId])
      //this.contentAddedDetailsForPackagesLoaded.next(true);
    }
  }
  // getCloudlabStatus() {
  //   this.contentAddedDetailsLoaded.subscribe(loaded => {
  //     if (loaded) {
  //       this.cloudlabStore.dispatch(
  //         new fromCloudlabStore.CloudlabGetStatus(
  //           Object.keys(this.contentAddedDetails.YorbitCourses)
  //         )
  //       );
  //     }
  //   });
  // }

  getAddedContent(details) {
    const addedContent = {
      YorbitCourses: {},
      NonYorbitCourses: {},
      YorbitPackages: {},
      NonYorbitPackages: {},
      RblPackages: {},
      YorbitPackageIds: [],
      NonYorbitPackageIds: [],
      RblPackageIds: [],
      YorbitPackageCourseMappings: {},
      NonYorbitPackageCourseMappings: {},
      RblPackageCourseMappings: {}
    };
 
    const courseProgressList = () => {
      return details.CourseProgressList.reduce((acc, curr) => {
        acc[curr.CourseId] = curr;
        return acc;
      }, {})
    }

    const mapData = (path, pkg) => {
      // console.log("acccountandpackageeeeeeeeee",courseProgressList()[pkg.ItemId]?.IsAccount);
      const courseList = courseProgressList();
      return {
            PathId: path.PathId,
            PathName: path.PathName,
            IsMandatory: pkg.IsMandatory,
            IsCrossSkill: path.IsCrossSkill,
            IsFutureSkill: path.IsFutureSkill,
            IsSkillPlan: path.IsSkillPlan,
            Id: pkg.ItemId,
            PackageId: pkg.ItemType === 'Course' ? '' : pkg.ItemId,
            Expertise: pkg.ItemExpertise,
            Name: pkg.ItemName,
            AssignedBy: pkg.AssignedBy,
            Type: pkg.ItemType,
            IsAccount: courseList[pkg.ItemId]?.IsAccount ?? false,
            IsProject: courseList[pkg.ItemId]?.IsProject ?? false
          };
    }
    
    const yorbitAndNonYorbitCourses = (path, progressList) => {
      const courseData = mapData(path, progressList);
      progressList.Badges.forEach(badge => {
        courseData.Id = badge.CourseId;
        courseData.Type = 'Course';
        if (!isNaN(badge.CourseId)) {
          if (!addedContent.YorbitCourses[badge.CourseId]) {
            addedContent.YorbitCourses[badge.CourseId] = [];
          }
          addedContent.YorbitCourses[badge.CourseId].push(courseData);
        } else {
          if (!addedContent.NonYorbitCourses[badge.CourseId]) {
            addedContent.NonYorbitCourses[badge.CourseId] = [];
          }
          addedContent.NonYorbitCourses[badge.CourseId].push(courseData);
        }
      });
    }
 
    const yorbitAndNonYorbitPackages = (path, progressList) => {
      const courseData = mapData(path, progressList);
      if (progressList.ItemType === 'FamilyPackage') {
        if (!isNaN(progressList.ItemId)) {
          if (!addedContent.YorbitPackages[progressList.ItemId]) {
            addedContent.YorbitPackages[progressList.ItemId] = [];
          }
          addedContent.YorbitPackages[progressList.ItemId].push(courseData);
          addedContent.YorbitPackageIds.push(progressList.ItemId);
          progressList.Badges.forEach(badge => {
            if (!addedContent.YorbitPackageCourseMappings[progressList.ItemId]) {
              addedContent.YorbitPackageCourseMappings[progressList.ItemId] = [];
            }
            addedContent.YorbitPackageCourseMappings[progressList.ItemId].push(badge.CourseId);
          });
        } else {
          if (!addedContent.NonYorbitPackages[progressList.ItemId]) {
            addedContent.NonYorbitPackages[progressList.ItemId] = [];
          }
          courseData.IsAccount = progressList.AccountPackage;
          courseData.IsProject = progressList.ProjectPackage;
          addedContent.NonYorbitPackages[progressList.ItemId].push(courseData);
          addedContent.NonYorbitPackageIds.push(progressList.ItemId);
          progressList.Badges.forEach(badge => {
            if (!addedContent.NonYorbitPackageCourseMappings[progressList.ItemId]) {
              addedContent.NonYorbitPackageCourseMappings[progressList.ItemId] = [];
            }
            addedContent.NonYorbitPackageCourseMappings[progressList.ItemId].push(badge.CourseId);
          });
        }
      }
    }
 
    details.LearningPaths.forEach(path => {
      path.PackageList.forEach(pkg => {
        
        if(!pkg.IsDeleted){
          yorbitAndNonYorbitPackages(path, pkg);
          yorbitAndNonYorbitCourses(path, pkg);
        }
 
        // if (!isNaN(pkg.ItemId)) {
        //   if (pkg.ItemType === 'Course') {
        //     if (!addedContent.YorbitCourses[pkg.ItemId]) {
        //       addedContent.YorbitCourses[pkg.ItemId] = [];
        //     }
        //     addedContent.YorbitCourses[pkg.ItemId].push(courseData);
        //   }
        //   // else if (pkg.ItemType === 'Rbl') {
        //   //     if (!addedContent.RblPackages[pkg.ItemId]) {
        //   //         addedContent.RblPackages[pkg.ItemId] = [];
        //   //     }
        //   //     addedContent.RblPackages[pkg.ItemId].push(courseData);
        //   // }
        //   else if (pkg.ItemType === 'FamilyPackage') {
        //     if (!addedContent.YorbitPackages[pkg.ItemId]) {
        //       addedContent.YorbitPackages[pkg.ItemId] = [];
        //     }
        //     addedContent.YorbitPackages[pkg.ItemId].push(courseData);
        //     addedContent.YorbitPackageIds.push(pkg.ItemId);
        //     badges(pkg, courseData, true);
        //   }
        // } else {
        //   if (pkg.ItemType === 'Course') {
        //     if (!addedContent.NonYorbitCourses[pkg.ItemId]) {
        //       addedContent.NonYorbitCourses[pkg.ItemId] = [];
        //     }
        //     addedContent.NonYorbitCourses[pkg.ItemId].push(courseData);
        //   }
        //   if (pkg.ItemType === 'FamilyPackage') {
        //     if (pkg.AccountPackage || pkg.ProjectPackage) {
        //       if (!addedContent.NonYorbitPackages[pkg.ItemId]) {
        //         addedContent.NonYorbitPackages[pkg.ItemId] = [];
        //       }
        //       addedContent.NonYorbitPackages[pkg.ItemId].push(courseData);
        //       addedContent.NonYorbitPackageIds.push(pkg.ItemId);
        //       badges(pkg, courseData, false);
        //     }
        //     //  else if () {
        //     //     if (!addedContent.RblPackages[pkg.ItemId]) {
        //     //         addedContent.RblPackages[pkg.ItemId] = [];
        //     //     }
        //     //     addedContent.RblPackages[pkg.ItemId].push(courseData);
        //     // }
        //   }
        // }
 
        // if (pkg.ItemType === 'Course' || pkg.ItemType === 'Rbl') {
        //   if (pkg.ItemType === 'Course') {
        //     addedContent.YorbitPackageIds.push(pkg.ItemId);
        //     addedContent.YorbitPackageCourseMappings[pkg.ItemId] = pkg.Badges.map(badge => badge.CourseId);
        //   } else if (pkg.ItemType === 'NonYorbit') {
        //     addedContent.NonYorbitPackageIds.push(pkg.ItemId);
        //     addedContent.NonYorbitPackageCourseMappings[pkg.ItemId] = pkg.Badges.map(badge => badge.CourseId);
        //   } else if (pkg.ItemType === 'Rbl') {
        //     addedContent.RblPackageIds.push(pkg.ItemId);
        //     addedContent.RblPackageCourseMappings[pkg.ItemId] = pkg.Badges.map(badge => badge.CourseId);
        //   }
        // }
      });
    });
 
    // details.CourseProgressList.forEach(course => {
    //   if (!isNaN(course.CourseId)) {
    //     addedContent.YorbitCourses[course.CourseId][0].Name = course.CourseName;
    //   } else {
    //     addedContent.NonYorbitCourses[course.CourseId][0].Name = course.CourseName;
    //   }
    // });
 
    return addedContent;
  }
}
