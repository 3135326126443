import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  OnDestroy,
  Inject,
  ElementRef
} from '@angular/core';
import {
  EnvironmentService,
  Ienvironment
} from '@YorbitWorkspace/global-environments';
import { ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { AuthService } from '@YorbitWorkspace/auth';
//import { AdalService } from 'adal-angular4';
import { SwUpdate } from '@angular/service-worker';
import { Store, select } from '@ngrx/store';
import * as fromAuthStore from '@YorbitWorkspace/auth';
import * as fromRoleAccessStore from '@YorbitWorkspace/role-access';
import { DomSanitizer } from '@angular/platform-browser';
import * as fromUserDetailsStore from './shared/user-details/store';
import * as fromRblStore from './shared/content-tiles/rolebased-store/store';
import { Observable, Subject } from 'rxjs';
import {
  IuserDetails,
  Role
} from './shared/user-details/store/user-details.interface';
import { AppInsightService } from '@YorbitWorkspace/app-insight';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { PushNotificationService } from './push-notifications/push-notifications.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef
} from '@angular/material/dialog';
import { HeaderPopupComponent } from './header/header-popup/header-popup.component';
import { SubscribePushNotificationsComponent } from './push-notifications/subscribe-push-notifications/subscribe-push-notifications.component';

import * as fromGraphStore from '@YorbitWorkspace/graph';
import * as fromMyPageStore from '../app/my-page/store';
import * as fromDeclareStore from './shared/declare-store/store';

import { ICompliance } from './my-page/store/my-page-details.interface';
import { IroleData } from '@YorbitWorkspace/role-access';

import { ManagerFeedbackComponent } from './shared/global-popups/manager-feedback/manager-feedback.component';
import { DetailsPageService } from './details-page/details-page.service';
import { PopupService } from './shared/global-popups/popup.service';
import { PopupTriggerService } from './shared/services/popup-trigger.service';
import { Globals } from './globals';
import { PackageCourseListService } from './shared/services/package-course-list.service';
import { SystemService } from './shared/services/system.service';
import { ToastComponent } from './toast/toast.component';

import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { SparkIntegrationService } from './shared/services/spark-integration.service';
import { AddedContentDataService } from './shared/services/added-content-data.service';
import { ContentTileLpService } from './shared/content-tiles/content-tile-lp/content-tile-lp.service';
import { GeneralInfoComponent } from './shared/global-popups/general-info/general-info.component';
import { MyPageService } from './my-page/my-page.service'; //MyPageService to get CDM code
import { GraphDataService } from '@YorbitWorkspace/graph';
import jwt_decode from 'jwt-decode';
import { SessionTimeoutComponent } from './shared/global-popups/session-timeout/session-timeout.component';
import { PreloaderPopupComponent } from './shared/global-popups/preloader-popup/preloader-popup.component';
import { VideoPlayersService } from '../app/shared/video-players/video-players.service';
import { QuizGuardDeactivate, QuizService } from '../app/quiz/quiz.service';
import { HeaderService } from './header/header.service';
import { ChromeBrowserSuggestionComponent } from './shared/global-popups/chrome-browser-suggestion/chrome-browser-suggestion.component';
import { CookieCheckComponent } from './shared/global-popups/cookie-check/cookie-check.component';
import { ContentUploadStatusPopupComponent } from './ao/content-upload-status-popup/content-upload-status-popup.component';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG
} from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { DeclarationFormPopupComponent } from './quiz/declaration-form-popup/declaration-form-popup.component';
import { LearningPathService } from './learning-path/learning-path.service';
import { TimeBoundLearningPopupComponent } from './shared/global-popups/time-bound-learning-popup/time-bound-learning-popup.component';
import { TimeBoundLearningService } from './shared/services/time-bound-learning.service';
import { UserDetailsService } from './shared/user-details/user-details.service';
import { ScormPlayerService } from './shared/video-players/scorm-player/scorm-player.service';
import { MatMenuTrigger } from '@angular/material/menu';
//import { type } from 'os';
import { YorbitCoursesService } from './shared/services/yorbit-courses.service';
import { json } from 'body-parser';
import { DiversityAndInclusionService } from './shared/services/diversity-and-inclusion.service';
import { MatSidenavContent } from '@angular/material/sidenav';
@Component({
  selector: 'yorbit-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('drawer', { static: false })
  drawer: any;
  title = 'yorbit';
  contentDetails: any;
  config: Ienvironment;
  userDetails$: Observable<IuserDetails>;
  userDetails: IuserDetails;
  authUserName$: Observable<string>;
  userNameFromGraphDetails: string;
  userImageFromGraphDetails: any;
  userImage: ImageData;
  userId: any;
  EmpId: any;
  complianceDetails$: Observable<ICompliance>;
  userRoleAccess$: Observable<IroleData>;
  navbarLinks: Array<any>;
  navbarLinksNew: Array<any>;
  yorbitPoints: number | string;
  rolesFromUserDetails: Role[];
  isCustomer: boolean;
  rolesList: any[];
  userDetailsSubscription:any;
  userRoleAccessLoaded$: Observable<boolean>;
  userProfileLoaded: boolean;
  competency: string;
  IsC4nabove: boolean = false;
  //isC7nbelow: boolean = false;

  isTimerSetForTokenRefresh: boolean;
  isFrame = window !== window.parent && !window.opener;
  offsetTimeoutForIdTokenRefresh = 5;
  showClickHereMsg: boolean = false;
  timerSetToReloadPage = false;
  swbroadcast: any;
  sessionTimerSubscription: any;
  isFutureSkillPage: boolean;
  adalFrameTimerSubscription: any;
  loginDisplay = false;
  timeBoundLearningCourseList;
  private readonly _destroying$ = new Subject<void>();
  roleBasedDetails$: Observable<any>;
  showHelpMessage: boolean = true;

  showsTooltip = false;
  topPosition: any;
  leftPosition: any;
  tooltipTimer;

  instructionPopup: MatDialogRef<TimeBoundLearningPopupComponent>;
  @ViewChild('iSupportMenuTrigger', { static: false }) iSupportPopup: MatMenuTrigger;
  @ViewChild('matSidenavContent', { static: false }) matSidenavContent: MatSidenavContent;

  constructor(
    private _envSvc: EnvironmentService,
    //private authService: MsalService,
    //private adalService: AdalService,
    private _InsightService: AppInsightService,
    private authStore: Store<fromAuthStore.IAuthState>,
    private declareStore: Store<fromDeclareStore.IdeclareState>,
    private userRoleAccessStore: Store<fromRoleAccessStore.IRoleReducerState>,
    private userDetailsStore: Store<fromUserDetailsStore.IuserDetailsState>,
    private graphStore: Store<fromGraphStore.IGraphReducerState>,
    private myPageStore: Store<fromMyPageStore.IMyPageReducerState>,
    private rblStore: Store<fromRblStore.IrolebasedData>,
    private appStore: Store<any>,
    private _route: Router,
    private pushService: PushNotificationService,
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private detailsPageService: DetailsPageService,
    private _popupSvc: PopupService,
    public globals: Globals,
    private _popup: MatDialog,
    private _packageCourseListSvc: PackageCourseListService,
    private _systemService: SystemService,
    private _popupTriggerService: PopupTriggerService,
    private snackBar: MatSnackBar,
    private swUpdate: SwUpdate,
    private _myPageSvc: MyPageService,
    private _graphSvc: GraphDataService,
    private _sparkintegrationService: SparkIntegrationService,
    private _changeDetectionRef: ChangeDetectorRef,
    private _videoPlayersService: VideoPlayersService,
    private _quizGuardDeactivate: QuizGuardDeactivate,
    private _contentTileLpService: ContentTileLpService,
    private headerService: HeaderService,
    private addedContentDataService: AddedContentDataService,
    private broadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private quizService: QuizService,
    private _dialog: MatDialog,
    private _timeBoundCourseService: TimeBoundLearningService,
    private _userDetailsService: UserDetailsService,
    private scormPlayerService: ScormPlayerService,
    private yorbitcoursesservice: YorbitCoursesService,
    private _diversityAndInclusionService: DiversityAndInclusionService,
    private _store: Store
  ) {
    if (!navigator.cookieEnabled) {
      this.gotoCookieBlocked();
    } else {
      this.userImageFromGraphDetails = null;
      this.userProfileLoaded = false;
      try {
        window.localStorage.setItem('IsTimerSetForIdTokenRenewal', '0');
        window.localStorage.setItem('isSessionExpiryPopupOpen', '0');
      } catch (error) {
        ////console.log('error ###', error);
      }
      this.isTimerSetForTokenRefresh = false;
      this.config = this._envSvc.getEnvironment();
      this.offsetTimeoutForIdTokenRefresh = this.globals.offsetTimeoutForIdTokenRefresh;
      this.sessionTimerSubscription = null;
      this.sessionTimerSubscription = null;
      this.swbroadcast = null;
      this.config.adalConfig.redirectUri = window.location.href;
      this.config.adalConfig.postLogoutRedirectUri = window.location.href;
      this.headerService.futureSkillButtonAnimation.subscribe(value => {
        this.isFutureSkillPage = value;
      });
    }
  }

  loadNavbarLinks() {
    //navbar links should be type of angular routerlinks
    if (this.isCustomer) {
      this.navbarLinks = [
        {
          Name: 'Explore',
          Link: ['/browse']
        },
        {
          Name: 'About Us',
          Link: ['/info/aboutus']
        },
        {
          Name: 'FAQ',
          Link: ['/info/faq/General']
        },
        {
          Name: 'Terms of Use',
          Link: ['/info/termsOfUse']
        },
        {
          Name: 'Feedback',
          Link: ['/info/feedback']
        }
      ];
    } else {
      this.navbarLinks = [
        {
          Name: 'My Profile',
          Link: ['/my-page', this.userId, 'self']
        },
        {
          Name: 'Learning Path',
          Link: ['/learningpath']
        },
        {
          Name: 'Explore',
          Link: ['/browse']
        },
        {
          Name: 'Certification Upload',
          Link: '/certificateUpload'
        },
        // {
        //   Name: 'New Course Request',
        //   Link: ['/course-request']
        // },
        {
          Name: 'About Us',
          Link: ['/info/aboutus']
        },
        {
          Name: 'FAQ',
          Link: ['/info/faq/General']
        },
        {
          Name: 'Terms of Use',
          Link: ['/info/termsOfUse']
        },
        {
          Name: 'Feedback',
          Link: ['/info/feedback']
        }
      ];
      this.showToast();
      this.checkPushSubscription();
    }

    //Load New Menu

    this.navbarLinksNew = [
      {
        Name: 'DIVERSITY & INCLUSION',
        Link: ['/diversity-and-inclusion']
      }
    ];
  }
  reload() {
    window.location.reload();
    // setTimeout(() => {
    //   if (this.userId == '') {
    //   }
    // }, 2000);
  }
  ngOnInit() {

    this._userDetailsService.getMandatoryLpName().subscribe(res => {
      this.globals.LpName = String(res);
      //console.log(this.globals.LpName)
    });

    this.isFutureSkillPage = false;

    /*
    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
      */
    // this.broadcastService.inProgress$
    // .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)
    // ,takeUntil(this._destroying$))
    // .subscribe(async () => {
    //   if (!this.msalService.) {
    //     await this.logIn();
    //   }
    // });

    this.broadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS))
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);

        this.broadcastService.inProgress$
          .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)
            , takeUntil(this._destroying$))
          .subscribe(async () => {
            this.login();
          });
      });

    if (!navigator.cookieEnabled) {
      this.gotoCookieBlocked();
      //this.isCookieEnabled();
    } else {
      this.devReroute();
      /* this.authService.authInit(this.config.adalConfig);
      if (!this.isFrame) {
        this.adalAuthentication();
      } else {
        this.adalService.handleWindowCallback();
      } */
    }
    // this.scormResumeData();
    this.getContentCourseTypeList();
  
  }

  openTimeBoundLearningDialog() {
    this._timeBoundCourseService
      .getTimeBoundCourses(this.globals.MId)
      .toPromise()
      .then(res => {
        this.timeBoundLearningCourseList = res;
        this.timeBoundLearningCourseList = this.timeBoundLearningCourseList.filter(
          ({ IsTakeAction }) => IsTakeAction == false
        );
        if (this.timeBoundLearningCourseList.length > 0) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.closeOnNavigation = false;
          dialogConfig.panelClass = 'popupDialogContainer';
          dialogConfig.data = {
            TimeBoundCourses: this.timeBoundLearningCourseList
          };

          this.instructionPopup = this._dialog.open(
            TimeBoundLearningPopupComponent,
            dialogConfig
          );
          this.instructionPopup.afterClosed().subscribe(data => { });
        }
      });
  }

  scormResumeData() {
    this.scormPlayerService.getScormDataByMID().then((scormData: any) => {
      this.globals.scormResumeIds = scormData;
    });
  }

  /*
  checkDeclaration(UserDetails) {
    this.quizService
      .getMandatoryDeclarationPending()
      .toPromise()
      .then(response => {
        ////console.log("______________________________________________");
        ////console.log(response);

        let badge = null;
        response.forEach(element => {
          if (2 == element.DeclarationId) {
            UserDetails.Achievements.Badges.filter(course => {
              if (course.CourseId === element.CourseId) {
                badge = course.BadgeId;
              }
            });
          }
        });

        if (badge) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.panelClass = 'popupDialogContainer';

          dialogConfig.data = {
            badgeId: badge,
            responseData: ''
          };

          const declarationPopup = this._popup.open(
            DeclarationFormPopupComponent,
            dialogConfig
          );
          declarationPopup.afterClosed().subscribe(res => {
            //////console.log('response from header pop up', res);
          });
        }
      });
  }
  */

  login() {
    ////console.log('hitapp', this.msalGuardConfig);
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
    if (!this.loginDisplay) {
      //this.reload();
      //this.login();
      //this.msalService.initialize();
      //this.msalService.loginRedirect();
    } else {

      this.broadcastService.msalSubject$
        .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS))
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this.msalService.instance.setActiveAccount(payload.account);
        });
    }
    ////console.log('lo', this.authService.instance.getAllAccounts());
  }
  appInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        ////console.log('sw update available');
        window.location.reload();
      });
    }
    this.setCommunicationChannelFromServiceWorker();
    this.userId = '';
    this.getuserDetails();
    //this.migrateUser();
    // this.addedContentDataService.getAddedContentDetails();
    // this.addedContentDataService.getCloudlabStatus();
    // this.getAllCoursesDeclareWorkflow();

    this.insightInitiate();
    this.appStore.subscribe(data => { });
    this.appStore.select('Graph').subscribe(graphdetails => { });
    setTimeout(() => {
      if (!this.userId) {
        this.showClickHereMsg = true;
      }
    }, 10000);
    setTimeout(() => {
      this._popupSvc.isManagerFeedbackPending().then(isManagerFeedbackPending => {
        if (isManagerFeedbackPending && !this.globals.isManagerFeedbackPopupShown) {
          this.openMgrFeedbackDialog();
        }
      });
    }, 2000);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    //isNotChrome
    this.isNotChrome();

    this._diversityAndInclusionService.scrollTop$.subscribe(data => {
      if (this.matSidenavContent) {
        this.matSidenavContent.getElementRef().nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
      }

    });
  }

  isNotChrome() {
    // const isIEOrEdge = /msie*\s|Firefox|trident\/|OPR\/|Edg|edge*\//i.test
    const isIEOrEdge = /msie*\s|Firefox|trident\/|OPR\//i.test(
      window.navigator.userAgent
    );
    //isIOS: /(iPhone|iPad|iPod)/.test(navigator.platform);
    const isSafari =
      /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    ////console.log('userAgent');
    ////console.log(window.navigator.userAgent);
    // let chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;

    /*//console.log(
      'window.navigator.userAgent. isIEOrEdge### navigator.cookieEnabled',
      isIEOrEdge,
      window.navigator.userAgent,
      navigator.cookieEnabled
    );*/
    if (isIEOrEdge || isSafari) {
      const dialogRef = this.dialog.open(ChromeBrowserSuggestionComponent, {
        panelClass: 'popupDialogContainer',
        disableClose: true,
        closeOnNavigation: true
      });
    }
  }

  isCookieEnabled() {
    const dialogRef = this.dialog.open(CookieCheckComponent, {
      panelClass: 'popupDialogContainer',
      disableClose: true,
      closeOnNavigation: true
    });
  }

  setCommunicationChannelFromServiceWorker() {
    if (BroadcastChannel != undefined) {
      this.swbroadcast = new BroadcastChannel('Yorbit-Channel');
      // Listen to the response
      this.swbroadcast.onmessage = event => {
        //////console.log('sw communication channel', event.data.payload);
        if (event.data.payload == 'LOGOUT') {
          localStorage.setItem('isSessionExpiryPopupOpen', '0');
          window.localStorage.setItem('IsTimerSetForIdTokenRenewal', '0');
          this._quizGuardDeactivate.canExitQuiz = true;
          // this.adalService.logOut();
          this.msalService.logout();
        } else if (event.data.payload == 'KEEPLEARNING') {
          this.dialog.openDialogs.forEach(dialog => {
            if (dialog.id == 'SessionInformation') {
              dialog.close();
            }
          });
          localStorage.setItem('isSessionExpiryPopupOpen', '0');
          window.localStorage.setItem('IsTimerSetForIdTokenRenewal', '0');
          this.setTimerForTokenRefresh();
        }
      };
    }
  }
  devReroute() {
    this.appInit();
    //   if (!this.config.isProd) {
    //     this._systemService.getMindsAccessStatus('DEV').then((response: any) => {
    //       if (response) {
    //         this.appInit();
    //       } else {
    //         window.location.href = this.globals.yorbitUrl;
    //       }
    //     });
    //   } else if (this.config.isProd) {
    //     this._systemService.getMindsAccessStatus('DEV').then((response: any) => {
    //       if (!response) {
    //         if (this.config.isSiteDown) {
    //           //navigate to downtime page
    //           this.globals.hideApp = true;
    //           this._route.navigate(['downtime']);
    //         } else {
    //           this.globals.hideApp = false;
    //           this.appInit();
    //         }
    //       } else {
    //         this.globals.hideApp = false;
    //         this.appInit();
    //       }
    //     });

    // }
  }

  insightInitiate() {
    this._InsightService.insightInit(this.config.appInsightKey);
  }
  getuserDetails() {
    this.userDetails$ = this.userDetailsStore.select(
      fromUserDetailsStore.getUserDetailObject
    );

    // this.userDetails$.subscribe((userDetails) => {
    //   console.log("xxxxxxxxxxxxxxxxxxxxxxxx", userDetails);
    // })
    this.userDetailsStore.dispatch(
      new fromUserDetailsStore.UserDetailsGetDetails()
    );
    // this.userDetailsStore.dispatch(
    //   new fromUserDetailsStore.UserDetailsGetGlobalCDMROLE()
    // )

    this.userDetails$.subscribe(res => {
      //////console.log('store user details udpate');
      //console.log(res)
      if (!this.userProfileLoaded && res.id !== '') {
        this.userProfileLoaded = true;
        this.loadUserDetails(res);
        this.addedContentDataService.getAddedContentDetails(res);
        // not subscribe, direct update method
        // this.addedContentDataService.contentAddedDetailsLoaded.subscribe(
        //   res => {
        //line 645, it would happen only when processing in line 640 is done
        //     if (res) {
              //this.addedContentDataService.updatePackageCourseMappingsInStore();
            //}
          //}
        // );
      } else {
        this.showHelpMessage = true
      }
    });
    let userDetailsLoadedStatus$ = this.userDetailsStore.select(
      fromUserDetailsStore.getUserDetailLoadedStatus
    );
    userDetailsLoadedStatus$.subscribe(res => {
      //////console.log('userDetailsLoadedStatus', res);
      if (res != null && !res) {
        //this.adalService.login();                        // <<<---using ()=> syntax
        //this.addedContentDataService.getAddedContentDetails(res);
        // this.addedContentDataService.getCloudlabStatus();
        this.getAllCoursesDeclareWorkflow();
        this.scormResumeData();
      }
    });
  }
  loadUserDetails(res) {
    this.globals.MId = res.id;
    this.globals.EmpId = res.EmpId
    this.userId = res.id;
    this.EmpId = res.EmpId;
    this._InsightService.trackUser(this.userId);
    this.isCustomer = res.IsCustomer;
    this.yorbitPoints = res.Achievements.Points;
    this.getGraphDetails(res.id);
    this.getComplianceDetails(res.id);
    this.getRBLDetails();
    //this.checkDeclaration(res);

    this.loadNavbarLinks();
    this.getCompetency(res.id);
    this.getRouteState();
    if (res.Roles) {
      this.rolesFromUserDetails = res.Roles;
    } else {
      this.rolesFromUserDetails = [];
    }
    if (this.isCustomer) this.rolesFromUserDetails.push({ RoleId: 'CUSTOMER' });

    this._userDetailsService.getGlobalCDMRole().then(
      (data: any) => {
        if (data == true) {
          this.rolesFromUserDetails.push({ RoleId: 'GlobalCDM' });
        }
        this.getUserRoles();
      },
      error => {
        this.getUserRoles();
      }
    );

    //this.adalAquireOnInterval();
    //this.getItemList(res); // to get cloud lab,declare and courses inside packages

    this.checkCDMAvailable(this.userId);
    this.globals.isDateAPICalled = true;
    this._contentTileLpService
      .getMandatoryDates(this.globals.LpName == 'coc' ? 'CODE OF CONDUCT' : this.globals.BEACLpName.toUpperCase())
      .then(dates => {
        this.globals.isDateAPICalled = true;
        this.globals.mandatoryReminderCount = dates.ReminderID;
        this.globals.isNewJoinee = dates.IsNewJoinee;
        this.globals.mandatoryRetakeDate = new Date(dates.ReminderDate);
      });
    this.globals.isDateAPICalledForPoshUSA = true;
    this._contentTileLpService
      .getMandatoryDates('SEXUAL HARASSMENT PREVENTION TRAINING - USA')
      .then(dates => {
        this.globals.isDateAPICalledForPoshUSA = true;
        this.globals.mandatoryReminderCountForPoshUSA = dates.ReminderID;
        this.globals.isNewJoineeForPoshUSA = dates.IsNewJoinee;
        this.globals.mandatoryRetakeDateForPoshUSA = new Date(
          dates.ReminderDate
        );
        this.globals.counterPOSHUSA = dates.Counter;
      });

    this._changeDetectionRef.markForCheck();
    setTimeout(() => {
      // <<<---using ()=> syntax
      this.openTimeBoundLearningDialog();
    }, 1000);
  }
  // adalAuthentication() {
  //   this.adalService.handleWindowCallback();
  //   this.authUserName$ = this.authStore.select(fromAuthStore.getAuthUserName);
  //   this.authStore.dispatch(new fromAuthStore.AuthGetUserProfile());
  //   if (this.authUserName$) {
  //     //this.authService.login();
  //   }
  //   //////console.log('user info', this.adalService.userInfo);
  //   window.localStorage.setItem('IsTimerSetForIdTokenRenewal', '0');
  //   this.setTimerForTokenRefresh();
  // }
  setTimerForTokenRefresh() {
    //commented in msal build
    // if (this.sessionTimerSubscription != null) {
    //   clearTimeout(this.sessionTimerSubscription);
    //   this.sessionTimerSubscription = null;
    // }
    // let idTokenTimerStatus = window.localStorage.getItem(
    //   'IsTimerSetForIdTokenRenewal'
    // );
    // if (idTokenTimerStatus != null) {
    //   parseInt(idTokenTimerStatus) == 0
    //     ? (this.isTimerSetForTokenRefresh = false)
    //     : (this.isTimerSetForTokenRefresh = true);
    // } else {
    //   this.isTimerSetForTokenRefresh = false;
    // }
    // if (
    //   this.adalService.userInfo.authenticated &&
    //   !this.isTimerSetForTokenRefresh
    // ) {
    //   const token = window.localStorage.getItem(
    //     'adal.access.token.key' + this.config.adalConfig.clientId
    //   );
    //   const decodedToken = jwt_decode(token);
    //   const tokenExpiresIn = decodedToken['exp'];
    //   const currentEpochTime = Math.floor(new Date().getTime() / 1000.0);
    //   const epochDiffInMins = Math.floor(
    //     (tokenExpiresIn - currentEpochTime) / 60
    //   ); // Epoch time difference in minutes
    //   if (epochDiffInMins > this.offsetTimeoutForIdTokenRefresh - 4) {
    //     this.isTimerSetForTokenRefresh = true;
    //     window.localStorage.setItem('IsTimerSetForIdTokenRenewal', '1');
    //     //console.log(
    //       'timer set in ',
    //       epochDiffInMins - this.offsetTimeoutForIdTokenRefresh
    //     );
    //     this.sessionTimerSubscription = setTimeout(() => {
    //       this.showSessionTimeoutPopup();
    //     }, (epochDiffInMins - this.offsetTimeoutForIdTokenRefresh) * 60 * 1000);
    //   } else {
    //     this.adalService.logOut();
    //   }
    // } else if (
    //   !this.adalService.userInfo.authenticated &&
    //   this.adalService.userInfo.token == null
    // ) {
    //   this.adalService.logOut();
    // }
  }
  // showSessionTimeoutPopup() {
  //   const token = window.localStorage.getItem(
  //     'adal.access.token.key' + this.config.adalConfig.clientId
  //   );
  //   const decodedToken = jwt_decode(token);
  //   const tokenExpiresIn = decodedToken['exp'];
  //   const currentEpochTime = Math.floor(new Date().getTime() / 1000.0);
  //   const epochDiffInMins = Math.floor(
  //     (tokenExpiresIn - currentEpochTime) / 60
  //   ); // Epoch time difference in minutes
  //   if (epochDiffInMins < this.offsetTimeoutForIdTokenRefresh + 1) {
  //     this._videoPlayersService.updateVideoPauseStatus(true);
  //     localStorage.setItem('isSessionExpiryPopupOpen', '1');
  //     let currentTime = Math.floor(new Date().getTime() / 1000.0);
  //     let popupData = {};
  //     if (this._route.url.indexOf('quiz') != -1) {
  //       popupData = {
  //         page: 'quiz',
  //         hasAttemptsRestriction: this._quizGuardDeactivate.hasAttempts,
  //         canExitQuiz: this._quizGuardDeactivate.canExitQuiz,
  //         tokenExpiresIn: tokenExpiresIn
  //       };
  //     } else {
  //       popupData = {
  //         page: '',
  //         hasAttemptsRestriction: false,
  //         canExitQuiz: true,
  //         tokenExpiresIn: tokenExpiresIn
  //       };
  //     }
  //     // if(this.globals.isContentUploadSaveAlertOpen){
  //     //   this.dialogRefSaveAlert.close();
  //     // }
  //     const dialogRef = this.dialog.open(SessionTimeoutComponent, {
  //       panelClass: 'popupDialogContainer',
  //       disableClose: true,
  //       data: popupData,
  //       id: 'SessionInformation',
  //       closeOnNavigation: false
  //     });
  //     dialogRef.afterClosed().subscribe(data => {
  //       if (data == 'logout') {
  //         // Send first request
  //         if (this.swbroadcast != null) {
  //           this.swbroadcast.postMessage({
  //             payload: 'LOGOUT'
  //           });
  //         }
  //         localStorage.setItem('isSessionExpiryPopupOpen', '0');
  //         window.localStorage.setItem('IsTimerSetForIdTokenRenewal', '0');
  //         this._quizGuardDeactivate.canExitQuiz = true;
  //         this.adalService.logOut();
  //       } else if (data == 'keeplearning') {
  //         const preloaderPopup = this.dialog.open(PreloaderPopupComponent, {
  //           panelClass: 'popupDialogContainer',
  //           disableClose: true,
  //           data: popupData,
  //           id: 'SessionInformationPreloader',
  //           closeOnNavigation: false
  //         });
  //         preloaderPopup.afterClosed().subscribe(status => {
  //           if (status == 'logout') {
  //             // Send first request
  //             if (this.swbroadcast != null) {
  //               this.swbroadcast.postMessage({
  //                 payload: 'LOGOUT'
  //               });
  //             }
  //             localStorage.setItem('isSessionExpiryPopupOpen', '0');
  //             window.localStorage.setItem('IsTimerSetForIdTokenRenewal', '0');
  //             this._quizGuardDeactivate.canExitQuiz = true;
  //             this.adalService.logOut();
  //           } else if (status == 'keeplearning') {
  //             if (this.swbroadcast != null) {
  //               this.swbroadcast.postMessage({
  //                 payload: 'KEEPLEARNING'
  //               });
  //             }
  //             this.dialog.openDialogs.forEach(dialog => {
  //               if (dialog.id == 'SessionInformation') {
  //                 dialog.close();
  //               }
  //             });
  //             localStorage.setItem('isSessionExpiryPopupOpen', '0');
  //             window.localStorage.setItem('IsTimerSetForIdTokenRenewal', '0');
  //             this.setTimerForTokenRefresh();
  //           }
  //         });
  //         //this._videoPlayersService.updateVideoPauseStatus(false);
  //       } else if (data == undefined || data == 'undefined' || data == null) {
  //         localStorage.setItem('isSessionExpiryPopupOpen', '0');
  //         window.localStorage.setItem('IsTimerSetForIdTokenRenewal', '0');
  //         this.setTimerForTokenRefresh();
  //       }
  //     });
  //   } else {
  //     localStorage.setItem('isSessionExpiryPopupOpen', '0');
  //     window.localStorage.setItem('IsTimerSetForIdTokenRenewal', '0');
  //     this.setTimerForTokenRefresh();
  //   }
  // }
  getGraphDetails(MId) {
    this.graphStore.dispatch(new fromGraphStore.GetGraphUserName(MId));
    this.graphStore.dispatch(new fromGraphStore.GetGraphUserFirstName(MId));
    this.graphStore.dispatch(new fromGraphStore.GetGraphUserJobTitle(MId));
    this.graphStore.dispatch(new fromGraphStore.GetGraphUserImage(MId));
    this.appStore.select('Graph').subscribe(graphDetails => {
      if (graphDetails.loaded) {
        this.userImageFromGraphDetails = this.domSanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(graphDetails.userGraphInfo.user_image)
        );
        this.userNameFromGraphDetails =
          graphDetails.userGraphInfo.user_first_name;
        this.globals.userName = this.userNameFromGraphDetails;
      }
    });
  }

  getComplianceDetails(MId) {
    this.myPageStore.select(_state => fromMyPageStore.GET_MY_PAGE_COMPLIANCE);
    this.myPageStore.dispatch(new fromMyPageStore.GetMyPageCompliance(MId));
  }

  getRBLDetails() {
    this.roleBasedDetails$ = this.rblStore.select(
      _state => fromRblStore.ROLEBASED_GET_DETAILS
    );
    this.rblStore.dispatch(new fromRblStore.RolebasedGetStatus());
    // this.learningPathService.getRBLPackage().toPromise().then(data=>{
    //   console.log("rbl",data);

    //   });

    // this.complianceDetails$ = this.myPageStore.select(
    //   fromMyPageStore.GET_MY_PAGE_COMPLIANCE
    // );
    //this.myPageStore.dispatch(new fromMyPageStore.GetMyPageCompliance(MId));
  }

  checkPushSubscription() {
    try {
      this.pushService.getPushSubscription.subscribe(token => {
        //////console.log('push subscription token', token);
        if (token != '') {
          if (token == null) {
            //trigger popup
            const dialogRef = this.dialog.open(
              SubscribePushNotificationsComponent,
              {
                panelClass: 'popupDialogContainer',
                disableClose: false
              }
            );
            dialogRef.afterClosed().subscribe(data => {
              this._popupTriggerService.canTriggerPopupsOnLoad.next(true);
            });
          } else {
            //save the token
            this.pushService
              .storeSubscriptionId(token)
              .toPromise()
              .then(response => { });
            this.pushService
              .subscribeUserToGlobalTopic(token)
              .toPromise()
              .then(response => { })
              .catch(err => { });
            this._popupTriggerService.canTriggerPopupsOnLoad.next(true);
          }
        } else {
          this._popupTriggerService.canTriggerPopupsOnLoad.next(true);
        }
      });
    } catch (error) {
      this._popupTriggerService.canTriggerPopupsOnLoad.next(true);
    }
  }

  goToCourseRequest() {
    this._route.navigate(['course-request']);
  }

  openMgrFeedbackDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'popupDialogContainer';
    this.dialog.open(ManagerFeedbackComponent, dialogConfig);
  }

  getUserRoles() {
    this.userRoleAccess$ = this.userRoleAccessStore.select(
      fromRoleAccessStore.getRoleAccessList
    );
    this.userRoleAccessLoaded$ = this.userRoleAccessStore.select(
      fromRoleAccessStore.getRoleAccessListLoaded
    );
    this.userRoleAccessStore.dispatch(
      new fromRoleAccessStore.RoleGetAccessList()
    );
    this.userRoleAccess$.subscribe(roleAccess => {
      this.userRoleAccessLoaded$.subscribe(loaded => {
        if (this.rolesFromUserDetails && loaded) {
          const allRoles = [];
          this.rolesFromUserDetails.forEach(element => {
            allRoles.push(element.RoleId.toUpperCase());
          });
          if (roleAccess) {
            Object.entries(roleAccess).forEach(([key, value]) => {
              if (value) allRoles.push(key.toUpperCase());
            });
          }

          if (this.rolesList) {
            this.rolesList = [...this.rolesList, ...allRoles];
          } else {
            this.rolesList = allRoles;
            this.userRoleAccessStore.dispatch(
              new fromRoleAccessStore.RoleUpdateAccessList(allRoles)
            );
          }
          //console.log(this.rolesList);
          this.AdminRoleInit();
          /**
           *
           this.userRoleAccessStore.dispatch(
             new fromRoleAccessStore.RoleUpdateAccessList(allRoles)
             );
             */
        }
      });
    });
  }

  getRouteState() {
    this._route.events.forEach(event => {
      if (event instanceof NavigationEnd) {
        this.onPageRoute();
      }
    });
    this.onPageRoute();
  }

  onPageRoute() {
    try {
      const routeTitle = this.activatedRoute.snapshot.firstChild.children[0]
        .data.title;
      // const obj = {
      //   title: routeTitle,
      //   url: window.location.pathname
      // };
      // this.detailsPageService.savePrevRoute(obj);
      this._InsightService.trackPage(routeTitle);
      if (this.isCustomer) {
        this.checkCustomerRoute(routeTitle);
      }
      this.AdminRoleInit();
    } catch (error) {
      setTimeout(() => {
        this.onPageRoute();
      }, 2000);
    }
  }

  AdminRoleInit() {
    try {
      const routeTitle = this.activatedRoute.snapshot.firstChild.children[0]
        .data.title;
      const adminPagesListFromUserDetails = ['C2OPS', 'PSS', 'CDM'];
      const adminPagesListFromRoleApi = ['AO', 'RM'];
      const adminPagesList = [
        ...adminPagesListFromUserDetails,
        ...adminPagesListFromRoleApi,
        'ACCOUNTS'
      ];
      if (-1 !== adminPagesList.indexOf(routeTitle)) {
        if (-1 !== adminPagesListFromUserDetails.indexOf(routeTitle)) {
          this.checkAdminRole(routeTitle);
        }
        if (-1 !== adminPagesListFromRoleApi.indexOf(routeTitle)) {
          this.userRoleAccessLoaded$.subscribe(res => {
            if (res) this.checkAdminRole(routeTitle);
          });
        }
        if ('ACCOUNTS' === routeTitle) {
          this.userRoleAccessLoaded$.subscribe(res => {
            if (res) {
              if (this.rolesList) {
                if (
                  null === this.rolesList ||
                  this.rolesList === _.isEmpty({})
                ) {
                  this.gotoUnauthorized();
                }
                const accountRole = {
                  ACM: -1 !== this.rolesList.indexOf('ACM'),
                  PCM: -1 !== this.rolesList.indexOf('PCM'),
                  TM: -1 !== this.rolesList.indexOf('TM')
                };
                if (
                  !(
                    accountRole.ACM ||
                    accountRole.PCM ||
                    accountRole.TM ||
                    this.isCustomer
                  )
                ) {
                  this.gotoUnauthorized();
                }
              }
            }
          });
        }
      }
      if ('Classroom' === routeTitle) {
        this.userRoleAccessLoaded$.subscribe(res => {
          if (res) {
            if (this.rolesList) {
              if (
                null === this.rolesList ||
                this.rolesList === _.isEmpty({})
              ) {
                this.gotoUnauthorized();
              }
              const classroomAccessRole = {
                C2OPS: -1 !== this.rolesList.indexOf('C2OPS'),
                AO: -1 !== this.rolesList.indexOf('AO'),
              };
              if (
                !(
                  classroomAccessRole.C2OPS ||
                  classroomAccessRole.AO
                )
              ) {
                this.gotoUnauthorized();
              }
            }
          }
        });
      }
    } catch (error) { }
  }

  gotoUnauthorized() {
    if (this.isCustomer) {
      this._route.navigate(['/unauthorized'], {
        queryParams: { forUser: 'Customer' }
      });
    } else {
      this._route.navigate(['unauthorized']);
    }
  }
  gotoCookieBlocked() {
    this._route.navigate(['cookiesdisabled']);
  }
  checkCustomerRoute(routeTitle) {
    const userAgentMobile =
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i);
    if (null !== userAgentMobile) {
      this._route.navigate(['/unauthorized'], {
        queryParams: { forUser: 'Customer' }
      });
    }
    const routesAllowed = [
      'Home',
      'Browse',
      'Search',
      'Details Page',
      'Project Details Page',
      'ACCOUNTS',
      'Feedback'
    ];
    const footerOptions = ['About us', 'FAQ', 'Terms of use'];
    if ('Home' === routeTitle) {
      this._route.navigate(['/browse']);
    }
    if (-1 !== footerOptions.indexOf(routeTitle)) {
      const path = window.location.href;
      if (-1 === path.indexOf('external')) {
        switch (routeTitle) {
          case 'About us':
            this._route.navigate(['info/aboutus/external']);
            break;
          case 'FAQ':
            this._route.navigate(['info/faq/external/', 'Access Related']);
            break;
          case 'Terms of use':
            this._route.navigate(['info/termsOfUse/external']);
            break;
          default:
            break;
        }
      }
    } else if (-1 === routesAllowed.indexOf(routeTitle)) {
      this._route.navigate(['/unauthorized'], {
        queryParams: { forUser: 'Customer' }
      });
    }
  }
  checkAdminRole(routeTitle) {
    if (null === this.rolesList || 0 === this.rolesList.length) {
      this.gotoUnauthorized();
    }
    if (this.rolesList && -1 === this.rolesList.indexOf(routeTitle)) {
      this.gotoUnauthorized();
    }
  }

  // adalAquireOnInterval() {
  //   const timer = setInterval(() => {
  //     Object.entries(
  //       this.config.adalConfig.endpoints
  //     ).forEach(([key, value]) => {
  //       this.adalService.acquireToken(key);
  //     });
  //   }, 15 * 60 * 1000);
  // }
  openPopup(tab) {
    // if (tab === 'Certification Upload') {
    //   const dialogConfig = new MatDialogConfig();
    //   dialogConfig.disableClose = true;
    //   dialogConfig.autoFocus = true;
    //   dialogConfig.data = {};
    //   dialogConfig.panelClass = 'popupDialogContainer';
    //   const response = this._popup.open(HeaderPopupComponent, dialogConfig);
    //   response.afterClosed().subscribe(res => {
    //     //////console.log('response from header pop up', res);
    //     if (res.data === 'Yes') {
    //       this._route.navigate(['iCertificationUpload']);
    //     } else if (res.data === 'No') {
    //       this._route.navigate(['eCertificationUpload']);
    //     }
    //   });
    // }
  }

  logOut() {
    this.msalService.logout();
  }

  getItemList(userDetails) {
    // let undeletedlps = [];
    // let packages = [];
    // let courses = [];
    // let courseIdList = [];
    // if (userDetails.LearningPaths.length > 0) {
    //   undeletedlps = userDetails.LearningPaths.filter(
    //     lp => lp.IsDeleted === false
    //   );
    //   if (undeletedlps.length > 0) {
    //     undeletedlps.forEach(lp => {
    //       if (lp.PackageList.length > 0) {
    //         let undeletedList = [];
    //         let undeletedPackagesList = [];
    //         let undeletedCoursesList = [];
    //         undeletedList = lp.PackageList.filter(
    //           entity => entity.IsDeleted === false
    //         );
    //         undeletedPackagesList = undeletedList.filter(
    //           packageList =>
    //             packageList.ItemType.toLowerCase() === 'package' ||
    //             packageList.ItemType.toLowerCase() === 'familypackage'
    //         );
    //         undeletedCoursesList = undeletedList.filter(
    //           courseList => courseList.ItemType.toLowerCase() === 'course'
    //         );
    //         if (undeletedPackagesList.length > 0) {
    //           undeletedPackagesList.forEach(li => {
    //             packages.push({
    //               packageId: li.ItemId,
    //               accountId: li.AccountId,
    //               projectId: li.ProjectId
    //             });
    //           });
    //         }
    //         if (undeletedCoursesList.length > 0) {
    //           undeletedCoursesList.forEach(li => {
    //             courses.push({
    //               CourseId: li.ItemId,
    //               Expertise: li.ItemExpertise
    //             });
    //             if (courseIdList && courseIdList.length > 0) {
    //               if (courseIdList.indexOf(li.ItemId) == -1) {
    //                 courseIdList.push(li.ItemId);
    //               }
    //             } else {
    //               courseIdList.push(li.ItemId);
    //             }
    //           });
    //         }
    //       }
    //     });
    //   }
    // }
    // if (packages.length > 0) {
    //   this._packageCourseListSvc.getCourseIdsOfPackage(packages);
    // }
    // if (courses.length > 0) {
    //   this.fetchCloudLabs(courseIdList);
    // } else {
    //    this.declareStore.dispatch(
    //      new fromDeclareStore.DeclareGetStatusError('empty')
    //    );
    // }
  }

  getAllCoursesDeclareWorkflow() {
    this.declareStore.dispatch(
      new fromDeclareStore.DeclareGetStatusForAllCourses()
    );
  }

  fetchCloudLabs(courses) {
    // this.cloudlabStore.dispatch(
    //   new fromCloudlabStore.CloudlabGetStatus(courses)
    // );
  }

  showToast() {
    //const today = new Date();
    // if (today <= this.globals.lastDateCompletion) {
    //   this.openSnackBar();
    // }

    /* Added the below logic as <= operator  is working differently from what is expected.
    (comparing today and last date checks even the timestamp).
    The below logic will show the toast even on the last date. */

    const currentDate = new Date();
    const globalsDate = new Date(this.globals.lastDateCompletion);
    const today = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const lastDateCertificateReimbursement = new Date(
      this.globals.lastDateCertificateReimbursement
    );
    if (today <= globalsDate || today <= lastDateCertificateReimbursement) {
      this.openSnackBar();
    }
  }

  openSnackBar() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.horizontalPosition = 'end';
    config.panelClass = 'toastSnackbar';
    config.duration = 70000;
    config.panelClass = 'toast-panel-class';
    this.snackBar.openFromComponent(ToastComponent, config);
  }

  getCompetency(mid) {
    return this._sparkintegrationService.getCompetencydetails(mid).then(res => {
      this.competency = res.toString();
      this.globals.loggedInUserCompetency = res.toString();
      this.checkForCompetency();
    });
  }
  checkForCompetency() {
    if (this.globals.competencyList.includes(this.competency)) {
      this.IsC4nabove = true;
      this._sparkintegrationService.isC4nabv.next(true);
      if (this.IsC4nabove) {
        this.navbarLinksNew.push({
          Name: 'HARVARD BUSINESS SCHOOL',
          Link: ['/browse/HMM-Spark']
        });
      }
      // ////console.log("***********", this.competency);
    }
  }

  checkCDMAvailable(userId) {
    //The Tooltip task

    this._myPageSvc.getCDMandRM(userId).then((response: any) => {
      //////console.log(response);
      if (response != null) {
        if (response.CDMs != undefined && response.CDMs.length != 0) {
          this.globals.myCDMs = [];
          this.globals.myRms = [];

          let count = response.CDMs.length;

          response.CDMs.forEach((cdm, index) => {
            this.globals.cdmNameList[index] = '';
          });

          this.globals.toolTipMsg =
            'This course can only be assigned by your Learning Business Partner(s): ';

          response.CDMs.forEach((cdm, index) => {
            this._graphSvc.getUserName(cdm).subscribe(response => {
              this.globals.myCDMs.push(' ' + response.displayName);
              if (index == count - 1) {
                this.globals.cdmNameList[index] = ' ' + response.displayName;
                //  this.globals.toolTipMsg += this.globals.cdmNameList[index];
                // let res = ' ' + response.value;
                // this.globals.toolTipMsg += res;
                // let commaSplitLen = this.globals.toolTipMsg.split(',').length;
                // if(this.globals.toolTipMsg.split(',')[commaSplitLen-1] == " "){
                //   this.globals.toolTipMsg = this.globals.toolTipMsg.replace(/,\s*$/, ".");
                // }
              } else {
                this.globals.cdmNameList[index] =
                  ' ' + response.displayName + ',';
                // this.globals.toolTipMsg += this.globals.cdmNameList[index];
                // let res = ' ' + response.value + ',';
                // this.globals.toolTipMsg += res;
              }
            });
          });
        } else if (response.RMMid != undefined && response.RMMid != null) {
          this.globals.toolTipMsg =
            'To get this course assigned, please seek a mail approval from your RM and reach out to us at ' + this.globals.isupportUrl;
          this._graphSvc.getUserName(response.RMMid).subscribe(response => {
            this.globals.myRms = response.displayName;
          });
        }
      }
    });
  }

  getDepartment(list) {
    list.forEach((value, key) => {
      this._graphSvc
        .getLearnerDepartment(value.trim().toUpperCase())
        .toPromise()
        .then(r => {
          ////console.log('Department' + value, r.department);
        });
    });
  }

  ngOnDestroy() {
    const localStore = localStorage.getItem('QuizRefresh');
    if (localStore != null && localStore != undefined) {
      localStorage.removeItem('QuizRefresh');
    }
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  openFutureSkill() {
    this._route.navigate(['/futureskills']);
  }

  migrateUser() {
    this._userDetailsService
      .migrateUser()
      .subscribe(successResponse => {
        this.getuserDetails();

      }, errorResponse => {
        // console.log(errorResponse);    
      });
  }

  openiSupportMenu(e: MouseEvent) {
    this.tooltipTimer = setTimeout(() => {
      var htmlElemnt = e.target as HTMLElement
      this.showsTooltip = true;
      this.topPosition = htmlElemnt.offsetTop + htmlElemnt.offsetHeight;
      this.leftPosition = htmlElemnt.offsetLeft;
    }, 800);
  }

  closeiSupportMenu() {
    clearTimeout(this.tooltipTimer);
    this.showsTooltip = false;
    this.topPosition = null;
    this.leftPosition = null;
  }

  getContentCourseTypeList() {
    this.yorbitcoursesservice.getContentCourseType().subscribe(data => {
      const substring = 'tf3';
      //console.log(data);
      if (data.length > 0) {
        data.forEach((item, index) => {
          if (item.category.toLowerCase() === "course") {
            this.globals.typeCourse.push({ "Type": item.type, "Label": item.label });
          } else if (item.category.toLowerCase() === "assessment") {
            this.globals.typeAssessment.push({ "Type": item.type, "Label": item.label })
            //this.globals.courselabel.push(item.label)
          }
          if (item.type.toLowerCase().includes(substring)) {
            this.globals.typetf3.push({ "Type": item.type, "Label": item.label });
          }

        });
      }
      //console.log(this.globals.typeCourse);
      //console.log(this.globals.typeAssessment);
      //console.log(this.globals.courselabel);
      //console.log("tf3 --->",this.globals.typetf3);
    });


  }

  onScroll(event: Event): void {
    const scrollPosition = (event.target as HTMLElement).scrollTop;
    this._diversityAndInclusionService.updateScrollData(scrollPosition);
  }
}
